/* eslint-disable prettier/prettier */
import { DataGrid, DataGridProps } from '@innovyze/stylovyze';
import { FilterAlt, Refresh } from '@mui/icons-material';
import { GridRowId, useGridApiRef } from '@mui/x-data-grid-premium';
import { IconButton, SelectChangeEvent } from '@mui/material';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { SystemAssetSelectors, Wrapper } from './AssetGrid.styles';
import {
	getAssetsPaginated,
	getAssetsPaginatedReset,
	getSystemAssetTypes,
} from '@Actions/asset';
import {
	selectAssetGridColumnVisibility,
	selectMapLayoutForAssetGrid,
} from '@Selectors/map';
import {
	selectGridExcludeList,
	selectGridLoading,
	selectGridPagination,
	selectGridRows,
	selectGridSort,
	selectSelectedAssets,
} from '@Selectors/asset';
import { useDispatch, useSelector } from 'react-redux';

import { Asset } from '@Utils/types';
import { AssetLinkProps } from './AssetLink';
import { AssetTypeSelect } from './AssetTypeSelect';
import { Field } from '@Types/map.types';
import { NamespacedStoreState } from '@Types/store.types';
import { SelectSystemAsset } from './SelectSystemAsset';
import { SystemTypeSelect } from './SystemTypeSelect';
import { initialState as assetInitialState } from '@Reducers/asset';
import { convertSortModel } from '@Utils/grid';
import { generateColumns } from './columnMapping';
import { getLayout } from '@Actions/map';
import { useFilter } from '../../context';
import { useFilterModel } from './useFilterModel';
import { useGlobalization } from '@Translations/useGlobalization';
import { useShallowEqlEffect } from '@Hooks/useShallowEqlEffect';
import { useSystemType } from './useSystemType';

export interface AssetGridProps {
	onViewDetails?: AssetLinkProps['onViewDetails'];
	onSelectedAssetsChange?: (selectedAssets: Asset[]) => void;
}

export const AssetGrid = ({
	onViewDetails,
	onSelectedAssetsChange,
}: AssetGridProps): JSX.Element => {
	const dispatch = useDispatch();
	const { t } = useGlobalization();

	const [systemType, setSystemType] = useSystemType();
	const [assetType, setAssetType] = useState('');
	const loading = useSelector(selectGridLoading);
	const rows = useSelector(selectGridRows);
	const pagination = useSelector(selectGridPagination);
	const sort = useSelector(selectGridSort);
	const filterButton = useRef(null);
	const apiRef = useGridApiRef();
	const {
		setAdditionalFilterProps,
		initExcludedFilter,
		clearExcludedFilter,
	} = useFilter();

	const columns = useSelector<NamespacedStoreState, Field[] | undefined>(
		state => selectMapLayoutForAssetGrid(state, assetType),
	);
	const columnVisibility = useSelector<
		NamespacedStoreState,
		DataGridProps['columnVisibilityModel']
	>(state => selectAssetGridColumnVisibility(state, assetType));
	const [paginationModel, setPaginationModel] = useState({
		page: pagination.current - 1,
		pageSize: pagination.limit,
	});
	const [sortModel, setSortModel] = useState(sort);
	const gridSortModel = useMemo(() => convertSortModel(sortModel), [
		sortModel,
	]);
	const [filterModel, setFilterModel, filterModelForServer] = useFilterModel(
		columns ?? [],
	);
	const [columnVisibilityModel, setColumnVisibilityModel] = useState(
		columnVisibility,
	);
	const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
	const selectedAssets = useSelector<NamespacedStoreState, Asset[]>(state =>
		selectSelectedAssets(state, selectionModel),
	);
	const excludedList = useSelector(selectGridExcludeList);

	const onSortModelChange: DataGridProps['onSortModelChange'] = useCallback(
		model => {
			if (model[0]?.field && model[0]?.sort) {
				setSortModel({
					sort: model[0]?.field,
					sortDescending: model[0]?.sort === 'desc',
				});
			} else {
				const { sort, sortDescending } = assetInitialState.assetGrid;
				setSortModel({
					sort,
					sortDescending,
				});
			}
		},
		[],
	);

	const onPageModelChange: DataGridProps['onPaginationModelChange'] = useCallback(
		model => {
			setPaginationModel(oldModel => ({
				...oldModel,
				...model,
				// reset pagination to 1st page if the pagesize has changed
				page: oldModel.pageSize !== model.pageSize ? 0 : model.page,
			}));
		},
		[],
	);

	const onColumnVisibilityChange: DataGridProps['onColumnVisibilityModelChange'] = useCallback(
		model => {
			setColumnVisibilityModel(model);
		},
		[],
	);

	const resetFilters = () => {
		const model = { ...filterModel, items: [] };
		setFilterModel(model);
	};

	const onSystemTypeChange = (event: SelectChangeEvent<string>) => {
		const newSystemType = event.target.value;
		setSystemType(newSystemType);
		setAssetType('');
		resetFilters();
		setAdditionalFilterProps({
			systemType: newSystemType,
		});
	};

	const onAssetTypeChange = (event: SelectChangeEvent<string>) => {
		const newAssetType = event.target.value;
		setAssetType(newAssetType);
		setPaginationModel(model => ({
			...model,
			page: 0,
		}));
		resetFilters();
		setAdditionalFilterProps(state => ({
			...state,
			assetType: newAssetType,
		}));
	};

	const showFilterPanel = () => {
		apiRef.current.showFilterPanel();
	};

	const getPaginatedResults = () => {
		if (systemType && assetType) {
			dispatch(
				getAssetsPaginated({
					assetType,
					systemType,
					limit: paginationModel.pageSize,
					offset: paginationModel.page + 1,
					sort: sortModel.sort,
					sortDescending: sortModel.sortDescending,
					filterModel: filterModelForServer,
				}),
			);
		} else {
			dispatch(getAssetsPaginatedReset());
		}
	};

	useEffect(() => {
		getPaginatedResults();
	}, [
		paginationModel.page,
		paginationModel.pageSize,
		systemType,
		assetType,
		sortModel.sort,
		sortModel.sortDescending,
		JSON.stringify(filterModelForServer),
	]);

	useEffect(() => {
		setColumnVisibilityModel(columnVisibility);
	}, [columnVisibility]);

	useShallowEqlEffect(() => {
		onSelectedAssetsChange?.(selectedAssets);
	}, [selectedAssets]);

	useEffect(() => {
		if (excludedList.length === 0) {
			clearExcludedFilter();
		} else {
			initExcludedFilter();
		}
	}, [excludedList]);

	useEffect(() => {
		dispatch(getSystemAssetTypes());
		dispatch(getLayout());
	}, []);

	return (
		<Wrapper>
			<SystemAssetSelectors>
				<SystemTypeSelect
					systemType={systemType}
					onSystemTypeChange={onSystemTypeChange}
					dataCy="system-type-select-top-bar"
					topBar
				/>
				<AssetTypeSelect
					systemType={systemType}
					assetType={assetType}
					onAssetTypeChange={onAssetTypeChange}
					dataCy="asset-type-select-top-bar"
					topBar
				/>
				<IconButton
					disabled={!(systemType && assetType)}
					title={t('Filter')}
					data-cy="filterButton"
					onClick={showFilterPanel}
					ref={filterButton}
					size="large">
					<FilterAlt />
				</IconButton>
				<IconButton
					disabled={!(systemType && assetType)}
					title={t('Refresh')}
					data-cy="refreshButton"
					onClick={getPaginatedResults}
					size="large">
					<Refresh />
				</IconButton>
			</SystemAssetSelectors>
			{!(systemType && assetType) && (
				<SelectSystemAsset
					assetType={assetType}
					systemType={systemType}
					onSystemTypeChange={onSystemTypeChange}
					onAssetTypeChange={onAssetTypeChange}
				/>
			)}
			{systemType && assetType && (
				<DataGrid
					apiRef={apiRef}
					columns={generateColumns(
						columns,
						assetType,
						systemType,
						t,
						onViewDetails,
					)}
					loading={
						!!systemType && !!assetType && !rows?.length && loading
					}
					rows={rows ?? []}
					getRowId={row => row._id}
					height="calc(100% - 75px)"
					autoHeight={false}
					pageSizeOptions={[5, 10, 15, 25, 50, 100]}
					pagination
					paginationMode="server"
					paginationModel={paginationModel}
					onPaginationModelChange={onPageModelChange}
					rowCount={pagination.total}
					onRowSelectionModelChange={setSelectionModel}
					footerHelper={t(
						'Showing {{from}}-{{to}} of {{total}} Assets',
						{
							from:
								(pagination.current - 1) * pagination.limit + 1,
							to:
								pagination.current == pagination.pages
									? pagination.total
									: pagination.current * pagination.limit,
							total: pagination.total,
						},
					)}
					sortingMode="server"
					sortModel={gridSortModel}
					onSortModelChange={onSortModelChange}
					filterMode="server"
					filterModel={filterModel}
					onFilterModelChange={setFilterModel}
					columnVisibilityModel={columnVisibilityModel}
					onColumnVisibilityModelChange={onColumnVisibilityChange}
					slotProps={{
						panel: {
							anchorEl: filterButton.current,
							placement: 'left',
						},
					}}
					data-cy="asset-grid"
				/>
			)}
		</Wrapper>
	);
};
