import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';

import { ServiceLayer } from '@Map/services/types';
import { SymbolLayout } from 'mapbox-gl';
import SymbolLoader from '@Map/symbols/SymbolLoader';

export default class LineSymbolLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'symbol';

	layerProperties(): UniqueLayerProps {
		return {
			layout: {
				'icon-image': this._getIcon(),
				'icon-size': [
					'interpolate',
					['linear'],
					['zoom'],
					// zoom 10 (or less)
					10,
					0.5,
					// zoom is 13 (or greater)
					13,
					1,
				],
				'icon-allow-overlap': true,
				'icon-ignore-placement': true,
				'symbol-placement': this._symbolPlacement(),
			},
		};
	}

	private _getIcon(): SymbolLayout['icon-image'] {
		if (this._icon === 'flow-arrow') {
			const color = this._selected
				? this._selectedColor || MapboxLayer.SELECTED_COLOR
				: this._color;

			return SymbolLoader.createSymbolId(this._icon, color);
		}

		return SymbolLoader.createSymbolId(
			this._icon,
			this._color,
			this._selected,
			this._selectedColor || MapboxLayer.SELECTED_COLOR,
			this._selected ? undefined : !this._iconBackground,
		);
	}

	private _symbolPlacement(): SymbolLayout['symbol-placement'] {
		return this._icon === 'flow-arrow' ? 'line' : 'line-center';
	}
}
