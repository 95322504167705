import React, { useEffect } from 'react';

import { ControlsWrapper } from './ControlsContainer.styles';
import { useMapboxContext } from '../../context/MapboxProvider';

interface ControlsContainerProps {
	children?: React.ReactNode;
	layerPanelOpen: boolean;
	layerPanelEditorOpen: boolean;
}

export const ControlsContainer = ({ layerPanelOpen, layerPanelEditorOpen, children }: ControlsContainerProps) => {
	const { map } = useMapboxContext();
	const [height, setHeight] = React.useState(0);
	const [layerPanelWidth, setLayerPanelWidth] = React.useState(700);

	// Get the height of the top right controls container
	// to position the controls correctly
	useEffect(() => {
		map?.on('load', () => {
			const div = map
				.getContainer()
				.getElementsByClassName('mapboxgl-ctrl-top-right')[0];
			const height = div?.getBoundingClientRect().height;
			setHeight(height);

			const layerPanel = document.getElementById('layer-panel');
			const layerPanelWidth = layerPanel?.getBoundingClientRect().width;
			if (layerPanelWidth) {
				setLayerPanelWidth(layerPanelWidth);
			}
		});
	}, [map]);

	return <ControlsWrapper top={height} layerPanelWidth={layerPanelWidth} layerPanelOpen={layerPanelOpen} layerPanelEditorOpen={layerPanelEditorOpen}>{children}</ControlsWrapper>;
};

export default ControlsContainer;
