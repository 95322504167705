import { GeoJSONFeature } from '../../features/types';
import { LocationBox } from '@Root';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import MapboxUtilsBase from './base';

export default class MapboxUtilsFeatures extends MapboxUtilsBase {
	clustersInBox(bbox: LocationBox): MapboxGeoJSONFeature[] | undefined {
		return this._map?.queryRenderedFeatures(bbox, {
			layers: this._layerManager?.loadedClusterLayerIds,
		});
	}

	featuresInBox(bbox: LocationBox): GeoJSONFeature[] | undefined {
		return (this._map?.queryRenderedFeatures(bbox, {
			layers: this._layerManager?.selectableLayerIdsOnMap,
			filter: ['has', 'id'],
		}) as unknown) as GeoJSONFeature[];
	}
}
