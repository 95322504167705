import { CircleLayout, CirclePaint } from 'mapbox-gl';
import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';

import { ServiceLayer } from '../../services/types';

export default class BubbleLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'circle';

	layerProperties(): UniqueLayerProps {
		return {
			paint: {
				'circle-color': this._getCircleColor(),
				// change circle size depending on the zoom level
				// larger as the user zooms from z12 to z14
				'circle-radius': [
					'interpolate',
					['linear'],
					['zoom'],
					// zoom 2 (or less)
					2,
					this._getScale() * 5,
					// zoom 13 (or greater)
					13,
					this._getScale() * 10,
					// // zoom is 18 (or greater)
					18,
					this._getScale() * 40,
				],
			},
			layout: {
				...this._getLayoutProperties(),
			},
		};
	}

	get opacityFallback(): number {
		return this._fillOpacity ?? MapboxLayer.FILL_OPACITY;
	}

	private _getScale(): number {
		return this._scale || MapboxLayer.SCALE;
	}

	private _getCircleColor(): CirclePaint['circle-color'] {
		if (this._colorLookup)
			return this._colorLookup as CirclePaint['circle-color'];
		return this._color || MapboxLayer.UNSELECTED_COLOR_FILL;
	}

	private _getLayoutProperties(): Partial<CircleLayout> {
		if (this._sort)
			return {
				'circle-sort-key': this
					._sort as CircleLayout['circle-sort-key'],
			};
		return {};
	}
}
