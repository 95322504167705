import React, { useCallback, useEffect, useMemo, useState } from 'react';

import BackgroundItem from './BackgroundItem';
import { BackgroundRegistry } from '@Map/panel/BackgroundRegistry';
import { BackgroundTypes, BasemapType } from '@Map/types';
import { BackgroundWrapper } from './LayerPanel.styles';
import { getBackgroundLayerImage } from './utils';
import { useGlobalization } from '@Translations/useGlobalization';

export interface BackgroundListProps {
	tabIndex: number;
	background?: BackgroundTypes;
	arcGISBasemapStylesToken: string | null;
	basemapOrigin?: BasemapType;
	backgroundChanged?: (background: BackgroundTypes) => void;
}

export const BackgroundList = ({
	tabIndex,
	background,
	backgroundChanged,
	arcGISBasemapStylesToken,
	basemapOrigin,
}: BackgroundListProps): JSX.Element => {
	const { t } = useGlobalization();
	const [selectedBackground, setSelectedBackground] = useState(
		background ?? BackgroundRegistry.DefaultStyle.key,
	);
	React.useEffect(() => {
		if (basemapOrigin && !background) {
			setSelectedBackground(BackgroundRegistry.DefaultStyle.key);
		}
	}, [basemapOrigin, background]);
	const allBackgroundStyles = useMemo(
		() => BackgroundRegistry.getAllStyles(arcGISBasemapStylesToken),
		[arcGISBasemapStylesToken],
	);

	const backgroundLayerSelected = useCallback(
		(backgroundKey: BackgroundTypes): boolean =>
			selectedBackground === backgroundKey,
		[selectedBackground],
	);

	const onBackgroundChange = useCallback(
		(backgroundKey: BackgroundTypes) => () => {
			setSelectedBackground(backgroundKey);
			backgroundChanged?.(backgroundKey);
		},
		[backgroundChanged],
	);

	useEffect(() => {
		if (background) {
			onBackgroundChange(background);
		}
	}, [background, onBackgroundChange]);

	return (
		<BackgroundWrapper>
			<h2>
				{t('Basemaps', {
					context: 'Heading for listing of map background layers',
				})}
			</h2>
			{allBackgroundStyles.map(({ title, key, description }) => (
				<BackgroundItem
					key={key}
					title={title}
					description={description}
					background={getBackgroundLayerImage(key)}
					tabIndex={tabIndex}
					onClick={onBackgroundChange(key)}
					selected={backgroundLayerSelected(key)}
				/>
			))}
		</BackgroundWrapper>
	);
};
