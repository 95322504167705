import { IconSet, ServiceLayer } from '../../services/types';

import { BackgroundTypes } from '@Map/types';

export type MapboxLayerProps = ServiceLayer & {
	selected?: boolean;
	highlight?: boolean;
	visible: boolean;
	assetFilter?: boolean | string[];
};

export type UniqueLayerProps = Partial<Pick<ServiceLayer, 'paint' | 'layout'>>;

export default abstract class MapboxLayer {
	static SELECTED_COLOR = '#aa0000';
	static SELECTED_COLOR_FILL = '#007ca0';
	static SELECTED_LIGHT_COLOR = '#f1f4f4';
	static UNSELECTED_COLOR = '#4a6067';
	static UNSELECTED_COLOR_FILL = '#768d95';
	static UNSELECTED_COLOR_OUTLINE = '#768d95';
	static HIGHLIGHT_COLOR = '#ffe54d';
	static FILL_OPACITY = 0.4;
	static SCALE = 3;
	protected _id: MapboxLayerProps['id'];
	protected _source: MapboxLayerProps['source'];
	protected _filter: MapboxLayerProps['filter'];
	protected _selected: MapboxLayerProps['selected'];
	protected _highlight: MapboxLayerProps['highlight'];
	protected _sourceLayer: MapboxLayerProps['sourceLayer'];
	protected _zIndex: MapboxLayerProps['zIndex'];
	protected _icon: MapboxLayerProps['icon'];
	protected _selectedIcon: MapboxLayerProps['selectedIcon'];
	protected _iconBackground: MapboxLayerProps['iconBackground'];
	protected _minZoom: MapboxLayerProps['minZoom'];
	protected _maxZoom: MapboxLayerProps['maxZoom'];
	protected _type: ServiceLayer['type'] = 'line';
	protected _iconSet: IconSet = {};
	protected _color: MapboxLayerProps['color'];
	protected _selectedColor: MapboxLayerProps['selectedColor'];
	protected _highlightColor: MapboxLayerProps['highlightColor'];
	protected _labelColor: MapboxLayerProps['labelColor'];
	protected _fillOpacity: MapboxLayerProps['fillOpacity'];
	protected _pattern: MapboxLayerProps['pattern'];
	protected _lineWidth: MapboxLayerProps['lineWidth'];
	protected _lineDashArray: MapboxLayerProps['lineDashArray'];
	protected _background: MapboxLayerProps['background'];
	protected _displayName: MapboxLayerProps['displayName'];
	protected _colorLookup: MapboxLayerProps['colorLookup'];
	protected _selectedColorLookup: MapboxLayerProps['selectedColorLookup'];
	protected _radius: MapboxLayerProps['radius'];
	protected _sort: MapboxLayerProps['sort'];
	protected _scale: MapboxLayerProps['scale'];
	protected _assetFilter: boolean | string[] = false;

	constructor({
		id,
		source,
		filter,
		selected = false,
		highlight = false,
		sourceLayer = undefined,
		zIndex = 0,
		icon,
		iconBackground = true,
		minZoom,
		maxZoom,
		color,
		selectedColor,
		highlightColor,
		labelColor,
		fillOpacity,
		pattern,
		lineWidth,
		lineDashArray,
		background = BackgroundTypes.Streets,
		displayName,
		colorLookup,
		selectedColorLookup,
		radius,
		sort,
		scale,
		assetFilter = false,
	}: MapboxLayerProps) {
		this._id = id;
		this._source = source;
		this._filter = filter;
		this._selected = selected;
		this._highlight = highlight;
		this._sourceLayer = sourceLayer;
		this._zIndex = zIndex;
		this._icon = icon;
		this._iconBackground = iconBackground;
		this._minZoom = minZoom;
		this._maxZoom = maxZoom;
		this._color = color;
		this._selectedColor = selectedColor;
		this._highlightColor = highlightColor;
		this._fillOpacity = fillOpacity;
		this._labelColor = labelColor;
		this._pattern = pattern;
		this._lineWidth = lineWidth;
		this._lineDashArray = lineDashArray;
		this._background = background;
		this._displayName = displayName;
		this._colorLookup = colorLookup;
		this._selectedColorLookup = selectedColorLookup;
		this._radius = radius;
		this._sort = sort;
		this._scale = scale;
		this._assetFilter = assetFilter;
	}

	get id(): MapboxLayerProps['id'] {
		return this._id;
	}

	set iconSet(iconSet: IconSet) {
		this._iconSet = iconSet;
	}

	build(): ServiceLayer {
		const layerProps = this.layerProperties();
		layerProps.paint = {
			...layerProps.paint,
			...this.layerOpacity,
		};
		return {
			id: this._id,
			...(this._filter && { filter: this._filter }),
			type: this._type,
			source: this._source,
			...(this._sourceLayer && { 'source-layer': this._sourceLayer }),
			...layerProps,
			metadata: {
				zIndex: this._zIndex,
				displayName: this._displayName,
			},
			...(this._minZoom && { minzoom: this._minZoom }),
			...(this._maxZoom && { maxzoom: this._maxZoom }),
		};
	}

	abstract layerProperties(): UniqueLayerProps;

	get opacityFallback(): number {
		return this._highlight ? 0.7 : 1;
	}

	get layerOpacity() {
		let propName;
		switch (this._type) {
			case 'heatmap':
				propName = 'heatmap-opacity';
				break;
			case 'fill':
				propName = 'fill-opacity';
				break;
			case 'line':
				propName = 'line-opacity';
				break;
			case 'raster':
				propName = 'raster-opacity';
				break;
			case 'bubble':
			case 'circle':
				propName = 'circle-opacity';
				break;
			case 'symbol':
			default:
				propName = 'icon-opacity';
				break;
		}

		const expression =
			this._assetFilter === true
				? 0.5
				: this._assetFilter === false
				? this.opacityFallback
				: [
						'case',
						['in', ['get', 'id'], ['literal', this._assetFilter]],
						this.opacityFallback,
						0.5,
				  ];
		return { [propName]: expression };
	}
}
