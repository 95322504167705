import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { selectSystemTypes } from '@Selectors/asset';
import { useSelector } from 'react-redux';

const getDefaultSystem = (systemTypes: string[]) => {
	return systemTypes.length === 1 ? systemTypes[0] : '';
};

export const useSystemType = (): [string, Dispatch<SetStateAction<string>>] => {
	const systemTypes = useSelector(selectSystemTypes);
	const defaultSystem = getDefaultSystem(systemTypes);
	const [state, setState] = useState(defaultSystem);

	useEffect(() => {
		if (!state) {
			setState(getDefaultSystem(systemTypes));
		}
	}, [systemTypes]);
	return [state, setState];
};
