import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	clearRisk,
	getRiskScoreResolved,
	getSpatialRecordResolved,
} from '@Actions/risk';

import { RiskStoreState } from '@Types/risk.types';

export const initialState: RiskStoreState = {
	spatialRecords: [],
	riskCondition: {},
};

interface RiskReducer {
	[x: string]: (
		state: RiskStoreState,
		action: AnyAction,
	) => RiskStoreState | undefined;
}

const reducer: RiskReducer = {
	[getSpatialRecordResolved.toString()]: (state, action) => ({
		...state,
		spatialRecords: [...state.spatialRecords, action.payload],
	}),
	[getRiskScoreResolved.toString()]: (state, action) => ({
		...state,
		riskCondition: {
			...state.riskCondition,
			[action.payload.assetId]: action.payload.risk,
		},
	}),
	[clearRisk.toString()]: () => ({
		...initialState,
	}),
};

export default createReducer(initialState, reducer);
