import {
	ASSET_NOT_FOUND,
	ASSET_PROPS_CHANGED,
	LOAD_ICON,
	LOOKUP_COMPLETE,
	SERVICES_ALL_LOADED,
	SERVICE_ERROR,
	SERVICE_LOADED,
	SERVICE_LOADING,
	SERVICE_RELOADED,
} from './services/constants';
import { Background, ConfigLayer } from './panel/types';
import {
	BackgroundTypes,
	BasemapType,
	DistanceUnit,
	EditorModes,
	FitBoundsOptions,
	LayerToggle,
	LocationBox,
	MapFunction,
	MapHighlightedAsset,
	MapModes,
	MapPosition,
	MapSelectedAssets,
	MapSetState,
	MapState,
	SelectionMode,
	SelectionOptions,
} from './types';
import { Bbox, SearchEndpoint, SelectedSearchResult } from './geocoder/types';
import {
	CONFIG_LOADED,
	CONFIG_RESET,
	CONFIG_UPDATED,
	LAYERS_CREATED,
	LAYER_UPDATED,
	MIN_ZOOM,
	RESET_MIN_ZOOM,
} from './layers/constants';
import {
	CameraOptions,
	GeoJSONSource,
	GeolocateControl,
	LngLat,
	LngLatBounds,
	LngLatLike,
	MapMouseEvent,
	MapboxGeoJSONFeature,
	Map as MapboxMap,
	NavigationControl,
	RequestParameters,
	ScaleControl,
} from 'mapbox-gl';
import {
	DataGridFilterObject,
	GeoJSONFeature,
	GeoJsonProperties,
	SelectedAsset,
} from './features/types';
import {
	DataServiceType,
	GeoJsonDataType,
	ServiceAssetNotFound,
	ServiceLoadAssetProperties,
	ServiceLoadIcon,
	ThemeEndpoint,
	TracingEndpoint,
} from './services/types';
import ExtentsControl, { FIT_TO_EXTENTS } from './ExtentsControl';
import GridControl, { GRID_CONTROL_OFF, GRID_CONTROL_ON } from './GridControl';
import LanguageControl, { LANGUAGE_CHANGE } from './LanguageControl';
import LayerManager, {
	LayerDebugInfo,
	MapHiddenLayers,
} from './layers/LayerManager';
import MapboxUtils, { CameraAction, MoveEndEvent } from './helpers/mapbox';
import {
	PANEL_BACKGROUND_CHANGED,
	PANEL_BACKGROUND_ORIGIN_CHANGED,
	PANEL_EDITOR_CANCEL,
	PANEL_LAYER_TOGGLE,
} from './panel/constants';
import PitchControl, {
	PITCH_CONTROL_OFF,
	PITCH_CONTROL_ON,
} from './PitchControl';
import PrintControl, { PRINT_PREVIEW } from './PrintControl';
import SymbolLoader, { SymbolCallback, Symbols } from './symbols/SymbolLoader';
import { UnitSystem, defaultUnitSystem } from '@Components/Inputs/units';
import {
	boundsAreNotInitialized,
	convertCoordsToBounds,
	convertToLayerVisibility,
	coordinatesSame,
	deleteEmptyValues,
	dispatchTargetedEvent,
	expandBoundary,
	functionalityEnabled,
	getDisplayMode,
	normalizeCoordinates,
	notUndefined,
	shallowEqual,
} from './utils';
import logger, { CustomLogger, StatusType } from './logger/Logger';

import AnimationControlBridge from './timeline/AnimationControlBridge';
import { BackgroundRegistry } from './panel/BackgroundRegistry';
import { CONFIG_AND_SERVICES_LOADED } from './constants';
import { CompositeLayerProps } from './layers/types';
import ConfigAdaptor from './layers/ConfigAdaptor';
import { DefaultTheme } from 'styled-components';
import DropPin from './geocoder/DropPin';
import { EventBridge } from './events/EventBridge';
import Events from './events/Events';
import HoverPopup from './popup/HoverPopup';
import LayerAdaptor from './layers/LayerAdaptor';
import { MapMethodProps } from '../mapElement';
import Messages from './notifications/Messages';
import PanelControl from './panel/PanelControl';
import { SEARCH_SELECT } from './geocoder/constants';
import SearchControl from './geocoder/Search';
import SelectionManager from './features/SelectionManager';
import ServiceManager from './services/ServiceManager';
import ShadowElement from './ShadowElement';
import Timeline from './timeline/Timeline';
import TraceService from './services/TraceService';
import defineElement from './defineElement';
import styles from './styles/map.scss';

type MapElement = HTMLElement;

interface MapInternalPosition {
	center: LngLat;
	zoom: number;
	internal: boolean;
}

@defineElement('inno-map')
export default class Map extends ShadowElement
	implements Required<MapMethodProps> {
	private static readonly BackgroundAttributeName = 'background';
	private static readonly MapKeyAttributeName = 'mapKey';
	private static readonly ArcGISBasemapStylesTokenAttributeName =
		'arcGISBasemapStylesToken';
	private static readonly BasemapOriginAttributeName = 'basemaporigin';
	private static readonly ModeAttributeName = 'mode';
	private static readonly PanelAttributeName = 'panel';
	private static readonly PanelOpenAttributeName = 'panelOpen';
	private static readonly LogLevelAttributeName = 'loglevel';
	private static readonly GeocoderAttributeName = 'geocoderid';
	private static readonly DistanceUnitAttributeName = 'distanceunit';
	private static readonly AnimationControlAttributeName =
		'animationControlId';
	private static readonly HoverPopupAttributeName = 'hoverPopup';
	private static readonly HoverPopupDebounce = 'hoverPopupDebounce';
	private static readonly ShowMinWarningAttributeName = 'showMinZoomWarning';
	private static readonly PitchControlAttributeName = 'pitchControl';
	private static readonly CBMaxValuesAttributeName = 'colorByMaxValues';
	private static readonly ThemeEditorModeAttributeName = 'themeEditorMode';
	private static readonly EnableCBRange = 'enableColorByAttributeRange';
	private static readonly EnableCBRangeHeatmap = 'enableColorByRangeHeatmap';
	private static readonly RestrictSearchToBounds = 'restrictSearchToBounds';
	private static readonly ShowMultiThemes = 'showMultiThemes';
	private static readonly LocalStoragePrefix = 'localStoragePrefix';
	private static readonly UnitSystemAttributeName = 'unitSystem';
	private static readonly GridControlAttributeName = 'gridControl';
	private static readonly PrintPreviewAttributeName = 'printpreview';
	private static readonly DefaultThemeIdAttributeName = 'defaultthemeid';
	private static readonly ZoomToHighlightedAssetsAttributeName =
		'zoomToHighlightedAssets';
	private static readonly MapPaddingTop = 50;
	private static readonly MapPaddingRight = 70;
	private static readonly MapPaddingLeft = 50;
	private static readonly MapPaddingBottom = 50;
	private static readonly MapPaddingLeftPanelOpen = 500;
	private static readonly MapPaddingRightPanelOpen = 370;
	private static readonly BearerToken = 'bearerToken';

	private _container: MapElement;
	private _map: MapboxMap | null = null;
	private _serviceManager = new ServiceManager();
	private _mapLoaded = false;
	private _layerManager: LayerManager | null = null;
	private _panelControl: PanelControl | null = null;
	private _defaultLayers = true;
	private _configAdaptor: ConfigAdaptor | null = null;
	private _background: Background | null = null;
	private _center: LngLat | null = null;
	private _zoom: number | null = null;
	private _positionSetExternally = false;
	private _propertyPanelEnabled = false;
	private _propertyPanelOpen = false;
	private _hiddenLayers: MapHiddenLayers = [];
	private _displayMode: MapModes = MapModes.Full;
	private _messageControl = new Messages();
	private _serviceLoading = false;
	private _allLoaded = true;
	private _configLoaded = false;
	private _selectionManager = new SelectionManager();
	private _scaleControl: ScaleControl | null = null;
	private _defaultBounds: number[] = [];
	private _eventBridge = new EventBridge();
	private _symbolLoader!: SymbolLoader;
	private _timeline = new Timeline();
	private _boundsFit: FitBoundsOptions = {};
	private _searchControl: SearchControl | null = null;
	private _searchResult: SelectedSearchResult | null = null;
	private _selectionOptions: SelectionOptions = {
		minZoom: 14,
		maxFeatures: 70,
	};
	private _currentBounds: number[] = [];
	private _minZoomForWarning = -1;
	private _showMinZoomWarning = true;
	private _hoverPopup: HoverPopup | null = null;
	private _themeEndpoint: ThemeEndpoint | null = null;
	private _mapboxUtils: MapboxUtils | null = null;
	private _dropPin: DropPin | null = null;
	private _traceService: TraceService | null = null;
	private _languageControl: LanguageControl | null = null;
	private _colorByMaxValues = 100;
	private _pitchControl: PitchControl | null = null;
	private _accessToken!: string;
	private _basemapsToken!: string;
	private _basemapOrigin!: BasemapType;
	private _localStoragePrefix: string | null = null;
	private _unitSystem = UnitSystem.Metric;
	private _showMultiThemes = false;
	private _gridControl: GridControl | null = null;
	private _printControl: PrintControl | null = null;
	private _disableFitToExtents = false;
	private _bearerToken: string | null = null;
	private _highlightedAssets: MapHighlightedAsset[] = [];
	private _tryHighlight = false;
	private _zoomToHighlightedAssets = false;
	private _mapState: MapState = {
		drawing: false,
	};

	constructor() {
		super();
		this._container = document.createElement('div');
		this._container.id = 'root';
		this.rootElement = this._container;
		this.addStyles(styles);
		this.attachElement();
	}

	connectedCallback(): void {
		this.init();
	}

	disconnectedCallback(): void {
		this._serviceManager.deleteAll();
	}

	static get observedAttributes(): string[] {
		return [
			Map.BasemapOriginAttributeName,
			Map.LogLevelAttributeName,
			Map.DistanceUnitAttributeName,
			Map.PrintPreviewAttributeName,
		];
	}

	set position({ center, zoom, internal }: MapInternalPosition) {
		let positionChanged = false;
		if (this._centerChanged(center)) {
			positionChanged = true;
		}
		if (this._zoom !== +zoom) {
			positionChanged = true;
		}
		this._center = center;
		this._zoom = zoom;
		if (positionChanged) {
			if (internal) this._positionChanged({ center, zoom });
			else this._map?.jumpTo({ center, zoom });
		}
	}

	get mapPosition(): MapPosition {
		return {
			center: this._center?.toArray() ?? [0, 0],
			zoom: this._zoom ?? 3,
		};
	}

	get bounds(): number[] {
		return this._currentBounds;
	}

	get layerDebug(): LayerDebugInfo[] | undefined {
		return this._layerManager?.debugInfo;
	}

	get map(): MapboxMap | null {
		return this._map;
	}

	setMapState: MapSetState = (key, value) => {
		this._mapState[key] = value;
	};

	attributeChangedCallback(
		name: string,
		_oldValue: string,
		newValue: string,
	): void {
		switch (name) {
			case Map.BasemapOriginAttributeName: {
				this._basemapOrigin = (newValue as BasemapType) ?? 'mapbox';
				BackgroundRegistry.BasemapOrigin = newValue as BasemapType;
				if (
					(this._basemapOrigin === 'arcgis' &&
						this._basemapsToken &&
						this._basemapsToken.length > 0) ||
					this._basemapOrigin === 'mapbox'
				) {
					this._background = BackgroundRegistry.getStyleWithFallback(
						BackgroundRegistry.DefaultStyle.key,
						this._basemapsToken,
					);
					if (this._panelControl) {
						this._panelControl._onBackgroundChanged(
							this._background.key ||
								BackgroundRegistry.DefaultStyle.key,
						);
					} else {
						this._map?.setStyle(this._background?.uri ?? '');
					}
					this.updateMap(false);
				}
				break;
			}
			case Map.LogLevelAttributeName: {
				logger.setLevel(newValue as StatusType);
				break;
			}
			case Map.DistanceUnitAttributeName: {
				this._scaleControl?.setUnit(newValue as DistanceUnit);
				break;
			}
			case Map.PrintPreviewAttributeName: {
				this.printPreview = newValue === 'true';
				break;
			}
		}
	}

	init(): void {
		this._basemapsToken =
			this.getAttribute(Map.ArcGISBasemapStylesTokenAttributeName) || '';
		BackgroundRegistry.ArcGISBasemapsToken = this._basemapsToken;

		this._basemapOrigin =
			(this.getAttribute(
				Map.BasemapOriginAttributeName,
			) as BasemapType) || ('mapbox' as BasemapType);
		BackgroundRegistry.BasemapOrigin = this._basemapOrigin;
		this._bearerToken = this.getAttribute(Map.BearerToken);
		this._accessToken = this.getAttribute(Map.MapKeyAttributeName) || '';

		const backgroundKey =
			this.getAttribute(Map.BackgroundAttributeName) ||
			BackgroundRegistry.DefaultStyle.key;
		this._background = BackgroundRegistry.getStyleWithFallback(
			backgroundKey,
			this._basemapsToken,
		);

		this._propertyPanelEnabled =
			this.getAttribute(Map.PanelAttributeName) === 'true';

		this._displayMode = getDisplayMode(
			this.getAttribute(Map.ModeAttributeName) as MapModes,
		);

		this._showMinZoomWarning =
			this.getAttribute(Map.ShowMinWarningAttributeName) === 'false'
				? false
				: true;

		this._localStoragePrefix = this.getAttribute(Map.LocalStoragePrefix);

		this._unitSystem = this._getUnitSystem();

		this._showMultiThemes =
			this.getAttribute(Map.ShowMultiThemes) === 'true';

		this.printPreview =
			this.getAttribute(Map.PrintPreviewAttributeName) === 'true';

		this._zoomToHighlightedAssets =
			this.getAttribute(Map.ZoomToHighlightedAssetsAttributeName) ===
			'true';

		const colorByMaxValues = parseInt(
			this.getAttribute(Map.CBMaxValuesAttributeName) ?? '',
		);
		if (!isNaN(colorByMaxValues)) {
			this._colorByMaxValues = colorByMaxValues;
		}

		const logLevel = this.getAttribute(Map.LogLevelAttributeName);
		if (logLevel) {
			logger.setLevel(logLevel as StatusType);
		}

		this._map = new MapboxMap({
			container: this._container,
			style: this._background.uri,
			interactive: this._functionalityEnabled(MapFunction.interactive),
			// limit how small you can make the countries
			minZoom: 2,
			// maximum values for longitude (-180 to 180) and
			// latitude (-90 to 90, except mapbox shows whitespace
			// when outside -85 to 85 range)
			maxBounds: new LngLatBounds(
				// sw corner
				new LngLat(-180, -85),
				// ne corner
				new LngLat(180, 85),
			),
			renderWorldCopies: false,
			transformRequest: (url: string): RequestParameters => {
				const originsRequiringCredentials = this._serviceManager
					.getAll()
					.filter(s => s.requiresCredentials && s.baseUrl != null)
					.map(s => new URL(s.baseUrl as string));

				// can only include credentials if not CORS
				const credentials =
					url.match(window.location.host) ||
					originsRequiringCredentials.some(c =>
						url.startsWith(c.origin),
					)
						? 'include'
						: undefined;

				return {
					url,
					credentials,
				};
			},
			// to disable rotation and pitch
			dragRotate: false,
			touchZoomRotate: false,
			accessToken: this._accessToken,
			// to allow exporting the canvas as a png
			preserveDrawingBuffer: true,
		});

		this._fixAccessibilityIssues(this._container);

		// deals with loading the symbol/icons for the map
		this._symbolLoader = new SymbolLoader();

		this._layerManager = new LayerManager(this._map);
		this._layerManager.background = this._background.key;

		this._mapboxUtils = new MapboxUtils(this._map, this._layerManager);
		this._mapboxUtils.setCameraPaddingFunction(
			this.getCameraPadding.bind(this),
		);

		const hoverPopupAttached =
			this.getAttribute(Map.HoverPopupAttributeName) === 'true';
		if (
			this._functionalityEnabled(MapFunction.hoverPopup) &&
			hoverPopupAttached
		) {
			this._hoverPopup = new HoverPopup(
				this._map,
				this._dispatchEvent.bind(this),
				this.getAttribute(Map.HoverPopupDebounce),
			);
		}

		if (this._basemapOrigin === 'mapbox') {
			this._addLanguageControl();
		}

		this._addGeocoder();

		this._addAnimationControl();

		this._addDefaultVisualControls();

		this._map.on('error', ({ error }) => {
			logger.error('Mapbox internal error', {
				error: error.name,
				message: error.message,
			});
		});

		this._map.on('click', this.mapClick);

		this._map.on('mousemove', this.mapMouseMove);

		this._map.on('moveend', this._moveEnd);

		// event fired when styles have loaded or changed
		this._map.on('styledata', e => {
			const attrib = this._container.getElementsByClassName(
				'mapboxgl-ctrl-attrib-inner',
			)[0];
			if (/(arcgis)|(osm)/g.test(e?.style?.stylesheet?.glyphs ?? '')) {
				const esriAttribLabel = attrib.getElementsByClassName(
					'powered-by-esri',
				);

				if (!esriAttribLabel.length) {
					const esriAttrib = document.createElement('div');
					esriAttrib.innerHTML =
						'<div>Powered by <a style="text-decoration: none;" rel="noreferrer" target="_blank" href="https://www.esri.com/">Esri</a></div>';
					esriAttrib.setAttribute(
						'style',
						'display: flex; flex-direction: row-reverse;',
					);
					esriAttrib.setAttribute('class', 'powered-by-esri');
					const attribContainer = this._container.getElementsByClassName(
						'mapboxgl-ctrl-bottom-right',
					)[0];

					attribContainer.setAttribute(
						'style',
						'margin-left: 100px;',
					);
					attrib.appendChild(esriAttrib);
				}
			}

			this.updateMap(false);
		});

		this._map.on('load', () => {
			this.updateMap();
		});

		this._map.on('styledataloading', () => {
			this._mapLoaded = false;
		});

		// the this._map.loaded function doesn't
		// seem to work after the style has been changed
		// so tracking the loaded state here
		this._map.on('style.load', () => {
			this._mapLoaded = true;
		});

		this._map.on('styleimagemissing', this._loadIcon);

		/**
		 * Function will call when zoom animation has completed
		 */
		this._map.on(
			'zoomend',
			({ mousePosition }: { mousePosition: MapMouseEvent }) => {
				this.selectGeocoderSearchResult();
				if (mousePosition) {
					// update the cursor when zoom has finished
					this.mapMouseMove(mousePosition);
				}
			},
		);

		/**
		 * Function will call when data has finished loading
		 */
		this._map.on('sourcedata', () => {
			this.selectGeocoderSearchResult();
		});

		/**
		 * Function will call when map has finished updating
		 * and no longer processing any changes
		 */
		this._map.on('idle', () => {
			this.findSelectedFeatures();
			/**
			 * Hopefully the asset from the geocoder will be selected
			 * once zoom animation completes, however if the map tiles
			 * haven't finished loading then need to try final time
			 */
			this.selectGeocoderSearchResult(true);
			this.highlightAssetsFromGrid();
			this._updatePrintPreview();
			this._setupAfterAllLoaded();
		});

		this.addServiceEventListeners();

		if (window.Cypress) {
			window.map = this._map;
		}
	}

	addServiceEventListeners(): void {
		const services: Events[] = [this._timeline, this._serviceManager];
		if (this._layerManager) services.push(this._layerManager);
		if (this._panelControl) services.push(this._panelControl);
		if (this._pitchControl) services.push(this._pitchControl);
		if (this._gridControl) services.push(this._gridControl);
		this._eventBridge.addServices(services);
		this._eventBridge.setNonPropagatingEvents([LOAD_ICON]);

		this._serviceManager.on(SERVICE_LOADING, () => {
			if (!this._serviceLoading) {
				this._messageControl.loading();
				this._serviceLoading = true;
			}
		});

		// update the map once the service data has been loaded
		this._serviceManager.on(SERVICE_LOADED, () => {
			this.updateMap();
		});
		this._serviceManager.on(SERVICE_RELOADED, () => {
			this.updateMap(false, true);
		});

		// listen for service errors
		this._serviceManager.on(SERVICE_ERROR, ({ criticalError }) => {
			// To update the layers panel as soon as the error occurs
			this.updateMap();
			if (criticalError) {
				this._messageControl.error();
			}
		});

		this._serviceManager.on(SERVICES_ALL_LOADED, () => {
			this._serviceLoading = false;
			this._loadMapboxLayers();
		});

		this._serviceManager.on(LOAD_ICON, this._loadIcon);

		this._serviceManager.on(
			ASSET_PROPS_CHANGED,
			({ id, data }: ServiceLoadAssetProperties) => {
				this._selectionManager.updateAssetProperties(id, data);
				this.selectedAssetsChanged(
					this._selectionManager.assetProperties,
				);
			},
		);

		this._serviceManager.on(
			ASSET_NOT_FOUND,
			({ id }: ServiceAssetNotFound) => {
				this._selectionManager.deleteOne(id);
				this.selectedAssetsChanged(
					this._selectionManager.assetProperties,
				);
			},
		);

		this._layerManager?.on(LAYERS_CREATED, () => {
			if (this._defaultLayers && this._layerManager?.compositeLayers) {
				if (this._panelControl) {
					const panelConfig = new LayerAdaptor(
						this._layerManager.compositeLayers,
					).generateConfig();
					this.setLayerConfig(panelConfig);
				} else {
					this._layerManager?.setLayerVisibility(this._hiddenLayers);
				}
			}
			this._dispatchEvent('layersDebug', this._layerManager?.debugInfo);
		});

		this._layerManager?.on(LAYER_UPDATED, () => {
			if (
				this._defaultLayers &&
				this._layerManager?.compositeLayers &&
				this._panelControl
			) {
				const panelConfig = new LayerAdaptor(
					this._layerManager.compositeLayers,
				).generateConfig();
				this.setLayerConfig(panelConfig);
			}
			this._dispatchEvent('layersDebug', this._layerManager?.debugInfo);
		});

		this._serviceManager.on(LOOKUP_COMPLETE, () => {
			this._loadMapboxLayers();
		});

		this._panelControl?.on(
			PANEL_BACKGROUND_CHANGED,
			({ backgroundInfo }) => {
				if (/(arcgis)|(osm)/g.test(backgroundInfo.uri)) {
					this._languageControl?.removeControl();
				} else {
					this._languageControl?.addControl();
				}
			},
		);

		this._panelControl?.on(PANEL_EDITOR_CANCEL, () => {
			this._loadMapboxLayers();
		});

		this._panelControl?.on(
			PANEL_LAYER_TOGGLE,
			({ hiddenLayers }: LayerToggle) => {
				this._hiddenLayers = hiddenLayers;
			},
		);
	}

	_loadIcon = ({ id }: ServiceLoadIcon): void => {
		// don't load the image if no id
		if (!id) return;
		// no point drawing image if it already exists
		if (this._map?.hasImage(id)) return;
		this._symbolLoader.getImage(id, this._symbolLoaded);
		if (id.match(/-selected/)) return;
		this._symbolLoader.getImage(`${id}-selected`, this._symbolLoaded);
	};

	_symbolLoaded: SymbolCallback = (image, id) => {
		// if the image is now in the map but wasn't when the event
		// first fired then don't add again to the map
		if (!this._map?.hasImage(id)) this._map?.addImage(id, image);
	};

	_overwriteSymbols: SymbolCallback = (image, id) => {
		// remove the old image and then add the new one
		if (this._map?.hasImage(id)) this._map?.removeImage(id);
		this._map?.addImage(id, image);
	};

	setFitBoundsOptions(boundsFit: FitBoundsOptions): void {
		this._boundsFit = boundsFit;
	}

	fitBoundsFromCoords(
		coordinates: LngLatLike[],
		options: FitBoundsOptions = {},
	): void {
		const bounds = convertCoordsToBounds(coordinates);

		// HACK: If the bounds we're being sent to are: 0,0 something has gone wrong,
		//       so disregard attempting to fit.
		if (boundsAreNotInitialized(bounds)) {
			return;
		}

		this.fitBoundsWithOptions(bounds, options);
	}

	fitBoundsWithOptions(
		bounds: LngLatBounds,
		options: FitBoundsOptions = {},
	): void {
		const padding = this._mapboxUtils?.getCameraPadding();

		const defaultOptions: Partial<FitBoundsOptions & {
			duration: number;
		}> = {
			padding,
			duration: 0,
			maxZoom: 18,
		};

		const mergedOptions = deleteEmptyValues({
			...defaultOptions,
			...this._boundsFit,
			...options,
		});

		this._map?.fitBounds(bounds, mergedOptions);
	}

	propertyPaneOpen(): boolean {
		return this._propertyPanelEnabled && this._propertyPanelOpen;
	}

	layerPanelOpen(): boolean {
		return !!this._panelControl?.panelOpen;
	}

	gridPanelOpen(): boolean {
		return !!this._gridControl?.active;
	}

	getCameraPadding() {
		const containerHeight = this._map
			?.getContainer()
			.getBoundingClientRect().height;
		const bottomPadding = containerHeight ? containerHeight / 2 + 50 : 0;
		return {
			top: Map.MapPaddingTop,
			bottom: this.gridPanelOpen() ? bottomPadding : Map.MapPaddingBottom,
			left: this.propertyPaneOpen()
				? Map.MapPaddingLeftPanelOpen
				: Map.MapPaddingLeft,
			right: this.layerPanelOpen()
				? Map.MapPaddingRightPanelOpen
				: Map.MapPaddingRight,
		};
	}

	setDataServices(dataServices: DataServiceType<unknown>[]): void {
		logger.debug('data service updated', dataServices);
		const { deleted, added, changed } = this._serviceManager.addMany(
			dataServices,
		);
		logger.debug('data service changes', { deleted, added, changed });
		if (deleted.length) {
			deleted.forEach(serviceId =>
				this._layerManager?.deleteByServiceId(serviceId),
			);
		}
		if (added.length || changed.length) {
			this._loadLayers();
		}
	}

	addDataService(dataService: DataServiceType<unknown>): void {
		this._serviceManager.addOne(dataService);
	}

	clearDataServices(): void {
		this._serviceManager.deleteAll();
		this._layerManager?.deleteAll();
	}

	setPosition({ center, zoom }: { center: number[]; zoom: number }): void {
		this._positionSetExternally = true;
		this.position = {
			center: LngLat.convert(center as LngLatLike),
			zoom,
			internal: false,
		};
	}

	resetPosition(): void {
		this._positionSetExternally = false;
		this._fitToExtents();
	}

	private _centerChanged(center: LngLat): boolean {
		if (this._center) {
			const prev = this._center.toArray();
			const current = center.toArray();
			if (prev[0] !== current[0] || prev[1] !== current[1]) return true;
			return false;
		}
		return true;
	}

	setSelectedAssets(selectedAssetIds: MapSelectedAssets): void {
		this._selectionManager.externalSelectedAssets = selectedAssetIds;
		if (selectedAssetIds.length) {
			this.findSelectedFeatures();
		} else {
			this.clearSelectedFeatures();
		}
	}

	setHighlightedAssets(highlightAssets: MapSelectedAssets): void {
		this._selectionManager.highlightAssets(highlightAssets);
		this._layerManager?.clearHighlightedLayers();
		Object.entries(this._selectionManager.highlightedPerLayer).forEach(
			([layerId, ids]) => {
				this._layerManager?.setHighlightedItemsOnLayer(layerId, ids);
			},
		);
	}

	setHighlightedAssetsFromAssetGrid(
		highlightedAssets: MapHighlightedAsset[],
		disableZoom?: boolean,
	) {
		this._tryHighlight = true;
		this._highlightedAssets = highlightedAssets;
		this.highlightAssetsFromGrid(disableZoom);
	}

	highlightAssetsFromGrid(disableZoom?: boolean) {
		if (!this._tryHighlight) return;
		const layerManager = this._layerManager;
		if (!layerManager) {
			console.error('Layer manager not available');

			return;
		}

		const highlighted: MapSelectedAssets = this._highlightedAssets
			.map(asset => ({
				id: asset._id,
				layerId: layerManager.getLayerIdByAssetType(
					asset.systemType,
					asset.assetType,
				),
				geometry: asset.geometry,
			}))
			.filter((asset): asset is Required<SelectedAsset> =>
				Boolean(asset.layerId),
			);

		if (this._highlightedAssets.length !== highlighted.length) return;

		this.setHighlightedAssets(highlighted);
		this._tryHighlight = false;
		if (this._zoomToHighlightedAssets && !disableZoom) {
			this.zoomToAssets(highlighted);
		}
	}

	zoomToAssets(zoomToAssets: MapSelectedAssets): void {
		if (!this._layerManager?.loadedLayers.length) return;
		const features = this._layerManager.loadedLayers.flatMap(
			({ id, sourceLayer }) => {
				return zoomToAssets
					.filter(asset => asset.layerId === id)
					.map(
						asset =>
							this._selectionManager
								.getAssetById(asset.id)
								?.asGeojsonFeature(sourceLayer) ??
							this._selectionManager
								.getHighlightedAssetById(asset.id)
								?.asGeojsonFeature(sourceLayer),
					)
					.filter(notUndefined);
			},
		);
		this.zoomToFeatures(features);
	}

	setTracingEndpoint({ url, token }: TracingEndpoint): void {
		if (this._traceService) this._traceService.destroy();
		this._traceService = new TraceService(url, token);
	}

	async traceFromAsset(assetId: string, dsTrace?: boolean): Promise<void> {
		if (!this._traceService) return;
		const asset = this._selectionManager.getAssetById(assetId);
		if (!asset) return;
		this._messageControl.loadingTrace();
		await this._traceService.getTrace(assetId, dsTrace);
		this._messageControl.clear();
		const ids = this._traceService.linkIds;
		this._layerManager?.setSelectedItemsOnLayer(asset.layerId, ids);
	}

	setHiddenLayers(hiddenLayers: MapHiddenLayers): void {
		if (shallowEqual(hiddenLayers, this._hiddenLayers)) return;
		this._hiddenLayers = hiddenLayers;
		logger.debug('hidden layers updated', hiddenLayers);
		if (this._configAdaptor) {
			this._configAdaptor.layerVisibility = convertToLayerVisibility(
				hiddenLayers,
			);
			this._layerManager?.setLayerVisibility(
				this._configAdaptor.layerVisibility,
			);
		} else {
			this._layerManager?.setLayerVisibility(hiddenLayers);
		}
	}

	resize(): void {
		this._map?.resize();
	}

	setLogger(customLogger: CustomLogger): void {
		logger.setCustom(customLogger);
	}

	_defaultBoundsValid(bounds: number[]): boolean {
		if (!bounds) return false;
		if (bounds.length !== 4) return false;
		if (
			bounds[0] === 0 &&
			bounds[1] === 0 &&
			bounds[2] === 0 &&
			bounds[3] === 0
		) {
			return false;
		}
		return true;
	}

	_defaultBoundsChanged(bounds: number[]): boolean {
		return JSON.stringify(this._defaultBounds) !== JSON.stringify(bounds);
	}

	/** Set the default bounds:
	 * 1. If we don't have a position set already.
	 * 2. If the value is valid.
	 * 3. If the bounds is different from the one already set.
	 */
	setDefaultBounds(
		defaultBounds: number[],
		unit = UnitSystem.Metric,
		searchRadius = 5,
	): void {
		if (
			this._defaultBoundsValid(defaultBounds) &&
			this._defaultBoundsChanged(defaultBounds)
		) {
			this._defaultBounds = defaultBounds;
			if (this._center == null && this._zoom == null) {
				this._fitToDefaultBounds();
			}
			if (this._searchControl) {
				this._searchControl.bounds = expandBoundary(
					defaultBounds as Bbox,
					searchRadius,
					unit === UnitSystem.Metric ? 'kilometers' : 'miles',
				);
			}
		}
	}

	setDataGridFilter(
		datagridFilter: DataGridFilterObject,
		endpoint: string | undefined,
	): void {
		this._layerManager?.setDataGridFilterObject(
			datagridFilter,
			endpoint,
			this._bearerToken,
		);
	}

	private _fitToDefaultBounds() {
		this._map?.fitBounds(
			this._defaultBounds as [number, number, number, number],
			{
				duration: 0,
			},
		);
	}

	setSearchEndpoints(searchEndpoints: SearchEndpoint[]): void {
		this._searchControl?.setSearchEndpoints(searchEndpoints);
	}

	setSelectionOptions(options: Partial<SelectionOptions>): void {
		this._selectionOptions = {
			...this._selectionOptions,
			...options,
		};
	}

	setDisableFitToExtents(disable: boolean) {
		this._disableFitToExtents = disable;
	}

	setLayerConfig(config: ConfigLayer[]): void {
		logger.debug('layer config updated', config);
		this._defaultLayers = false;
		if (this._configAdaptor) {
			this._configAdaptor.updateLayerConfig(config);
		} else {
			this._configAdaptor = new ConfigAdaptor(
				config,
				this._symbolLoader,
				this._serviceManager,
				this._themeEndpoint,
				this._colorByMaxValues,
				this._localStoragePrefix,
				this._unitSystem,
				this.getAttribute(Map.DefaultThemeIdAttributeName),
			);
			this._configAdaptor.localStoragePrefix = this._localStoragePrefix;
			this._eventBridge.addService(this._configAdaptor);
			if (this._panelControl) {
				this._panelControl.configAdaptor = this._configAdaptor;
			}
			this._loadLayers();
			this._configAdaptor.on(CONFIG_LOADED, () => {
				this._configLoaded = true;
				this._loadLayers();
				this._restorePitchControl();
			});
			this._configAdaptor.on(CONFIG_RESET, () => {
				this._loadLayers();
				this._restorePitchControl();
			});
			this._configAdaptor.on(CONFIG_UPDATED, () => {
				this._loadLayers();
				this._restorePitchControl();
			});
			this._configAdaptor.on(LOAD_ICON, this._loadIcon);
			this._configAdaptor.on(MIN_ZOOM, ({ minZoom }) => {
				this.updateMinZoomForWarning(minZoom);
			});
			this._configAdaptor.on(RESET_MIN_ZOOM, this.resetMinZoomForWarning);
		}
	}

	private async _loadLayers(): Promise<void> {
		if (!this._configAdaptor) return;
		if (this._panelControl) {
			this._panelControl.layers = this._configAdaptor.convertForPanel();
			if (this._showMultiThemes) {
				this._panelControl.themeName = this._configAdaptor.themeName;
				this._panelControl.themeList = await this._configAdaptor?.getThemeList();
				this._panelControl.selectedThemeId = this._configAdaptor.getSelectedThemeId();
			}
			logger.debug('load layer panel', {
				panelLayers: this._panelControl?.layers,
				layerVisibility: this._configAdaptor?.layerVisibility,
			});
		}
		this._loadMapboxLayers();
	}

	private _loadMapboxLayers() {
		if (!this._configAdaptor) return;
		logger.debug('load mapbox layers', {
			panelLayers: this._panelControl?.layers,
			layerVisibility: this._configAdaptor?.layerVisibility,
		});
		this._layerManager?.addMany(this._configAdaptor.convertForLayers());
	}

	setIconSet(icons: Symbols): void {
		this._symbolLoader = new SymbolLoader(icons);
		this._symbolLoader.loadAll(this._overwriteSymbols);
	}

	setPanelElement(element: HTMLDivElement | null): void {
		if (this._panelControl) {
			this._panelControl.layerPanel = element;
		}
	}

	setSearchElement(element: HTMLDivElement | null): void {
		if (this._searchControl) {
			this._searchControl.searchElement = element;
		}
	}

	setPreviewElement(element: HTMLDivElement | null): void {
		if (this._printControl) {
			this._printControl.previewElement = element;
		}
	}

	setMessageElement(element: HTMLDivElement | null): void {
		this._messageControl.messageElement = element;
	}

	setThemeEndpoint(themeEndpoint: ThemeEndpoint): void {
		this._themeEndpoint = themeEndpoint;
		if (this._configAdaptor) {
			this._configAdaptor.themeEndpoint = themeEndpoint;
		}
	}

	updateMap(fitToExtents = true, reload = false): void {
		// cannot update the map while it is loading
		if (!this._mapLoaded) return;

		this._serviceManager.getAll().forEach(service => {
			const { layers, dataSources, loaded, id, iconSet } = service;
			if (loaded) {
				dataSources?.map(({ id, source }) => {
					this._mapboxUtils?.updateSource(id, source);
				});
			}

			if (this._defaultLayers) {
				this._layerManager?.addMany(
					(layers as CompositeLayerProps[]) ?? [],
					id,
				);
			} else if (!reload) {
				this._layerManager?.redrawByServiceId(id, loaded, iconSet);
			}
		});

		if (
			!this._disableFitToExtents &&
			fitToExtents &&
			!this._positionSetExternally
		) {
			this._fitToExtents();
		}
	}

	private _addDefaultVisualControls(): void {
		if (!this._map) {
			return;
		}

		if (this._functionalityEnabled(MapFunction.navigationControl)) {
			this._map.addControl(new NavigationControl({ showCompass: false }));
		}

		if (this._functionalityEnabled(MapFunction.geolocateControl)) {
			this._map.addControl(
				new GeolocateControl({
					positionOptions: {
						enableHighAccuracy: true,
					},
					trackUserLocation: true,
				}),
			);
		}

		if (this._functionalityEnabled(MapFunction.scaleControl)) {
			const unitAttribute = this.getAttribute(
				Map.DistanceUnitAttributeName,
			) as DistanceUnit;
			const unit = Object.values(DistanceUnit).includes(unitAttribute)
				? unitAttribute
				: DistanceUnit.metric;
			this._scaleControl = new ScaleControl({
				maxWidth: 80,
				unit,
			});
			this._map.addControl(this._scaleControl);
		}

		if (this._functionalityEnabled(MapFunction.extentsControl)) {
			const extentsControl = new ExtentsControl();
			this._map.addControl(extentsControl);
			extentsControl.on(FIT_TO_EXTENTS, this._fitToExtents);
		}

		if (this._functionalityEnabled(MapFunction.backgroundControl)) {
			if (this._basemapsToken) {
				BackgroundRegistry.ArcGISBasemapsToken = this._basemapsToken;
			}

			this._panelControl = new PanelControl(
				this._dispatchEvent.bind(this),
				this._background?.key as BackgroundTypes,
				this._colorByMaxValues,
				this._unitSystem,
				this.getAttribute(Map.PanelOpenAttributeName) === 'true',
				this.getAttribute(
					Map.ThemeEditorModeAttributeName,
				) as EditorModes,
				this.getAttribute(Map.EnableCBRange) === 'true',
				this.getAttribute(Map.EnableCBRangeHeatmap) === 'true',
				this._showMultiThemes,
				this.getAttribute(Map.DefaultThemeIdAttributeName),
				this.getAttribute(Map.ArcGISBasemapStylesTokenAttributeName),
				this.getAttribute(
					Map.BasemapOriginAttributeName,
				) as BasemapType,
			);
			this._map?.addControl(this._panelControl);
		}

		this._addPitchControl();

		this._addGridControl();

		this._addPrintControl();

		this._addBackgroundOrigin();
	}

	private _addGeocoder(): void {
		const geocoderId = this.getAttribute(Map.GeocoderAttributeName);

		// default is to restrict search to bounds if bounds are set
		const restrictSearchToBounds =
			this.getAttribute(Map.RestrictSearchToBounds) === 'false'
				? false
				: true;

		this._searchControl = new SearchControl(
			this._map as MapboxMap,
			this._accessToken,
			this._functionalityEnabled(MapFunction.geocoderControl),
			geocoderId,
			this._dispatchEvent.bind(this),
			this._mapboxUtils as MapboxUtils,
			restrictSearchToBounds,
		);
		this._searchControl.on(
			SEARCH_SELECT,
			(searchResult: SelectedSearchResult) => {
				this._searchResult = searchResult;
			},
		);

		this._dropPin = new DropPin(
			this._map as MapboxMap,
			this._accessToken,
			this._dispatchEvent.bind(this),
		);
	}

	private _addAnimationControl(): void {
		const animtionControlId = this.getAttribute(
			Map.AnimationControlAttributeName,
		);
		if (animtionControlId) {
			const container = document.getElementById(animtionControlId);
			if (container) {
				const animationController = new AnimationControlBridge(
					container,
				);
				this._eventBridge.addService(animationController);
			}
		}
	}

	private _addLanguageControl(): void {
		if (!this._map) return;
		this._languageControl = new LanguageControl(this._map);
		this._languageControl.on(LANGUAGE_CHANGE, () => {
			this._messageControl.refresh();
		});
	}

	private _addPitchControl(): void {
		if (!this._map) return;
		const pitchControl =
			this.getAttribute(Map.PitchControlAttributeName) === 'true';
		if (pitchControl) {
			this._pitchControl = new PitchControl();
			this._map?.addControl(this._pitchControl);
			this._pitchControl.on(PITCH_CONTROL_ON, () => {
				this._configAdaptor?.updateConfigItem('pitchControl', true);
			});
			this._pitchControl.on(PITCH_CONTROL_OFF, () => {
				this._configAdaptor?.updateConfigItem('pitchControl', false);
			});
		}
	}

	private _addBackgroundOrigin(): void {
		if (!this._map) return;
		this._map.fire(PANEL_BACKGROUND_ORIGIN_CHANGED);
	}
	_restorePitchControl = () => {
		if (this._pitchControl) {
			const savedValue = this._configAdaptor?.getConfigItem(
				'pitchControl',
			);
			logger.debug('restoring pitch control', { savedValue });
			if (savedValue) {
				this._pitchControl.turnOn();
			} else {
				this._pitchControl.turnOff();
			}
		}
	};

	private _addGridControl() {
		// check if to show grid control by getting attribute of `<inno-map>` custom element
		const gridControl =
			this.getAttribute(Map.GridControlAttributeName) === 'true';
		if (gridControl) {
			// create grid control and attach to the map
			this._gridControl = new GridControl(this._dispatchEvent.bind(this));
			this._map?.addControl(this._gridControl);
			// listen to the events emitted from the grid control
			this._gridControl.on(GRID_CONTROL_ON, () => {
				// clear any currently selected assets and disable the property panel
				this.clearSelectedFeatures();
				this._propertyPanelEnabled = false;
			});
			this._gridControl.on(GRID_CONTROL_OFF, () => {
				// re-enable the property panel
				this._propertyPanelEnabled =
					this.getAttribute(Map.PanelAttributeName) === 'true';
			});
		}
	}

	private _addPrintControl() {
		if (!this._map) return;
		this._printControl = new PrintControl(this._map);
	}

	private _updatePrintPreview() {
		if (!this._printControl) return;
		this._printControl.fire(PRINT_PREVIEW);
	}

	_fitToExtents = () => {
		const tileBounds = this._serviceManager
			.getAll()
			.filter(service => !service.ignoreExtents)
			.map(service => service.bounds)
			.filter(Boolean) as LngLatBounds[];

		if (tileBounds.length) {
			const allBounds = tileBounds.reduce((previous, bounds) =>
				previous.extend(bounds),
			);
			this.fitBoundsWithOptions(allBounds);
			return;
		}

		const coordinates = this._serviceManager
			.getAll()
			.filter(service => !service.ignoreExtents)
			.map(service => service.data as GeoJsonDataType)
			.map(data =>
				data?.features?.map(({ geometry }) =>
					// GeometryCollection doesn't have coordinates - will ignore for now
					geometry && 'coordinates' in geometry
						? geometry.coordinates
						: [],
				),
			)
			.filter(Boolean);

		if (coordinates.length) {
			const normalised = normalizeCoordinates(
				coordinates,
			) as LngLatLike[];
			this.fitBoundsFromCoords(normalised);
		}
	};

	private get _canSelectAssets(): boolean {
		// disable selecting assets if grid is active
		return !this._gridControl?.active && !this._mapState.drawing;
	}

	mapClick = (e: MapMouseEvent): void => {
		const ctrlKey = e.originalEvent.ctrlKey;
		const clusterExpansionEnabled = this._functionalityEnabled(
			MapFunction.clusterExapnsion,
		);
		const selectionEnabled = this._functionalityEnabled(
			MapFunction.selection,
		);
		const markerEnabled = this._functionalityEnabled(MapFunction.marker);
		if (!clusterExpansionEnabled && !selectionEnabled && !markerEnabled)
			return;

		const bbox = this._createLocationBox(e);
		const clusters = this._mapboxUtils?.clustersInBox(bbox);
		const features = this._mapboxUtils?.featuresInBox(bbox);

		const prioritiseClusters = this._prioritiseClusters(clusters, features);

		if (clusterExpansionEnabled && prioritiseClusters && clusters?.length) {
			this.zoomToCluster(clusters);
			return;
		}

		if (!this._canSelectAssets) return;

		const clickAction = this._clickAction(features);

		if (markerEnabled && !ctrlKey) {
			this.placeMarker(
				e.lngLat,
				selectionEnabled ? features?.length ?? 0 : 0,
				this._selectionManager.assetIds.length,
			);
		}

		if (selectionEnabled) {
			// clear the externally set selection
			this._selectionManager.externalSelectedAssets = [];
			if (clickAction === 'select' || clickAction === 'deselect') {
				if (ctrlKey) {
					this.selectFeature(features, SelectionMode.invert);
				} else {
					this.selectFeature(features);
				}
			} else if (clickAction === 'zoom') {
				const zoom = Math.max(
					(this._map?.getZoom() ?? 0) + 1,
					this._selectionOptions.minZoom,
				);
				this._map?.easeTo(
					{
						around: e.lngLat,
						zoom,
					},
					{
						mousePosition: e,
					},
				);
				this._messageControl.noSelect();
			}
		}
	};

	mapMouseMove = (e: MapMouseEvent): void => {
		const clusterExpansionEnabled = this._functionalityEnabled(
			MapFunction.clusterExapnsion,
		);

		const bbox = this._createLocationBox(e);
		const clusters = this._mapboxUtils?.clustersInBox(bbox);
		const features = this._mapboxUtils?.featuresInBox(bbox);

		const clickAction = this._clickAction(features);

		if (
			(clusters?.length && clusterExpansionEnabled) ||
			clickAction === 'zoom'
		) {
			this._changeCursor('zoom-in');
			this._hoverPopup?.debouncedHover(e);
		} else if (clickAction === 'select') {
			this._changeCursor('pointer');
			this._hoverPopup?.debouncedHover(e, features);
		} else {
			this._changeCursor('');
			this._hoverPopup?.debouncedHover(e);
		}
	};

	private _clickAction(
		features?: MapboxGeoJSONFeature[],
	): 'select' | 'zoom' | 'deselect' | 'none' {
		const selectionEnabled = this._functionalityEnabled(
			MapFunction.selection,
		);
		const hasFeatures = features && features.length > 0;
		const tooManyFeatures =
			features && features.length > this._selectionOptions.maxFeatures;
		const zoomLevelLow =
			this._map && this._map.getZoom() < this._selectionOptions.minZoom;
		if (this._mapState.drawing) return 'none';
		if (selectionEnabled) {
			if (hasFeatures) {
				if (tooManyFeatures || zoomLevelLow) {
					return 'zoom';
				} else {
					return 'select';
				}
			} else {
				return 'deselect';
			}
		}
		return 'none';
	}

	private _prioritiseClusters(
		clusters: MapboxGeoJSONFeature[] | undefined,
		features: MapboxGeoJSONFeature[] | undefined,
	): boolean {
		if (!clusters?.length) return false;
		if (!features?.length) return true;
		const clustersTopZIndex = Math.max(
			...clusters.map(({ layer }) => layer.metadata.zIndex),
		);
		const featuresTopZIndex = Math.max(
			...features.map(({ layer }) => layer.metadata.zIndex),
		);
		if (clustersTopZIndex < featuresTopZIndex) return false;
		return true;
	}

	private _changeCursor(cursor: string): void {
		if (!this._map) return;
		this._map.getCanvas().style.cursor = cursor;
	}

	private _createLocationBox(e: MapMouseEvent): LocationBox {
		const {
			point: { x, y },
		} = e;
		return [
			[x - 5, y - 5],
			[x + 5, y + 5],
		];
	}

	zoomToCluster(clusters: MapboxGeoJSONFeature[]): void {
		if (clusters?.length) {
			const cluster = clusters?.[0];
			const clusterId = cluster.properties?.cluster_id;
			const source = cluster.source;
			if (clusterId && source) {
				(this._map?.getSource(
					source,
				) as GeoJSONSource).getClusterExpansionZoom(
					clusterId,
					(err, zoom) => {
						if (err) return;
						this._map?.easeTo({
							around: (cluster.geometry as GeoJSON.Point)
								.coordinates as CameraOptions['center'],
							zoom,
						});
					},
				);
			} else {
				const zoom = (this._map?.getZoom() ?? 0) + 1;
				this._map?.easeTo({
					around: (cluster.geometry as GeoJSON.Point)
						.coordinates as CameraOptions['center'],
					zoom,
				});
			}
		}
	}

	zoomToFeatures(
		features: MapboxGeoJSONFeature[] | undefined,
		options?: FitBoundsOptions,
	): void {
		if (!features?.length) return;
		const coordinates = features
			?.map(({ geometry }) =>
				// GeometryCollection doesn't have coordinates - will ignore for now
				geometry && 'coordinates' in geometry
					? geometry.coordinates
					: [],
			)
			.filter(coords => coords.length);

		if (coordinates.length) {
			const normalised = normalizeCoordinates([
				coordinates,
			]) as LngLatLike[];

			this.fitBoundsFromCoords(normalised, options);
		}
	}

	/**
	 * Select geocoder search result on the map if found
	 * @param finalTry stop trying to find the asset
	 */
	selectGeocoderSearchResult(finalTry = false): void {
		if (this._searchResult && this._canSelectAssets) {
			const features = this.findFeatureById(
				this._searchResult.id,
				this._searchResult.serviceId,
			);
			if ((features && features.length > 0) || finalTry) {
				this._searchResult = null;
			}
		}
	}

	resetMinZoomForWarning = (): void => {
		this._minZoomForWarning = -1;
	};

	updateMinZoomForWarning(zoomLevel: number): void {
		if (zoomLevel > this._minZoomForWarning) {
			this._minZoomForWarning = zoomLevel;
		}
	}

	private _setupAfterAllLoaded(): void {
		if (this._serviceLoading || !this._allLoaded || !this._configLoaded)
			return;
		this._eventBridge.fire(CONFIG_AND_SERVICES_LOADED);
		this.showWarningForHiddenAssets();
		// the hidden assets warning will clear the loading message if triggered
		// if it is not triggered then we want to clear the loading message
		// its in this order so that there is no gap between messages being changed
		this._messageControl.clear('loading');
		this._allLoaded = false;
	}

	showWarningForHiddenAssets(): void {
		if (this._serviceLoading) return;
		const zoom = this._map?.getZoom() ?? 0;
		if (this._showMinZoomWarning && zoom < this._minZoomForWarning) {
			this._messageControl.hiddenAssets();
		} else {
			this._messageControl.clear('hidden-assets');
		}
	}

	findFeatureById(
		featureId: string,
		serviceId: string,
	): GeoJSONFeature[] | undefined {
		const layers = this._layerManager?.getByServiceId(serviceId);
		const features = layers?.flatMap(layer => {
			return (
				this._map?.querySourceFeatures(layer.source || layer.id, {
					filter: ['in', 'id', featureId],
					...(layer.sourceLayer && {
						sourceLayer: layer.sourceLayer,
					}),
				}) ?? []
			).map(feature => ({
				...feature,
				layer: {
					id: layer.id,
					type: '',
					metadata: { displayName: layer.displayName },
				},
				source: layer.source ?? layer.id,
			}));
		});
		this.selectFeature(features);
		return features;
	}

	findSelectedFeatures(): void {
		if (
			!this._selectionManager.externalAssetsDiff ||
			!this._layerManager?.loadedLayerIds.length ||
			this._layerManager.loadedLayerIds.length <
				this._serviceManager.getAll().length
		)
			return;
		this._updateSourceIdsOfSelectedAssets();
		const features = this._layerManager.loadedLayers.flatMap(
			({ id, sourceLayer }) => {
				const feature = this._selectionManager.getAssetsToFindInLayer(
					id,
					sourceLayer,
				);
				return feature;
			},
		);
		this.selectFeature(features, undefined, !this._positionSetExternally);
	}

	private _updateSourceIdsOfSelectedAssets() {
		this._selectionManager.externalAssetsToFind.forEach(feature => {
			const layer = this._layerManager?.getLayerById(feature.layerId);
			if (!layer) return;
			const { source, displayName } = layer;
			feature.updateSourceId(source);
			feature.updateDisplayName(displayName);
		});
	}

	clearSelectedFeatures(): void {
		this.selectFeature([]);
	}

	selectFeature(
		features: GeoJSONFeature[] | undefined,
		mode = SelectionMode.normal,
		zoomToSelected = false,
	): void {
		const changes = this._updateSelection(features, mode);

		// if the selected assets haven't changed then there is no need to proceed
		if (!changes.deleted && !changes.added) return;

		this._updateRenderedSelection();
		//remove hoverpop when panel pops out
		if (this._propertyPanelEnabled && this._propertyPanelOpen) {
			this._hoverPopup?.debouncedHover({} as MapMouseEvent);
		}

		if (zoomToSelected) {
			this.zoomToFeatures(features);
		}
	}

	private _updateSelection = (
		features: GeoJSONFeature[] | undefined,
		mode = SelectionMode.normal,
	) => {
		switch (mode) {
			case SelectionMode.normal:
				return this._selectionManager.replaceAll(features);
			case SelectionMode.invert:
				return this._selectionManager.invertMany(features);
			case SelectionMode.add:
				return this._selectionManager.addMany(features);
			case SelectionMode.subtract:
				return this._selectionManager.deleteMany(features);
		}
	};

	private _updateRenderedSelection() {
		this._layerManager?.clearSelectedLayers();
		this._layerManager?.clearHighlightedLayers();
		this.setHighlightedAssetsFromAssetGrid(this._highlightedAssets, true);

		Object.entries(this._selectionManager.assetsPerLayer).forEach(
			([layerId, ids]) => {
				this._layerManager?.setSelectedItemsOnLayer(layerId, ids);
			},
		);

		this._propertyPanelOpen = !!this._selectionManager.assetIds.length;
		this.selectedAssetsChanged(this._selectionManager.assetProperties);

		this._serviceManager.loadMissingProperties(
			this._selectionManager.assetsPerSource,
		);
	}

	selectedAssetsChanged(features: GeoJsonProperties[] | undefined): void {
		this._dispatchEvent<GeoJsonProperties[] | undefined>(
			'selectedassets',
			features,
		);
	}

	placeMarker(
		lngLat: LngLat,
		numFeatures: number,
		prevNumFeatures: number,
	): void {
		if (numFeatures === 0 && prevNumFeatures === 0) {
			this._dropPin?.setPin(lngLat);
		} else if (numFeatures > 0) {
			this._dropPin?.clearPin();
		}
	}

	setSearch(search: string): void {
		this._searchControl?.setSearch(search);
		this._dropPin?.clearPin();
	}

	setSearchPreview(location: number[]): void {
		this._dropPin?.setPreview(location);
	}

	clearSearchPreview(): void {
		this._dropPin?.clearPreview();
	}

	removeMarker(): void {
		this._dropPin?.clearPin();
	}

	setMuiTheme(theme: DefaultTheme): void {
		this._searchControl?.setTheme(theme);
		this._panelControl?.setTheme(theme);
	}

	outsideMap(): void {
		this._hoverPopup?.clear();
	}

	cancelPopupUpdate(): void {
		this._hoverPopup?.cancelPopupUpdate();
	}

	_moveEnd = (e: MoveEndEvent): void => {
		const center = this._map?.getCenter();
		const zoom = this._map?.getZoom();
		if (!center || !zoom) return;
		this.position = { center, zoom, internal: true };
		this._checkMove(e);
		this._boundsChanged();
	};

	/**
	 * Checks that a flyTo, jumpTo or easeTo event has landed in the correct place,
	 * otherwise will retry for n times, where n is set in the initial flyTo,
	 * jumpTo or easeTo call.
	 */
	private _checkMove(e: MoveEndEvent) {
		const { trigger, action, options, retry, ...otherEventData } = e;
		if (!trigger || !action) return;
		const { center } = this.mapPosition;
		if (
			!coordinatesSame(center as LngLatLike, options.center ?? [0, 0]) &&
			retry > 0
		) {
			if (action === CameraAction.flyTo) {
				this._mapboxUtils?.flyTo(
					options,
					trigger,
					retry - 1,
					otherEventData,
				);
			} else if (action === CameraAction.jumpTo) {
				this._mapboxUtils?.jumpTo(
					options,
					trigger,
					retry - 1,
					otherEventData,
				);
			} else if (action === CameraAction.easeTo) {
				this._mapboxUtils?.easeTo(
					options,
					trigger,
					retry - 1,
					otherEventData,
				);
			}
		}
	}

	private _positionChanged({
		center,
		zoom,
	}: Omit<MapInternalPosition, 'internal'>): void {
		const detail = {
			center: center.toArray(),
			zoom,
		};
		this._dispatchEvent('position', detail);
	}

	private _boundsChanged() {
		const bounds = this._map
			?.getBounds()
			.toArray()
			.flat();
		if (
			bounds &&
			JSON.stringify(this._currentBounds) !== JSON.stringify(bounds)
		) {
			this._dispatchEvent('boundsChanged', bounds);
			this._currentBounds = bounds;
		}
	}

	private _dispatchEvent<T>(eventName: string, detail: T): void {
		dispatchTargetedEvent(this.rootElement, eventName, detail);
	}

	/**
	 * check whether the functionality/control should be enabled
	 * @param functionName enum of the name of the functionality to check
	 */
	private _functionalityEnabled(functionName: MapFunction): boolean {
		return functionalityEnabled(functionName, this._displayMode);
	}

	/**
	 * fixes the accessibility issues caused by a half
	 * implemented mapbox feature
	 * @param container container to find mapbox control
	 */
	private _fixAccessibilityIssues(container: HTMLElement): void {
		const attrib = container.getElementsByClassName(
			'mapboxgl-ctrl-attrib-inner',
		)[0];
		attrib?.removeAttribute('role');
	}

	private _getUnitSystem(): UnitSystem {
		const unitSystem = this.getAttribute(
			Map.UnitSystemAttributeName,
		) as UnitSystem;
		if (unitSystem && Object.values(UnitSystem).includes(unitSystem)) {
			return unitSystem;
		}
		return defaultUnitSystem;
	}

	set printPreview(printPreview: boolean) {
		// add class to container element to hide map controls
		if (printPreview) {
			this._container.classList.add('print-preview');
		} else {
			this._container.classList.remove('print-preview');
		}
	}
}
