import Icon, { Offset } from './Icon';
import { generateSVGGradient, svgGradients } from '@Map/layers/settings';

import MapboxLayer from '@Map/layers/mapbox/MapboxLayer';
import { SystemTypes } from '@Map/services/Info360TileTypes';
import TileConfigAdaptor from '@Map/layers/TileConfigAdaptor';
import icons from './icons';
import { santizeString } from '@Map/utils';

export interface Symbols {
	[key: string]: string;
}

export interface BufferImage {
	width: number;
	height: number;
	data: Uint8Array | Uint8ClampedArray;
}

export type SymbolCallback = (
	image: HTMLImageElement | BufferImage,
	id: string,
) => void;

interface ParsedId {
	key: string;
	color: string;
	selected: boolean;
	selectedColor?: string;
	pattern: boolean;
	namespaced: boolean;
}

export default class SymbolLoader {
	_symbols: Symbols;
	static selectedPostfix = '-selected';
	static patternPostfix = '-pattern';
	static disableBackgroundPosfix = '-nobackground';
	static namespace = 'inno:';

	constructor(additionalSymbols = {}) {
		this._symbols = this.buildSelectSet({ ...icons, ...additionalSymbols });
	}

	static createSymbolId(
		iconName?: string,
		color?: string,
		selected?: boolean,
		selectedColor?: string,
		disableBackground?: boolean,
	): string {
		return `${SymbolLoader.namespace}${iconName}${SymbolLoader.iconPostfix(
			color,
			selected,
			selectedColor,
			disableBackground,
		)}`;
	}

	static iconPostfix(
		color?: string,
		selected?: boolean,
		selectedColor?: string,
		disableBackground?: boolean,
	): string {
		let postfix = '';
		if (color) {
			postfix += `~${color}`;
		}
		if (selected) {
			postfix += SymbolLoader.selectedPostfix;
			if (selectedColor) {
				postfix += `~${selectedColor}`;
			}
		}
		if (disableBackground) {
			postfix += SymbolLoader.disableBackgroundPosfix;
		}
		return postfix;
	}

	static createPatternId(
		icon: string,
		color?: string,
		selected?: boolean,
		selectedColor?: string,
	): string {
		const postfix = selectedColor && selected ? selectedColor : color;
		return `${icon}${SymbolLoader.patternPostfix}${
			postfix ? `~${postfix}` : ''
		}`;
	}

	buildSelectSet(symbols: Symbols): Symbols {
		const symbolSet = { ...symbols };
		Object.entries(symbols).forEach(([id, svg]) => {
			const processedSvg = this._convertQuotes(svg);
			symbolSet[id] = processedSvg;
			if (id.match(/^circle/) || id.match(/^valve/)) return;
			for (const systemType in SystemTypes) {
				const systemColor = TileConfigAdaptor.getSystemColour(
					systemType as SystemTypes,
				);
				const encodedColor = this._getEncodedColor(systemColor);
				const symbolId = SymbolLoader.createSymbolId(id, systemColor);
				symbolSet[symbolId] = this._replaceColorAddStroke(
					processedSvg,
					encodedColor,
				);
			}
		});
		return symbolSet;
	}

	getImage(symbolId: string, callback: SymbolCallback): void {
		const image = document.createElement('img');
		image.onload = (): void => {
			const {
				selected,
				selectedColor,
				key,
				pattern,
			} = SymbolLoader.parseId(symbolId);

			if (!SymbolLoader.iconValid(symbolId, true)) {
				callback(image, symbolId);
			} else if (key.match(/arrow/) || pattern) {
				// use image as is for the arrows and for patterns
				callback(image, symbolId);
			} else {
				const disableIconBackground = this._shouldDisableIconBackground(
					symbolId,
				);
				const customSize = this._customCircleSize(key);
				const circleOffset = this._getCircleOffset(key);
				const icon = new Icon(
					image,
					selected,
					selectedColor,
					customSize,
					disableIconBackground,
					circleOffset,
				);
				callback(icon.asBuffer(), symbolId);
			}
		};
		image.src = this._getSrc(symbolId);
		image.crossOrigin = 'Anonymous';
	}

	getSvg(symbolId: string | undefined): string {
		return symbolId ? this._getSrc(symbolId, true) : '';
	}

	getGradientSvg(symbolId: string | undefined, gradient: string): string {
		return symbolId
			? this._getSrcGradient(this._symbols[symbolId], gradient)
			: '';
	}

	getCustomGradientSvg(
		symbolId: string | undefined,
		gradient: string[],
	): string {
		return symbolId
			? this._addCustomGradient(this._symbols[symbolId], gradient)
			: '';
	}

	loadAll(callback: SymbolCallback): void {
		Object.keys(this._symbols).forEach(id => {
			this.getImage(SymbolLoader.createSymbolId(id), callback);
		});
	}

	private _convertQuotes(svg: string): string {
		// eslint-disable-next-line quotes
		return svg.replaceAll(/"/g, "'");
	}

	private _getEncodedColor(color: string | undefined): string {
		return color?.replace('#', '%23') || 'inherit';
	}

	private _replaceColorAddStroke(svg: string, color: string): string {
		return svg
			.replace(
				/fill='(%234a6067|_COLOR_)'/gi,
				`fill='${color}' stroke='%23333' stroke-width='0.5'`,
			)
			.replace(/(%234a6067|_COLOR_)/gi, color);
	}

	private _addGradient(svg: string, gradient: string): string {
		return this._replaceColorAddStroke(
			svg,
			`url%28%23${santizeString(gradient)}%29`,
		).replace(/%3C\/svg%3E/i, `${svgGradients[gradient]}%3C/svg%3E`);
	}

	private _addCustomGradient(svg: string, gradient: string[]): string {
		const gradientName = gradient.join('-');
		const svgGradient = generateSVGGradient(gradient, gradientName);
		return this._replaceColorAddStroke(
			svg,
			`url%28%23${santizeString(gradientName)}%29`,
		).replace(/%3C\/svg%3E/i, `${svgGradient}%3C/svg%3E`);
	}

	static parseId(id: string): ParsedId {
		const selected = !!id.match(SymbolLoader.selectedPostfix);
		const pattern = !!id.match(SymbolLoader.patternPostfix);
		const namespaced = !!id.match(SymbolLoader.namespace);
		const [key, color, selectedColor] = id
			.replace(SymbolLoader.namespace, '')
			.replace(SymbolLoader.selectedPostfix, '')
			.replace(SymbolLoader.patternPostfix, '')
			.replace(SymbolLoader.disableBackgroundPosfix, '')
			.split('~');
		return { key, color, selected, selectedColor, pattern, namespaced };
	}

	private _getSrc(symbolId: string, svg?: boolean): string {
		const { key, color } = SymbolLoader.parseId(symbolId);
		const iconToFind = SymbolLoader.createSymbolId(key, color);
		if (!SymbolLoader.iconValid(symbolId, true)) {
			return svg ? '' : this._symbols.blank;
		} else if (this._symbols[iconToFind] && color) {
			return this._symbols[iconToFind];
		} else {
			return this._generateIcon(key, color);
		}
	}

	private _getSrcGradient(symboldId: string, gradient: string): string {
		const gradientSymbolKey = symboldId + '~~' + gradient;
		if (!this._symbols[gradientSymbolKey]) {
			this._symbols[gradientSymbolKey] = this._addGradient(
				symboldId,
				gradient,
			);
		}
		return this._symbols[gradientSymbolKey];
	}

	private _generateIcon(
		baseSymbolId: string,
		color = MapboxLayer.UNSELECTED_COLOR,
	): string {
		const originalIcon = this._symbols[baseSymbolId];
		if (!originalIcon) return this._symbols['unknown'];

		const newSymbolId = SymbolLoader.createSymbolId(baseSymbolId, color);
		const encodedColor = this._getEncodedColor(color);
		this._symbols[newSymbolId] = this._replaceColorAddStroke(
			originalIcon,
			encodedColor,
		);
		return this._symbols[newSymbolId];
	}

	private _shouldDisableIconBackground(key: string): boolean {
		return !!(
			key.match(/^valve-/) ||
			key.match(SymbolLoader.disableBackgroundPosfix)
		);
	}

	private _getCircleOffset(key: string): Offset | undefined {
		return !!key.match(/^valve-/) ? { x: 0, y: -3 } : undefined;
	}

	private _customCircleSize(key: string): number | undefined {
		return key.match(/defect/) ? 16 : undefined;
	}

	// fixes issue where by the pipe icon has been saved in a theme,
	// but it no longer exists and this prevents it showing the dot instead
	// also checks for namespace on icon to prevent loading missing
	// icons that are from mapbox not the app
	static iconValid(
		symbolId: string | undefined,
		checkNamespace = false,
	): boolean {
		if (!symbolId) return false;
		const { key, namespaced } = SymbolLoader.parseId(symbolId);
		return (
			(!checkNamespace || (checkNamespace && namespaced)) &&
			!key.match(/^pipe$/)
		);
	}
}
