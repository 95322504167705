import { AssetRiskAndCondition, SpatialRecord } from '@Types';

import { AxiosResponse } from 'axios';
import service from '@Apis/risk.management.api';

export interface GetSpatialRecordResponse {
	record: SpatialRecord;
}

export const getSpatialRecord = (
	recordId: string,
): Promise<AxiosResponse<GetSpatialRecordResponse>> => {
	return service.get<GetSpatialRecordResponse>(`/spatial/${recordId}`);
};

export const getRiskScore = (
	id: string,
): Promise<AxiosResponse<AssetRiskAndCondition>> => {
	return service.get(`/risk/singlescore/${encodeURIComponent(id)}`);
};
