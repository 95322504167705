import { CompositeLayerProps, MapboxLayerType } from '../types';
import { IconSet, LayerZindex } from '@Map/services/types';
import MapboxLayer, { MapboxLayerProps } from '../mapbox/MapboxLayer';

import BubbleLayer from '../mapbox/BubbleLayer';
import FillLayer from '../mapbox/FillLayer';
import HeatmapLayer from '../mapbox/HeatmapLayer';
import HighlightLayer from '../mapbox/HighlightLayer';
import LineLayer from '../mapbox/LineLayer';
import MapboxUtils from '@Map/helpers/mapbox';
import RasterLayer from '../mapbox/RasterLayer';
import SymbolLayer from '../mapbox/SymbolLayer';

export interface KVPNum {
	[key: string]: number | undefined;
}

export interface Config {
	idPostfix?: string;
	zIndexesPerType?: KVPNum;
	selected?: boolean;
	highlightBackground?: boolean;
	highlightForeground?: boolean;
}

export default abstract class LayerBase {
	private _layerInfo: CompositeLayerProps;
	protected _layer!: MapboxLayerType;
	protected _secondLayer?: MapboxLayerType;
	protected _map: mapboxgl.Map;
	protected _idPostfix = '';
	protected _zIndexesPerType: KVPNum = {};
	protected _selected = false;
	protected _highlightBackground = false;
	protected _highlightForeground = false;
	protected _filter: string[] | undefined = undefined;
	protected _mapboxUtils: MapboxUtils;

	constructor(
		layerInfo: CompositeLayerProps,
		map: mapboxgl.Map,
		config: Config = {},
	) {
		this._layerInfo = layerInfo;
		this._map = map;
		this._mapboxUtils = new MapboxUtils(this._map);
		this._idPostfix = config.idPostfix ?? this._idPostfix;
		this._zIndexesPerType = config.zIndexesPerType ?? this._zIndexesPerType;
		this._selected = config.selected ?? this._selected;
		this._highlightBackground =
			config.highlightBackground ?? this._highlightBackground;
		this._highlightForeground =
			config.highlightForeground ?? this._highlightForeground;
		this.setup();
	}

	get layerInfo(): CompositeLayerProps {
		return this._layerInfo;
	}

	get id(): CompositeLayerProps['id'] {
		return this.layerInfo.id + this._idPostfix;
	}

	get secondId(): CompositeLayerProps['id'] {
		return this.layerInfo.id + this._idPostfix + '-second';
	}

	get properties(): CompositeLayerProps['properties'] {
		return this.layerInfo.properties;
	}

	get hasClustering(): boolean {
		return this.layerInfo.cluster ?? false;
	}

	get layerType(): 'main' | 'selected' | 'highlight' {
		if (this._highlightBackground) return 'highlight';
		if (this._selected) return 'selected';
		return 'main';
	}

	set iconSet(iconSet: IconSet) {
		this._layer.iconSet = iconSet;
	}

	get serviceId(): CompositeLayerProps['serviceId'] {
		return this.layerInfo.serviceId;
	}

	get source(): string {
		if (
			this.layerInfo.source &&
			typeof this.layerInfo.source === 'string'
		) {
			return this.layerInfo.source;
		}
		return this.serviceId;
	}

	get sourceLayer(): CompositeLayerProps['sourceLayer'] {
		return this.layerInfo.sourceLayer;
	}

	set filter(filter: string[] | undefined) {
		this._filter = filter;
		this._resetFilterOnMap();
	}

	get filter(): string[] | undefined {
		return this._filter;
	}

	get onMap(): boolean {
		return this._mapboxUtils.layerExists(this._layer.id);
	}

	setup(): void {
		if (this.layerInfo.type === 'polygon') {
			this._layer = this.getLayerClass({
				...this._layerInfo,
				type: 'fill',
			});
			this._secondLayer = this.getLayerClass(
				{
					...this._layerInfo,
					color:
						this._layerInfo.outlineColor ??
						MapboxLayer.UNSELECTED_COLOR_OUTLINE,
					colorLookup: undefined,
					type: 'line',
					pattern: undefined,
				},
				true,
			);
		} else {
			this._layer = this.getLayerClass(this._layerInfo);
		}
	}

	draw(): void {
		this._mapboxUtils.addLayer(this._layer);
		this._resetFilterOnMap();
		this._resetState();
		if (this._secondLayer) {
			this._mapboxUtils.addLayer(this._secondLayer);
			this._resetFilterOnMap(true);
		}
	}

	show(): void {
		this._mapboxUtils.showLayer(this.id);
		if (this._secondLayer) {
			this._mapboxUtils.showLayer(this.secondId);
		}
	}

	hide(): void {
		this._mapboxUtils.hideLayer(this.id);
		if (this._secondLayer) {
			this._mapboxUtils.hideLayer(this.secondId);
		}
	}

	delete(): void {
		this._mapboxUtils.removeLayer(this.id);
		if (this._secondLayer) {
			this._mapboxUtils.removeLayer(this.secondId);
		}
	}

	getLayerClass(
		layerInfo: CompositeLayerProps,
		secondLayer = false,
	): MapboxLayerType {
		const zIndexOffset =
			this._zIndexesPerType[layerInfo.type] ?? this._zIndexesPerType.base;
		const layerProps: CompositeLayerProps = {
			...layerInfo,
			id: secondLayer ? this.secondId : this.id,
			filter: this._getFilter(),
			selected: this._selected,
			highlight: this._highlightBackground,
			zIndex: zIndexOffset
				? this._getZIndex(
						layerInfo.zIndex,
						zIndexOffset,
						this.layerType,
				  )
				: layerInfo.zIndex,
			minZoom: this._ignoreZoom() ? undefined : layerInfo.minZoom,
			maxZoom: this._ignoreZoom() ? undefined : layerInfo.maxZoom,
		};
		switch (layerProps.type) {
			case 'heatmap':
				return new HeatmapLayer(layerProps);
			case 'fill':
				return new FillLayer(layerProps);
			case 'line':
				return new LineLayer(layerProps);
			case 'raster':
				return new RasterLayer(layerProps);
			case 'bubble':
				return new BubbleLayer(layerProps);
			case 'symbol':
			default:
				return this._highlightBackground
					? new HighlightLayer(layerProps)
					: new SymbolLayer(layerProps);
		}
	}

	protected _ignoreZoom(): boolean {
		return this._highlightForeground || this._highlightBackground;
	}

	protected _getZIndex(
		zIndex: LayerZindex | undefined,
		offset = 0,
		layerType: 'main' | 'selected' | 'highlight' = 'main',
	): number {
		const fallback = 0 + offset;
		if (zIndex === undefined) return fallback;
		if (typeof zIndex === 'number') return zIndex + offset;
		if (layerType in zIndex && typeof zIndex[layerType] === 'number')
			return zIndex[layerType] ?? fallback;
		return fallback;
	}

	protected _getFilter = (): MapboxLayerProps['filter'] => {
		return undefined;
	};

	protected _resetFilterOnMap = (secondLayer = false): void => {
		this._mapboxUtils.setFilter(
			secondLayer ? this.secondId : this.id,
			this._getFilter(),
		);
	};

	protected _resetState = (): void => {
		return;
	};
}
