import {
	AnyLayout,
	AnyPaint,
	BackgroundLayer,
	CircleLayer,
	FillExtrusionLayer,
	FillLayer,
	HeatmapLayer,
	HillshadeLayer,
	Layer,
	LineLayer,
	RasterLayer,
	SymbolLayer,
} from 'mapbox-gl';
/// <reference types="geojson" />
import { BackgroundTypes, ConfigLayer } from '@Map/panel/types';

import { Timings } from '../timeline/types';

// mapbox has type AnyLayer but this includes CustomLayerInterface
// which we don't want as it is completely different to the other
// layer types and breaks typings through out the code.
export type AnyBuiltInLayer =
	| BackgroundLayer
	| CircleLayer
	| FillExtrusionLayer
	| FillLayer
	| HeatmapLayer
	| HillshadeLayer
	| LineLayer
	| RasterLayer
	| SymbolLayer;

export type LayerZindex =
	| number
	| { main?: number; selected?: number; highlight?: number };

// layout and paint which come from Layer have been changed for the worse
// so overriding to make work like before upgrading (version 1.10.x)
export type ServiceLayer = Layer & {
	layout?: AnyLayout;
	paint?: AnyPaint;
	displayName?: string;
	cluster?: boolean;
	serviceId?: string;
	sourceLayer?: string;
	errored?: boolean;
	loaded?: boolean;
	background?: BackgroundTypes;
	colorLookup?: unknown[];
	selectedColorLookup?: unknown[];
	sort?: unknown[];
	attributeLayerId?: string;
	systemType?: string;
	assetType?: string;
} & Omit<
		ConfigLayer,
		'layerName' | 'layers' | 'layersEndpoint' | '_adaptor' | 'type'
	>;

export type Coordinates = GeoJSON.Position;

export interface Properties {
	[key: string]: string | number | boolean | Properties[] | undefined;
}

export type GeoJson = GeoJSON.GeoJSON;

export type Geometry = GeoJSON.Geometry;
export type GeometryPoint = GeoJSON.Point;
export type LineString = GeoJSON.LineString;

export interface Features {
	geometry: Geometry;
	properties: Properties;
	type?: string;
}

export interface HarviResponseWrapper<T> {
	content: T;
	status_code: number;
	message: string;
}

export interface Sensor {
	_id: string;
	assetType: string;
	sensorId: string;
	geometry: Geometry;
}

export interface Alert {
	id: string;
	name: string;
	priority: string;
	createDate: string;
	metadata: {
		sensors: Sensor[];
	};
}

export interface AlertsResponse {
	alerts: Alert[];
}

export interface GeoJsonDataType {
	type: string;
	features: Features[];
}

export type GeoJsonInputType = GeoJSON.Feature | GeoJSON.FeatureCollection;

export type GeoJsonInputTypes =
	| GeoJsonInputType
	| GeoJsonDataType
	| GeoJsonDataType[];

export interface VectorLayer {
	id: string;
	description: string;
	maxzoom: number;
	minzoom: number;
	geometry: string;
}

export interface TileJson {
	type?: string;
	bounds: number[];
	maxzoom: number;
	minzoom: number;
	tiles: string[];
	vector_layers: VectorLayer[];
}

export enum SourceType {
	GEOJSON = 'geojson',
	HARVI = 'harvi',
	ONU = 'onu',
	INFO360_TILES = 'info360-tiles',
	INFO360_TILES_V2 = 'info360-tiles-v2',
	TILES = 'tiles',
	ALERTS = 'alerts',
	FACILITIES = 'facilities',
}
export type Service = GeoJsonInputTypes | string | undefined;

export type Either<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
	U[keyof U];

interface PropsUrlObject {
	any: string;
	[key: string]: string;
}
export type PropsUrl = string | PropsUrlObject | undefined;

export type KVP = { [key: string]: string | number | boolean };
export type PaginatedKVP = { data: KVP };

export interface PropertyLookup {
	[key: string]: string | KVP;
}

export type IconSet = { [key: string]: string };

export interface AnimationProps {
	inView?: string;
	outView?: string;
	startTime?: number;
	endTime?: number;
	stepDuration?: number;
	jumpDuration?: number;
}

export type Animated = boolean | AnimationProps;

export type DataServiceType<TDataType> = {
	type: SourceType;
	id: string;
	displayName: string;
	token?: string;
	cluster?: boolean;
	/** @deprecated */
	propsUrl?: PropsUrl;
	propertyLookup?: PropertyLookup;
	/** @deprecated - use ReactMap layerConfig instead */
	zIndex?: number;
	pollingInterval?: number;
	animate?: Animated;
	attributesUrl?: string;
	attributeForRange?: string;
	ignoreExtents?: boolean;
} & Either<{
	url: string;
	data: TDataType;
}>;

export interface ServiceError {
	error: string;
	status?: string | number;
	statusText?: string;
}

export interface ServiceLoadIcon {
	id: string;
}

export interface ServiceLoadAssetProperties {
	id: string;
	data: {
		[key: string]: unknown;
	};
}

export interface ServiceAssetNotFound {
	id: string;
}

export interface ServiceLoaded {
	serviceId: string;
	animated: Animated;
	timings?: Timings;
	iconSet?: IconSet;
}

export interface ServiceError {
	serviceId: string;
}

export interface ThemeEndpoint {
	url: string;
	token?: string;
}

export interface Link {
	_id: string;
	dsNodeId?: string;
	usNodeId?: string;
}

export interface TraceResults {
	links: Link[];
	nodes: string[];
}

export interface TracingEndpoint {
	url: string;
	token?: string;
}
