import { Background, BackgroundTypes, BasemapType } from './types';
import { ExtractionKeys, e, t } from '@Translations/extraction';
import { getLanguage } from '@Translations/utils';
type TranslateBackgroundType = ExtractionKeys<
	Background,
	'title' | 'description'
>;

class TranslateBackground implements TranslateBackgroundType {
	_background: TranslateBackgroundType;
	constructor(background: TranslateBackgroundType) {
		this._background = background;
	}

	get title() {
		return t(this._background.title);
	}

	get key() {
		return this._background.key;
	}

	get description() {
		return t(this._background.description);
	}

	get uri() {
		return this._background.uri;
	}
}

export class BackgroundRegistry {
	private static basemapOrigin: BasemapType = 'mapbox';
	private static defaultStyle = () =>
		BackgroundRegistry.basemapOrigin == 'mapbox'
			? 'streets'
			: 'osmStandard';
	private static arcGISBasemapsToken = '';
	private static defaultBackgrounds: {
		[index: string]: Background;
	} = {
		streets: new TranslateBackground({
			title: e('Streets'),
			key: BackgroundTypes.Streets,
			description: e('Best way to get started'),
			uri: 'mapbox://styles/mapbox/streets-v11',
		}),
		satellite: new TranslateBackground({
			title: e('Satellite'),
			key: BackgroundTypes.Satellite,
			description: e(
				'Global imagery enhanced with road and label hierarchy',
			),
			uri: 'mapbox://styles/mapbox/satellite-streets-v11',
		}),
		outdoors: new TranslateBackground({
			title: e('Streets with contoured terrain'),
			key: BackgroundTypes.Outdoors,
			description: e(
				'Large-scale detail and quantitative representation of relief',
			),
			uri: 'mapbox://styles/mapbox/outdoors-v11',
		}),
		dark: new TranslateBackground({
			title: e('High contrast'),
			key: BackgroundTypes.Dark,
			description: e('Dark and high contrast road display'),
			uri: 'mapbox://styles/mapbox/dark-v10',
		}),
	};
	private static extendedBackgrounds: (
		basemapToken: string,
	) => {
		[index: string]: Background;
	} = (basemapToken: string) => {
		try {
			return {
				...BackgroundRegistry.defaultBackgrounds,
				osmStandard: new TranslateBackground({
					title: e('OpenStreetMap - Standard'),
					key: BackgroundTypes.OSMStandard,
					description: e('The standard OpenStreetMap style'),
					uri: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/osm/standard?language=${getLanguage()}&token=${basemapToken}`,
				}),
				osmRelief: new TranslateBackground({
					title: e('OpenStreetMap - Std. w/ Relief'),
					key: BackgroundTypes.OSMRelief,
					description: e(
						'Std. OpenStreetMap style with topographic relief',
					),
					uri: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/osm/standard-relief?language=${getLanguage()}&token=${basemapToken}`,
				}),
				osmStreets: new TranslateBackground({
					title: e('OpenStreetMap - Streets'),
					key: BackgroundTypes.OSMStreets,
					description: e('OpenStreetMap Streets style'),
					uri: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/osm/streets?language=${getLanguage()}&token=${basemapToken}`,
				}),
				osmStreetsRelief: new TranslateBackground({
					title: e('OpenStreetMap - Streets w/ Relief'),
					key: BackgroundTypes.OSMStreetsRelief,
					description: e(
						'OpenStreetMap Streets style with topographic relief',
					),
					uri: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/osm/streets-relief?language=${getLanguage()}&token=${basemapToken}`,
				}),
				osmLightGray: new TranslateBackground({
					title: e('OpenStreetMap - Light Gray'),
					key: BackgroundTypes.OSMLightGray,
					description: e('OpenStreetMap Light Gray'),
					uri: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/osm/light-gray?language=${getLanguage()}&token=${basemapToken}`,
				}),
				osmDarkGray: new TranslateBackground({
					title: e('OpenStreetMap - Dark Gray'),
					key: BackgroundTypes.OSMDarkGray,
					description: e('OpenStreetMap Dark Gray'),
					uri: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/osm/dark-gray?language=${getLanguage()}&token=${basemapToken}`,
				}),
				arcGISImagery: new TranslateBackground({
					title: e('ArcGIS Imagery'),
					key: BackgroundTypes.ArcGISImagery,
					description: e('ArcGIS Satellite Imagery with labels'),
					uri: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/arcgis/imagery?language=${getLanguage()}&token=${basemapToken}`,
				}),
				arcGISImageryLabels: new TranslateBackground({
					title: e('ArcGIS Imagery Labels'),
					key: BackgroundTypes.ArcGISImageryLabels,
					description: e('ArcGIS labels only'),
					uri: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/arcgis/imagery/labels?language=${getLanguage()}&token=${basemapToken}`,
				}),
			};
		} catch (err) {
			console.error('Error in extendedBackgrounds', err);
			return BackgroundRegistry.defaultBackgrounds;
		}
	};

	public static get DefaultStyle(): Background {
		let defaultBackground;
		if (
			BackgroundRegistry.basemapOrigin == 'arcgis' &&
			BackgroundRegistry.arcGISBasemapsToken.length > 0
		) {
			defaultBackground = BackgroundRegistry.extendedBackgrounds(
				BackgroundRegistry.arcGISBasemapsToken,
			);
		} else {
			defaultBackground = BackgroundRegistry.defaultBackgrounds;
		}
		const result = defaultBackground[BackgroundRegistry.defaultStyle()];

		return result;
	}

	public static set ArcGISBasemapsToken(token: string) {
		BackgroundRegistry.arcGISBasemapsToken = token;
	}

	public static set BasemapOrigin(origin: BasemapType) {
		BackgroundRegistry.basemapOrigin = origin ?? 'mapbox';
	}

	public static getStyleWithFallback(
		styleKey: string,
		basemapsToken: string | null,
	): Background {
		let backgrounds;
		if (basemapsToken) {
			BackgroundRegistry.arcGISBasemapsToken = basemapsToken;
			backgrounds = BackgroundRegistry.extendedBackgrounds(
				BackgroundRegistry.arcGISBasemapsToken,
			);
		} else {
			backgrounds = BackgroundRegistry.defaultBackgrounds;
		}

		const background = backgrounds[styleKey];
		if (background) {
			return background;
		}

		console.warn(`The specified background '${styleKey}' does not exist.`);

		return BackgroundRegistry.DefaultStyle;
	}

	public static getAllStyles(basemapsToken: string | null): Background[] {
		if (basemapsToken) {
			console.debug('getAllStyles - Returning extended basemaps');
			return Object.values(
				BackgroundRegistry.extendedBackgrounds(basemapsToken),
			);
		}
		console.debug('getAllStyles - Returning default basemaps');
		return Object.values(BackgroundRegistry.defaultBackgrounds);
	}
}
