import { AnyAction, createReducer } from '@reduxjs/toolkit';
import { AssetAttrbuteField, AssetDetailStoreState } from '@Types/asset.types';
import {
	checkAssetIdsRejected,
	checkAssetIdsResolved,
	clearValidAssetIds,
	getAssetDetail,
	getAssetDetailRejected,
	getAssetDetailResolved,
	setAssetDetailIsWaiting,
	setAssetDetailNotesIsWaiting,
	setAssetLocation,
	setAssetLocationIsWaiting,
	setInspectionsValidatingAsset,
} from '@Actions/assetDetail.actions';

const initAssetDetails: AssetAttrbuteField[] = [
	{
		FieldName: '',
		FieldTitle: '',
		FieldValue: '',
	},
];

export const initState: AssetDetailStoreState = {
	assetDetails: initAssetDetails,
	notes: [],
	isReady: false,
	isWaiting: false,
	notesIsWaiting: false,
	assetLocationIsWaiting: false,
};

interface AssetDetailReducer {
	[x: string]: (
		state: AssetDetailStoreState,
		action: AnyAction,
	) => AssetDetailStoreState | undefined;
}

const reducer: AssetDetailReducer = {
	[getAssetDetail.toString()]: state => ({
		...state,
		assetDetails: [],
		notes: [],
		isReady: false,
	}),
	[getAssetDetailResolved.toString()]: (state, action) => ({
		...state,
		initialized: true,
		assetDetails: action.payload.assetDetails,
		notes: action.payload.notes,
		isReady: true,
		isWaiting: false,
		notesIsWaiting: false,
	}),
	[getAssetDetailRejected.toString()]: state => ({
		...state,
		initialized: true,
		isWaiting: false,
		notesIsWaiting: false,
	}),
	[checkAssetIdsResolved.toString()]: (state, action) => ({
		...state,
		validAssetIdsList: action.payload,
	}),
	[checkAssetIdsRejected.toString()]: state => ({
		...state,
		validAssetIdsList: [],
	}),
	[clearValidAssetIds.toString()]: state => ({
		...state,
		validAssetIdsList: [],
	}),
	[setInspectionsValidatingAsset.toString()]: (state, action) => ({
		...state,
		validatingAssets: action.payload,
	}),
	[setAssetDetailIsWaiting.toString()]: (state, action) => ({
		...state,
		isWaiting: action.payload,
	}),
	[setAssetDetailNotesIsWaiting.toString()]: (state, action) => ({
		...state,
		notesIsWaiting: action.payload,
	}),
	[setAssetLocation.toString()]: (state, action) => ({
		...state,
		assetLocation: action.payload,
	}),
	[setAssetLocationIsWaiting.toString()]: (state, action) => ({
		...state,
		assetLocationIsWaiting: action.payload,
	}),
};

export default createReducer(initState, reducer);
