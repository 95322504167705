export interface BufferImage {
	width: number;
	height: number;
	data: Uint8Array | Uint8ClampedArray;
}

export interface Offset {
	x: number;
	y: number;
}

export default class Icon {
	private _image: HTMLImageElement;
	private _selected: boolean;
	private _selectedColor: string;
	private _canvas: HTMLCanvasElement;
	private _canvasSize: number;
	private _radius: number;
	private _ctx: CanvasRenderingContext2D | null;
	private _circleOffset: Offset;

	constructor(
		image: HTMLImageElement,
		selected = false,
		selectedColor = '#60ddff',
		circleSize = 32,
		disableBackground = false,
		circleOffset = { x: 0, y: 0 },
	) {
		this._image = image;
		this._selected = selected;
		this._selectedColor = selectedColor;
		this._canvasSize = circleSize + 10;
		this._radius = circleSize / 2;
		this._canvas = document.createElement('canvas');
		this._canvas.width = this._canvasSize;
		this._canvas.height = this._canvasSize;
		this._ctx = this._canvas.getContext('2d');
		this._circleOffset = circleOffset;
		if (!disableBackground) {
			this._addBackground();
		}
		this._addImage();
		this._addStroke();
	}

	private _drawCircle() {
		if (!this._ctx) return;
		this._ctx.arc(
			this._canvasSize / 2 + this._circleOffset.x,
			this._canvasSize / 2 + this._circleOffset.y,
			this._radius,
			0,
			2 * Math.PI,
		);
	}

	private _addBackground() {
		if (!this._ctx) return;
		this._ctx.beginPath();
		this._ctx.shadowOffsetX = 0;
		this._ctx.shadowOffsetY = 2;
		this._ctx.shadowBlur = 3;
		this._ctx.shadowColor = 'rgba(0, 0, 0, 0.4)';
		this._drawCircle();
		this._ctx.fillStyle = '#F1F4F4';
		this._ctx.fill();
		this._ctx.shadowColor = 'rgba(0, 0, 0, 0)';
	}

	private _addStroke() {
		if (!this._ctx) return;
		if (this._selected) {
			this._ctx.beginPath();
			this._drawCircle();
			this._ctx.lineWidth = 3;
			this._ctx.strokeStyle = this._selectedColor;
			this._ctx.stroke();
		}
	}

	private _addImage() {
		if (!this._ctx) return;
		const x = (this._canvasSize - (this._image.width || 16)) / 2;
		const y = (this._canvasSize - (this._image.height || 16)) / 2;
		this._ctx.drawImage(this._image, x, y);
	}

	asImage(): string {
		return this._canvas.toDataURL();
	}

	asBuffer(): BufferImage {
		const { width, height } = this._canvas;
		const img = this._ctx?.getImageData(0, 0, width, height);
		const data = img?.data as Uint8ClampedArray;
		return { width, height, data };
	}
}
