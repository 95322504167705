import { Asset, PaginatedAssetsAction } from '@Utils/types';

import { addNamespace } from '@Utils/actions';
import { createAction } from 'redux-actions';

export const getAsset = createAction<string>(addNamespace('asset/getAsset'));
export const getAssetResolved = createAction<Asset>(
	addNamespace('asset/getAsset/resolved'),
);
export const getAssetRejected = createAction(
	addNamespace('asset/getAsset/rejected'),
);

export const getAssetWithExtendedData = createAction<string>(
	addNamespace('asset/getAssetWithExtendedData'),
);
export const getAssetWithExtendedDataResolved = createAction(
	addNamespace('asset/getAssetWithExtendedData/resolved'),
);
export const getAssetWithExtendedDataRejected = createAction(
	addNamespace('asset/getAssetWithExtendedData/rejected'),
);

export const getSensor = createAction<string>(addNamespace('asset/getSensor'));
export const getSensorResolved = createAction<Asset>(
	addNamespace('asset/getSensor/resolved'),
);
export const getSensorRejected = createAction(
	addNamespace('asset/getSensor/rejected'),
);

export const clearAssets = createAction(addNamespace('asset/clearAssets'));

export const getAssetsPaginated = createAction<PaginatedAssetsAction>(
	addNamespace('asset/getAssetsPaginated'),
);
export const getAssetsPaginatedResolved = createAction(
	addNamespace('asset/getAssetsPaginated/resolved'),
);
export const getAssetsPaginatedRejected = createAction(
	addNamespace('asset/getAssetsPaginated/rejected'),
);
export const getAssetsPaginatedReset = createAction(
	addNamespace('asset/getAssetsPaginated/reset'),
);

export const getSystemAssetTypes = createAction(
	addNamespace('asset/getSystemAssetTypes'),
);
export const getSystemAssetTypesResolved = createAction<unknown>(
	addNamespace('asset/getSystemAssetResolved'),
);
export const getSystemAssetTypesRejected = createAction(
	addNamespace('asset/getSystemAssetRejected'),
);

export const assetGridExcludeItem = createAction<string>(
	addNamespace('asset/assetGridExcludeItem'),
);
export const assetGridIncludeItem = createAction<string>(
	addNamespace('asset/assetGridIncludeItem'),
);
