import { AnySourceImpl, GeoJSONSourceOptions } from 'mapbox-gl';

import MapboxUtilsBase from './base';
import logger from '@Map/logger/Logger';
import { shallowEqual } from '@Map/utils';

type AnySourceImplExtended = AnySourceImpl & {
	_options: unknown;
	load: () => void;
};

export default class MapboxUtilsSources extends MapboxUtilsBase {
	hasSource(id: string): boolean {
		return !!this._map?.getSource(id) ?? false;
	}

	addSource(id: string, source: mapboxgl.AnySourceData): void {
		if (!this.hasSource(id)) {
			this._map?.addSource(id, source);
		}
	}

	updateSource(id: string, source: mapboxgl.AnySourceData): void {
		const mapSource = this._map?.getSource(id) as AnySourceImplExtended;
		if (mapSource?.type === 'geojson' && 'data' in source && source.data) {
			mapSource.setData(
				// even though checked above, the data property is
				// optional in the GeoJSONSourceOptions so would
				// otherwise moan about it not existing
				(source as Required<GeoJSONSourceOptions>).data,
			);
		} else {
			if (mapSource) {
				try {
					const options = mapSource._options;
					if (!shallowEqual(options, source)) {
						mapSource._options = source;
						mapSource.load();
					}
				} catch {
					logger.error(`Could not update source ${id}`);
				}
			}
			this.addSource(id, source);
		}
	}

	removeSource(id: string): void {
		if (this.hasSource(id)) {
			this._map?.removeSource(id);
		}
	}
}
