import AbstractFeature from './AbstractFeature';
import AssetRegistry from '@Map/services/AssetRegistry';
import { GeoJsonProperties } from './types';
import { Geometry } from '@Map/services/types';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { deserialiseObjectValues } from '../utils';
import { t } from '@Translations/extraction';

export default class Feature implements AbstractFeature {
	private _featureData: MapboxGeoJSONFeature;
	private _id: string;

	constructor(featureData: MapboxGeoJSONFeature) {
		this._featureData = this._deserialiseProperties(featureData);
		this._id = featureData.properties?.id;
	}

	get id(): string {
		return this._id;
	}

	get properties(): GeoJsonProperties {
		return {
			...this._featureData.properties,
			sourceId: this.sourceId,
			layerId: this.layerId,
			layerName: this.layerName,
			assetType: this.assetType,
			assetId: this.assetId,
			sensorId: this.assetId,
			type: this.assetCategory,
			displayType: this.displayType,
			geometry: this.geometry,
		} as GeoJsonProperties;
	}

	get layerId(): string {
		return this._featureData.layer.id;
	}

	get sourceId(): string {
		return this._featureData.source;
	}

	get featureData(): MapboxGeoJSONFeature {
		return this._featureData;
	}

	get geometry(): Geometry {
		return this._featureData.geometry;
	}

	get layerZIndex(): number {
		return this._featureData.layer.metadata.zIndex;
	}

	get type(): Geometry['type'] {
		return this.geometry.type;
	}

	get layerName(): string {
		return t(this._featureData.layer.metadata.displayName, {
			context: 'Asset registry - plural item',
		});
	}

	get assetId(): string {
		if (this.id.match('#')) return this.id.split('#')[1];
		return this.id;
	}

	get assetType(): string {
		if (this._featureData.properties?.srcType)
			return this._featureData.properties.srcType;
		if (this.id.match('#')) return this.id.split('#')[0];
		if (this._featureData.properties?.sequence) return 'incident';
		return 'unknown';
	}

	get assetCategory(): string {
		if (
			this.assetType === 'unknown' ||
			this.assetType === 'sensor' ||
			this.assetType === 'alert' ||
			this.assetType === 'incident' ||
			this.assetType === 'facility'
		)
			return this.assetType;
		if (this.assetType === 'amSpatial') return 'spatial';
		return 'asset';
	}

	get displayType(): string {
		if (this.assetType === 'sensor') {
			// get sensor type from id when in the format: sensor#34739.Status
			const sensorType = this.id.split(/\./).pop();
			if (sensorType) return sensorType;
		}
		return AssetRegistry.getDisplayName(this.assetType);
	}

	updateProperties(properties: GeoJsonProperties): void {
		this._featureData.properties = {
			...this._featureData.properties,
			...properties,
		};
	}

	asGeojsonFeature(sourceLayer?: string): MapboxGeoJSONFeature {
		return sourceLayer
			? { ...this.featureData, sourceLayer }
			: this.featureData;
	}

	private _deserialiseProperties(
		featureData: MapboxGeoJSONFeature,
	): MapboxGeoJSONFeature {
		return {
			...featureData,
			// geometry is a getter, cannot spread
			geometry: featureData.geometry,
			properties: deserialiseObjectValues(featureData.properties || {}),
		};
	}
}
