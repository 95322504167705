import { RiskScoreResolved, SpatialRecord } from '@Types';

import { addNamespace } from '@Utils/actions';
import { createAction } from 'redux-actions';

export const getSpatialRecord = createAction<string>(
	addNamespace('risk/getSpatialRecord'),
);
export const getSpatialRecordResolved = createAction<SpatialRecord>(
	addNamespace('risk/getSpatialRecord/resolved'),
);
export const getSpatialRecordRejected = createAction(
	addNamespace('risk/getSpatialRecord/rejected'),
);

export const getRiskScore = createAction(addNamespace('risk/getRiskScore'));
export const getRiskScoreResolved = createAction<RiskScoreResolved>(
	addNamespace('risk/getRiskScore/resolved'),
);
export const getRiskScoreRejected = createAction(
	addNamespace('risk/getRiskScore/rejected'),
);

export const clearRisk = createAction(addNamespace('risk/clearRisk'));
