import { ShowMoreOverlay } from '@Components';
import {
	InfoCard,
	InputText,
	StylovyzeForm,
	useSettings,
	useCompanyDateTime,
	useTzDateTime,
} from '@innovyze/stylovyze';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, {
	timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { CircularProgress, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { selectInspections, selectInspectionsIsWaiting } from '@Selectors';
import { useGlobalization } from '@Translations';
import { InspectionStandard } from '@Types/inspection.types';
import { InspectionScoredAttributes } from '@Types/inspectionAttributes.types';
import { InspDetailActions } from '@Types/InspectionDetail.types';
import { formatDateTimeWithFormatDate } from '@Utils';
import { getTimeDifferenceText } from '@Utilsluxon';
import React from 'react';
import { getStandardFromVersion } from '../AssetDetails.component';
import { extractValue } from '../DetailsOverview/ValueMapping';
import { StyledButton } from './DetailsWorkHistory.styles';
import { NoDetailsWorkHistory } from './NoDetailsWorkHistory.component';
import { WorkOrderIcon } from './WorkOrderIcon.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DetailsWorkHistory = ({
	actions,
}: {
	actions?: InspDetailActions;
}): JSX.Element => {
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;

	const { companySettings } = useSettings();
	const { t } = useGlobalization();

	const isWaiting = selectInspectionsIsWaiting();
	const inspAttributeList = selectInspections()?.inspectionAttributes;
	let hasWorkHistory = false;
	if (inspAttributeList && inspAttributeList.length > 0) {
		hasWorkHistory = true;
	}

	const handleOpen = (
		videoURL: string,
		inspID: string,
		inspVersion: string,
	) => {
		if (actions && actions.onInspectionDetailClicked) {
			// Translate the version to the standard
			const standard = getStandardFromVersion(inspVersion, false);
			actions.onInspectionDetailClicked(videoURL, inspID, standard);
		}
	};

	const handleOpenTask = (taskID: string, inspVersion: string) => {
		if (actions && actions.onTaskDetailClicked) {
			// Translate the version to the standard
			actions.onTaskDetailClicked(
				taskID,
				inspVersion as InspectionStandard,
			);
		}
	};

	const getRow = (inspAttributes: InspectionScoredAttributes) => {
		if (inspAttributes.task) {
			return (
				<Grid
					container
					style={{
						height: '100%',
					}}>
					<Grid
						item
						style={{
							margin: '0 12px 0 2px',
						}}>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							style={{
								height: '100%',
							}}>
							<WorkOrderIcon />
						</Grid>
					</Grid>
					<Grid xs={3} item container alignItems="center">
						<InputText
							fieldAttrs={{
								name: 'taskId',
							}}
							textField={{
								label: t('Task ID'),
							}}
						/>
					</Grid>
					<Grid xs={2} item container alignItems="center">
						<InputText
							fieldAttrs={{
								name: 'taskTypeName',
							}}
							textField={{
								label: t('Task Type'),
							}}
						/>
					</Grid>
					<Grid xs={2} item container alignItems="center">
						<InputText
							fieldAttrs={{
								name: 'scheduled',
							}}
							textField={{
								label: t('Scheduled Date'),
							}}
						/>
					</Grid>
					<Grid xs={2} item container alignItems="center">
						<InputText
							fieldAttrs={{
								name: 'completed',
							}}
							textField={{
								label: t('Completed Date'),
							}}
						/>
					</Grid>
					<Grid item xs>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="flex-end"
							style={{
								height: '100%',
							}}>
							<StyledButton
								size="small"
								onClick={() => {
									handleOpenTask(
										inspAttributes._id,
										inspAttributes.inspectionVersion,
									);
								}}>
								{t('View Details >')}
							</StyledButton>
						</Grid>
					</Grid>
				</Grid>
			);
		} else {
			const standard = getStandardFromVersion(
				inspAttributes.inspectionVersion,
				false,
			);

			switch (standard) {
				case InspectionStandard.WSA05:
				case InspectionStandard.MSCC:
					{
						return (
							<Grid
								container
								style={{
									height: '100%',
								}}>
								<Grid
									item
									style={{
										margin: '0 12px 0 2px',
									}}>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
										style={{
											height: '100%',
										}}>
										<WorkOrderIcon />
									</Grid>
								</Grid>
								<Grid xs={3} item container alignItems="center">
									<InputText
										fieldAttrs={{
											name: 'inspectionId',
										}}
										textField={{
											label: t('Inspection ID'),
										}}
									/>
								</Grid>
								<Grid xs={2} item container alignItems="center">
									<InputText
										fieldAttrs={{
											name: 'inspectionVersion',
										}}
										textField={{
											label: t('Inspection Type'),
										}}
									/>
								</Grid>
								<Grid xs={3} item container alignItems="center">
									<InputText
										fieldAttrs={{
											name: 'structuralGradeRating',
										}}
										textField={{
											label: t('Structural Grade'),
										}}
									/>
								</Grid>
								<Grid xs={2} item container alignItems="center">
									<InputText
										fieldAttrs={{
											name: 'serviceGradeRating',
										}}
										textField={{
											label: t('Service Grade'),
										}}
									/>
								</Grid>
								<Grid item xs>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="flex-end"
										style={{
											height: '100%',
										}}>
										<StyledButton
											size="small"
											onClick={() => {
												handleOpen(
													inspAttributes.videoUrl
														? inspAttributes.videoUrl
														: '',
													inspAttributes._id,
													inspAttributes.inspectionVersion,
												);
											}}>
											{t('View Details >')}
										</StyledButton>
									</Grid>
								</Grid>
							</Grid>
						);
					}
					break;
				default:
					return (
						<Grid
							container
							style={{
								height: '100%',
							}}>
							<Grid
								item
								style={{
									margin: '0 12px 0 2px',
								}}>
								<Grid
									container
									direction="column"
									justifyContent="center"
									alignItems="center"
									style={{
										height: '100%',
									}}>
									<WorkOrderIcon />
								</Grid>
							</Grid>
							<Grid xs={3} item container alignItems="center">
								<InputText
									fieldAttrs={{
										name: 'inspectionId',
									}}
									textField={{
										label: t('Inspection ID'),
									}}
								/>
							</Grid>
							<Grid xs={2} item container alignItems="center">
								<InputText
									fieldAttrs={{
										name: 'inspectionVersion',
									}}
									textField={{
										label: t('Inspection Type'),
									}}
								/>
							</Grid>
							<Grid xs={2} item container alignItems="center">
								<InputText
									fieldAttrs={{
										name: 'overallRating',
									}}
									textField={{
										label: t('Overall Rating'),
									}}
								/>
							</Grid>
							<Grid xs={2} item container alignItems="center">
								<InputText
									fieldAttrs={{
										name: 'structuralOverallRating',
									}}
									textField={{
										label: t('Structural Rating'),
									}}
								/>
							</Grid>
							<Grid xs={1} item container alignItems="center">
								<InputText
									fieldAttrs={{
										name: 'serviceOverallRating',
									}}
									textField={{
										label: t('O&M'),
									}}
								/>
							</Grid>
							<Grid item xs>
								<Grid
									container
									direction="column"
									justifyContent="center"
									alignItems="flex-end"
									style={{
										height: '100%',
									}}>
									<StyledButton
										size="small"
										onClick={() => {
											handleOpen(
												inspAttributes.videoUrl
													? inspAttributes.videoUrl
													: '',
												inspAttributes._id,
												inspAttributes.inspectionVersion,
											);
										}}>
										{t('View Details >')}
									</StyledButton>
								</Grid>
							</Grid>
						</Grid>
					);
					break;
			}
		}
	};

	return (
		<InfoCard title={t('Work History')} applyBodyPadding={isWaiting}>
			{!isWaiting ? (
				hasWorkHistory ? (
					<ShowMoreOverlay height={220}>
						<Timeline
							sx={{
								[`& .${timelineOppositeContentClasses.root}`]: {
									flex: 0.2,
								},
							}}
							style={{
								paddingLeft: '11px',
								paddingBottom: '25px',
							}}>
							{inspAttributeList?.map(
								(
									inspAttributes: InspectionScoredAttributes,
									index,
								) => (
									<TimelineItem
										key={inspAttributes._id}
										style={{ paddingLeft: 0 }}>
										<TimelineOppositeContent
											style={{
												flex: 0.2,
												maxWidth: '200px',
												paddingLeft: 0,
												paddingTop: '28px',
											}}>
											<Typography
												variant="body1"
												color="textPrimary">
												{formatDateTimeWithFormatDate(
													inspAttributes.surveyDateTime,
													formatDateUTC,
												)}
											</Typography>
											<Typography
												style={{
													fontSize: '12px',
													lineHeight: '16px',
												}}
												color="textSecondary">
												{getTimeDifferenceText(
													new Date(
														inspAttributes.surveyDateTime,
													),
													companySettings,
												)}
											</Typography>
										</TimelineOppositeContent>
										<TimelineSeparator
											style={
												index === 0
													? {
															paddingTop: '27px',
													  }
													: index ===
													  inspAttributeList.length -
															1
													? {
															paddingBottom:
																'27px',
													  }
													: {}
											}>
											{index === 0 ? null : (
												<TimelineConnector />
											)}
											<TimelineDot variant="outlined" />

											{index ===
											inspAttributeList.length -
												1 ? null : (
												<TimelineConnector />
											)}
										</TimelineSeparator>
										<TimelineContent>
											<StylovyzeForm
												mode="view"
												initialValues={{
													...inspAttributes.ratings,
													inspectionVersion:
														inspAttributes.inspectionVersion,
													inspectionId:
														inspAttributes.inspectionId,
													taskId: inspAttributes.taskId,
													taskTypeName:
														inspAttributes.taskTypeName,
													completed:
														formatDateTimeWithFormatDate(
															extractValue(
																inspAttributes.completedDate,
															) ?? '',
															formatDateUTC,
														),
													scheduled:
														formatDateTimeWithFormatDate(
															extractValue(
																inspAttributes.scheduledDate,
															) ?? '',
															formatDateUTC,
														),
												}}
												onSubmit={data => {
													console.log(
														'should update the data',
														data,
													);
												}}>
												{() => {
													return (
														<>
															<Paper
																square
																style={{
																	padding:
																		'0px 10px',
																	height: '70px',
																}}>
																{getRow(
																	inspAttributes,
																)}
															</Paper>
														</>
													);
												}}
											</StylovyzeForm>
										</TimelineContent>
									</TimelineItem>
								),
							)}
						</Timeline>
					</ShowMoreOverlay>
				) : (
					<NoDetailsWorkHistory />
				)
			) : (
				<CircularProgress />
			)}
		</InfoCard>
	);
};
