import { Anchor, Popup } from '@Components';
import {
	useDebounce,
	useEventListener,
	usePrevious,
	useShallowEqlEffect,
} from '@Hooks';
import { GRID_TOGGLED, GRID_TOGGLE_STATE } from '@Map/GridControl';
import { GEOCODE_POPUP, SEARCH_RESULT } from '@Map/geocoder/constants';
import {
	PANEL_BACKGROUND_CHANGED,
	PANEL_LAYER_TOGGLE,
	PANEL_VISIBILITY_CHANGED,
} from '@Map/panel/constants';
import {
	BackgroundTypes,
	GeocoderResult,
	LayerToggle,
	MapFunction,
	MapSelectedAssets,
} from '@Map/types';
import {
	cx,
	functionalityEnabled,
	getDisplayMode,
	getEditMode,
	lngLatToFixed,
} from '@Map/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMapGlobals, useMapGlobalsWithLocalOverrides } from './MapGlobals';
import {
	AddressInfo,
	AssetGridWrapper,
	CloseButton,
	CloseIcon,
	LongLatLocation,
	PlaceName,
	PreviewContainer,
	SearchContainer,
	StyledMaterialDesignContent,
	Wrapper,
} from './ReactMap.styles';

import ControlsContainer from '@Components/ControlsContainer/ControlsContainer';
import { Messages } from '@Components/Messages/Messages';
import PolygonControl from '@Components/PolygonControl/PolygonControl';
import { BackgroundRegistry } from '@Map/panel/BackgroundRegistry';
import { SnackbarProvider } from 'notistack';
import ReactResizeDetector from 'react-resize-detector';
import { useTheme } from 'styled-components';
import { MapboxProvider } from '../context/MapboxProvider';
import { MapElement } from '../mapElement';
import { ReactMapProps } from './types';

interface Size {
	width: number;
	height: number;
}

const ReactMap = ({
	dataServices,
	layerConfig,
	summaryPanel,
	onSelectedAssetsChange,
	onBackgroundChanged,
	background,
	mode,
	selectedAssets,
	hiddenLayers,
	onHiddenLayersChange,
	position,
	onPositionChange,
	geocoderId,
	animationControlId,
	searchEndpoints,
	selectionOptions,
	onBoundsChange,
	layerPanelOpen: layerPanelInitiallyOpen,
	hoverPopup,
	hoverPopupDebounce,
	showMinZoomWarning,
	onSearch,
	defaultSearch,
	restrictSearchToBounds,
	pitchControl,
	enableColorByAttributeRange,
	assetGrid,
	printPreview,
	disableFitToExtents,
	enableColorByRangeHeatmap,
	highlightedAssets,
	zoomToHighlightedAssets,
	polygonControl,
	onPolygonComplete,
	onBasemapOriginChanged,
	...localPropOverrides
}: ReactMapProps): JSX.Element => {
	const map = useRef<MapElement | null>(null);
	const [items, setItems] = useState<MapSelectedAssets | null>(null);
	const [popupProps, setPopupProps] = useState({});
	const [geocoderPopupProps, setGeocoderPopupProps] = useState({});
	const searchRef = useRef<HTMLDivElement | null>(null);
	const panelRef = useRef<HTMLDivElement | null>(null);
	const previewRef = useRef<HTMLDivElement | null>(null);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const messagesRef = useRef<HTMLDivElement | null>(null);
	const [size, setSize] = useState<Size>({
		width: 0,
		height: 0,
	});
	const debouncedSize = useDebounce(size, 200);
	const previousSize = usePrevious(debouncedSize);
	const displayMode = getDisplayMode(mode);
	const [showGridPanel, setShowGridPanel] = useState(false);
	const {
		mapKey,
		logger,
		logLevel,
		distanceUnit,
		defaultBounds,
		fitBoundsOptions,
		iconSet,
		enableStyleEditing,
		themeEditorMode,
		themeEndpoint,
		tracingEndpoint,
		colorByMaxValues,
		showMultiThemes,
		localStoragePrefix,
		unitSystem,
		defaultThemeId,
		searchRadius,
		datagridFilter,
		datagridFilterEndpoint,
		bearerToken,
		arcGISBasemapStylesToken,
		basemapOrigin,
	} = useMapGlobalsWithLocalOverrides(localPropOverrides);
	const editorMode = getEditMode(themeEditorMode, enableStyleEditing);
	const {
		onBoundsChange: globalOnBoundsChange,
		onBackgroundChanged: globalOnBackgroundChanged,
		onHiddenLayersChange: globalOnHiddenLayersChange,
		onPositionChange: globalOnPositionChange,
		onSelectedAssetsChange: globalOnSelectedAssetsChange,
		onLayersDebug: globalOnLayersDebug,
	} = useMapGlobals();
	const theme = useTheme();

	useEffect(() => {
		map?.current?.setMessageElement?.(messagesRef.current);
	}, [messagesRef]);

	useEffect(() => {
		if (disableFitToExtents != undefined) {
			map.current?.setDisableFitToExtents?.(disableFitToExtents);
		}
	}, [disableFitToExtents]);

	useEffect(() => {
		if (fitBoundsOptions) {
			map.current?.setFitBoundsOptions?.(fitBoundsOptions);
		}
	}, [fitBoundsOptions]);

	useEffect(() => {
		if (iconSet) {
			map?.current?.setIconSet?.(iconSet);
		}
	}, [iconSet]);

	// only runs when the dataSources change
	useShallowEqlEffect(() => {
		if (dataServices) {
			map?.current?.setDataServices?.(dataServices);
		} else {
			map?.current?.clearDataServices?.();
		}
	}, [dataServices]);

	useEffect(() => {
		if (themeEndpoint) {
			map?.current?.setThemeEndpoint?.(themeEndpoint);
		}
	}, [themeEndpoint]);

	useEffect(() => {
		if (tracingEndpoint) {
			map?.current?.setTracingEndpoint?.(tracingEndpoint);
		}
	}, [tracingEndpoint]);

	useShallowEqlEffect(() => {
		if (layerConfig) {
			map?.current?.setLayerConfig?.(layerConfig);
		}
	}, [layerConfig]);

	const selectedAssetsChanged = useCallback(
		e => {
			const selectedItems = e.detail.length ? e.detail : null;
			onSelectedAssetsChange?.(selectedItems);
			globalOnSelectedAssetsChange?.(selectedItems);
			if (summaryPanel) setItems(selectedItems);
		},
		[summaryPanel, onSelectedAssetsChange, globalOnSelectedAssetsChange],
	);

	useEventListener('selectedassets', selectedAssetsChanged, map);

	useEffect(() => {
		if (selectedAssets) {
			map?.current?.setSelectedAssets?.(selectedAssets);
		}
	}, [selectedAssets]);

	useEffect(() => {
		if (hiddenLayers) {
			map.current?.setHiddenLayers?.(hiddenLayers);
		}
	}, [hiddenLayers]);

	useEffect(() => {
		if (position) {
			map.current?.setPosition?.(position);
		} else {
			map.current?.resetPosition?.();
		}
	}, [position]);

	const positionChange = useCallback(
		e => {
			onPositionChange?.(e.detail);
			globalOnPositionChange?.(e.detail);
		},
		[onPositionChange, globalOnPositionChange],
	);

	useEventListener('position', positionChange, map);

	useEffect(() => {
		const initialised = !previousSize?.height && !previousSize?.width;
		if (initialised) return;
		if (
			debouncedSize.height !== previousSize?.height ||
			debouncedSize.width != previousSize?.width
		) {
			map.current?.resize?.();
		}
	}, [debouncedSize]);

	useEffect(() => {
		if (logger) {
			map.current?.setLogger?.(logger);
		}
	}, [logger]);

	useEffect(() => {
		if (defaultBounds) {
			map.current?.setDefaultBounds?.(
				defaultBounds,
				unitSystem,
				searchRadius,
			);
		}
	}, [defaultBounds, unitSystem, searchRadius]);

	useEffect(() => {
		if (datagridFilter) {
			map.current?.setDataGridFilter?.(
				datagridFilter,
				datagridFilterEndpoint,
			);
		}
	}, [datagridFilter, datagridFilterEndpoint]);

	const deselectAssets = useCallback(
		(assets: string[]) => {
			/** istanbul ignore next */
			if (!items) return;
			const newSelectedItems = items.filter(
				item => !assets.includes(item.id),
			);
			map?.current?.setSelectedAssets?.(newSelectedItems);
		},
		[items],
	);

	useEffect(() => {
		if (searchEndpoints) {
			map.current?.setSearchEndpoints?.(searchEndpoints);
		}
	}, [searchEndpoints]);

	useEffect(() => {
		if (selectionOptions) {
			map?.current?.setSelectionOptions?.(selectionOptions);
		}
	}, [selectionOptions]);

	useShallowEqlEffect(() => {
		if (highlightedAssets) {
			map?.current?.setHighlightedAssetsFromAssetGrid?.(
				highlightedAssets,
			);
		}
	}, [highlightedAssets]);

	const highlightAssets = useCallback(
		(assets: string[]) => {
			/** istanbul ignore next */
			if (!items) return;
			const highlightItems = items.filter(item =>
				assets.includes(item.id),
			);
			map?.current?.setHighlightedAssets?.(highlightItems);
		},
		[items],
	);

	const zoomToAssets = useCallback(
		(assets: string[]) => {
			/** istanbul ignore next */
			if (!items) return;
			const zoomToItems = items.filter(item => assets.includes(item.id));
			map?.current?.zoomToAssets?.(zoomToItems);
		},
		[items],
	);

	const traceFromAsset = useCallback((assetId: string, dsTrace?: boolean) => {
		if (!assetId) return;
		map?.current?.traceFromAsset?.(assetId, dsTrace);
	}, []);

	const boundsChange = useCallback(
		e => {
			onBoundsChange?.(e.detail);
			globalOnBoundsChange?.(e.detail);
		},
		[onBoundsChange, globalOnBoundsChange],
	);

	useEventListener('boundsChanged', boundsChange, map);

	const hoverPopupChange = useCallback(e => {
		setPopupProps(e.detail);
	}, []);

	const cancelPopupUpdate = useCallback(() => {
		map?.current?.cancelPopupUpdate?.();
	}, []);

	useEventListener('hoverPopup', hoverPopupChange, map);

	const geocoderPopupChange = useCallback(e => {
		const detail = e.detail;
		setGeocoderPopupProps(detail);
	}, []);

	useEventListener(GEOCODE_POPUP, geocoderPopupChange, map);

	const searchResult = useCallback(e => {
		const detail = e.detail;
		onSearch?.(detail);
	}, []);

	useEventListener(SEARCH_RESULT, searchResult, map);

	const layersDebug = useCallback(
		e => {
			globalOnLayersDebug?.(e.detail);
		},
		[globalOnLayersDebug],
	);

	useEventListener('layersDebug', layersDebug, map);

	const backgroundChanged = useCallback(
		e => {
			const newBackground = e.detail.backgroundInfo;
			onBackgroundChanged?.(newBackground);
			globalOnBackgroundChanged?.(newBackground);
		},
		[onBackgroundChanged, globalOnBackgroundChanged],
	);

	useEventListener(PANEL_BACKGROUND_CHANGED, backgroundChanged, map);

	const hiddenLayersChange = useCallback(
		e => {
			const { hiddenLayers } = e.detail as LayerToggle;
			onHiddenLayersChange?.(hiddenLayers);
			globalOnHiddenLayersChange?.(hiddenLayers);
		},
		[onHiddenLayersChange, globalOnHiddenLayersChange],
	);

	useEventListener(PANEL_LAYER_TOGGLE, hiddenLayersChange, map);

	const gridToggled = useCallback(e => {
		const toggleState = e.detail;
		if (toggleState === GRID_TOGGLE_STATE.on) {
			setShowGridPanel(true);
		} else {
			setShowGridPanel(false);
		}
	}, []);

	useEventListener(GRID_TOGGLED, gridToggled, map);

	// ------ Layer Panel visibility
	// This reflects the visibility of the layer panel and the theme editor
	// It uses a custom event to communicate between the map and the react component
	const [layerPanelOpen, setLayerPanelOpen] = React.useState(layerPanelInitiallyOpen ?? false);
	const [layerPanelEditorOpen, setLayerPanelEditorOpen] = React.useState(false);

	const mapPanelVisibilityChanged = useCallback(
		(event: Event) => {
			const { open, editorOpen } = (event as CustomEvent<{
				open: boolean;
				editorOpen: boolean;
			}>).detail;
			setLayerPanelOpen(open);
			setLayerPanelEditorOpen(editorOpen);
		},
		[setLayerPanelOpen, setLayerPanelEditorOpen],
	);

	useEventListener(PANEL_VISIBILITY_CHANGED, mapPanelVisibilityChanged, map);
	// ------ Layer Panel visibility

	const setSearch = (search: string) => (e: React.MouseEvent) => {
		e.preventDefault();
		map?.current?.setSearch?.(search);
	};

	const previewSearch = (location?: number[] | false) => () => {
		if (!location) return;
		map?.current?.setSearchPreview?.(location);
	};

	const clearPreviewSearch = () => {
		map?.current?.clearSearchPreview?.();
	};

	const closeGeocodePopup = () => {
		setGeocoderPopupProps({});
		map?.current?.removeMarker?.();
	};

	const mouseOutsideMap = () => {
		map?.current?.outsideMap?.();
	};


	useEffect(() => {
		if (defaultSearch) {
			if (geocoderId) {
				console.warn(
					'The property `defaultSearch` should be set on the `<Geocoder>` component, if being used, instead of `<ReactMap>`',
				);
			} else {
				map?.current?.setSearch?.(defaultSearch);
			}
		}
	}, [defaultSearch]);

	useEffect(() => {
		map?.current?.setSearchElement?.(searchRef.current);
	}, [searchRef]);

	useEffect(() => {
		map?.current?.setPanelElement?.(panelRef.current);
	}, [panelRef]);

	useEffect(() => {
		map?.current?.setPreviewElement?.(previewRef.current);
	}, [previewRef]);

	useEffect(() => {
		map?.current?.setMuiTheme?.(theme);
	}, [theme]);

	useEffect(() => {
		globalOnHiddenLayersChange?.(hiddenLayers ?? []);
		globalOnBackgroundChanged?.(
			BackgroundRegistry.getStyleWithFallback(
				background ?? BackgroundTypes.Streets,
				arcGISBasemapStylesToken ?? null,
			),
		);
		if (map?.current?.mapPosition)
			globalOnPositionChange?.(map?.current?.mapPosition);
		if (map?.current?.bounds) globalOnBoundsChange?.(map?.current?.bounds);
		if (map?.current?.layerDebug)
			globalOnLayersDebug?.(map?.current?.layerDebug);
	}, []);

	return (
		<SnackbarProvider
			domRoot={containerRef.current || undefined}
			maxSnack={1}
			anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
			Components={{
				success: StyledMaterialDesignContent,
				error: StyledMaterialDesignContent,
				warning: StyledMaterialDesignContent,
				info: StyledMaterialDesignContent,
				default: StyledMaterialDesignContent,
			}}
			SnackbarProps={{ 'data-cy': 'snackbar' }}>
			<MapboxProvider
				map={map?.current?.map}
				setMapState={map?.current?.setMapState}>
				<Wrapper
					className={cx(printPreview && 'print-preview')}
					onMouseLeave={mouseOutsideMap}
					ref={containerRef}>
					<Messages ref={messagesRef} />
					<PreviewContainer ref={previewRef} />
					<ReactResizeDetector
						handleWidth
						handleHeight
						onResize={(width, height): void =>
							setSize({ width: width ?? 0, height: height ?? 0 })
						}></ReactResizeDetector>
					<SearchContainer ref={searchRef} />
					<inno-map
						mapKey={mapKey}
						arcGISBasemapStylesToken={arcGISBasemapStylesToken}
						basemaporigin={basemapOrigin}
						ref={map}
						background={background}
						mode={mode}
						loglevel={logLevel}
						panel={!!summaryPanel}
						panelOpen={layerPanelInitiallyOpen}
						geocoderid={geocoderId}
						distanceunit={distanceUnit}
						animationControlId={animationControlId}
						hoverPopup={!!hoverPopup}
						hoverPopupDebounce={hoverPopupDebounce}
						showMinZoomWarning={
							printPreview ? false : showMinZoomWarning
						}
						themeEditorMode={editorMode}
						colorByMaxValues={colorByMaxValues}
						pitchControl={pitchControl}
						enableColorByAttributeRange={
							enableColorByAttributeRange
						}
						enableColorByRangeHeatmap={enableColorByRangeHeatmap}
						restrictSearchToBounds={restrictSearchToBounds}
						showMultiThemes={showMultiThemes}
						localStoragePrefix={localStoragePrefix}
						unitSystem={unitSystem}
						gridControl={!!assetGrid}
						printpreview={printPreview}
						defaultthemeid={defaultThemeId}
						searchRadius={searchRadius}
						bearerToken={bearerToken}
						zoomToHighlightedAssets={
							zoomToHighlightedAssets
						}></inno-map>
					<ControlsContainer layerPanelOpen={layerPanelOpen} layerPanelEditorOpen={layerPanelEditorOpen}>
						{polygonControl && showGridPanel && (
							<PolygonControl
								onDrawingComplete={onPolygonComplete}
							/>
						)}
					</ControlsContainer>
					{summaryPanel &&
						React.cloneElement(summaryPanel, {
							items,
							deselectAssets,
							highlightAssets,
							zoomToAssets,
							traceFromAsset,
						})}
					{assetGrid && (
						<AssetGridWrapper show={showGridPanel}>
							{assetGrid}
						</AssetGridWrapper>
					)}
					{functionalityEnabled(
						MapFunction.backgroundControl,
						displayMode,
					) && <div ref={panelRef} />}
					{hoverPopup &&
						React.cloneElement(hoverPopup, {
							...popupProps,
							cancelPopupUpdate,
							containerSize: size,
						})}
					<Popup
						{...geocoderPopupProps}
						anchor={Anchor.bottom}
						containerSize={size}
						dataCy="geocode-popup-wrapper"
						contentDataCy="geocode-popup-content">
						{(features: GeocoderResult[]) => {
							const feature = features[0];
							const lngLat = feature.lngLat?.toArray();
							const latLngToFixed = lngLatToFixed(
								feature.lngLat,
								true,
							);
							return (
								<AddressInfo>
									<CloseButton onClick={closeGeocodePopup}>
										<CloseIcon />
									</CloseButton>
									{feature.place_name && (
										<PlaceName data-cy="geocode-popup-place-name">
											<a
												href="#"
												onClick={setSearch(
													feature.place_name,
												)}
												onMouseEnter={previewSearch(
													'coordinates' in
														feature.geometry &&
														(feature.geometry
															.coordinates as number[]),
												)}
												onMouseOut={clearPreviewSearch}>
												{feature.place_name}
											</a>
										</PlaceName>
									)}
									<LongLatLocation data-cy="geocode-popup-lng-lat">
										<a
											href="#"
											onClick={setSearch(
												latLngToFixed?.join(',') ?? '',
											)}
											onMouseEnter={previewSearch(lngLat)}
											onMouseOut={clearPreviewSearch}>
											{latLngToFixed?.join(', ')}
										</a>
									</LongLatLocation>
								</AddressInfo>
							);
						}}
					</Popup>
				</Wrapper>
			</MapboxProvider>
		</SnackbarProvider>
	);
};
export default ReactMap;
