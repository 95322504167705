import { AssetLink, AssetLinkProps } from './AssetLink';
import { AssetTypeInfo, Field, FieldType } from '@Types/map.types';
import { GridAlignment, GridColDef } from '@mui/x-data-grid';

import { DataGridProps } from '@innovyze/stylovyze';
import React from 'react';
import SpatialFilterInput from './SpatialFilterInput';
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { TFunc } from '@Translations/types';
import { renderCell } from './renderCell';

const valueGetter = (fieldType: FieldType): GridColDef['valueGetter'] => {
	if (fieldType === FieldType.Date) {
		return ({ value }) => value && new Date(value);
	}
};

const valueFormatter = (
	fieldType: FieldType,
	unit?: string,
): GridColDef['valueFormatter'] => {
	if (unit || fieldType === FieldType.Date) {
		return ({ value }) => value;
	}
	return ({ value }) => value ?? '-';
};

export const spatialFilterColumn: Field = {
	name: 'Spatial',
	fieldKey: 'spatial',
	fieldType: FieldType.Boolean,
	columnWidth: 80,
	visible: false,
};

export const excludedFilterColumn: Field = {
	name: 'Excluded',
	fieldKey: 'excluded',
	fieldType: FieldType.Boolean,
	columnWidth: 80,
	visible: false,
};

export const generateColumns = (
	fields: AssetTypeInfo['fields'] = [],
	assetType = '',
	systemType = '',
	t: TFunc,
	onViewDetails: AssetLinkProps['onViewDetails'],
): DataGridProps['columns'] => {
	return [
		{
			field: 'assetId',
			// using the context of existing translations, so is slighly different to context used in rest of file
			headerName: t('Asset ID', { context: 'Asset properties' }),
			minWidth: 160,
			flex: 1,
			hideable: false,
			renderCell: ({ value }) => {
				return (
					<AssetLink
						assetId={value}
						assetType={assetType}
						systemType={systemType}
						onViewDetails={onViewDetails}
					/>
				);
			},
		},
		{
			field: spatialFilterColumn.fieldKey,
			headerName: t(spatialFilterColumn.name, {
				context: 'Asset Property',
			}),
			type: spatialFilterColumn.fieldType,
			valueGetter: ({ row }) => row.isSpatialSelected,
			renderCell: renderCell(spatialFilterColumn.fieldType as FieldType),
			filterOperators: [
				{
					label: 'Is inside',
					value: 'isInside',
					getApplyFilterFn: () => {
						return null;
					},
					InputComponent: SpatialFilterInput,
				},
			],
		},
		{
			field: excludedFilterColumn.fieldKey,
			headerName: t(excludedFilterColumn.name, {
				context: 'Asset Property',
			}),
			type: excludedFilterColumn.fieldType,
			valueGetter: ({ row }) => row.excluded,
			renderCell: renderCell(excludedFilterColumn.fieldType as FieldType),
		},
		...fields
			?.filter(
				({ fieldKey }) =>
					fieldKey !== 'assetId' &&
					fieldKey !== 'spatial' &&
					fieldKey !== 'excluded',
			)
			.map(({ name, fieldType, fieldKey, units }) => ({
				field: fieldKey,
				headerName: t(name, { context: 'Asset Property' }),
				flex: 1,
				type: fieldType,
				align: 'left' as GridAlignment,
				headerAlign: 'left' as GridAlignment,
				valueGetter: valueGetter(fieldType as FieldType),
				valueFormatter: valueFormatter(fieldType as FieldType, units),
				renderCell: renderCell(fieldType as FieldType, units),
			})),
	];
};
