import {
	Bbox,
	EditorModes,
	LayerVisibility,
	MapConfig,
	MapFunction,
	MapModes,
	OldConfig,
} from './types';
import {
	Coord,
	Feature,
	LineString,
	MultiLineString,
	Polygon,
	Units,
	point,
} from '@turf/helpers';
import { Coordinates, Geometry } from './services/types';
import { LngLat, LngLatBounds, LngLatLike } from 'mapbox-gl';

import { DataServiceRequestError } from './services/DataServiceRequestError';
import { GeoJSONFeature } from './features/types';
import { MapHiddenLayers } from './layers/LayerManager';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import booleanContains from '@turf/boolean-contains';
import buffer from '@turf/buffer';
import centroid from '@turf/centroid';
import { defaultThemeProps } from './services/ThemeDefaults';
import explode from '@turf/explode';
import flatten from '@turf/flatten';
import length from '@turf/length';
import lineInterSect from '@turf/line-intersect';
import lineSlice from '@turf/line-slice';

export type ObjectValues = { [name: string]: string };
export type ObjectDeserialised = { [name: string]: unknown };

export const reduceArrayToObject = <T extends Record<string, unknown>>(
	input: T[],
): T => {
	return input.reduce(
		(prev, current) => ({
			...prev,
			...current,
		}),
		{},
	) as T;
};

export const deserialiseObjectValues = (
	input: ObjectValues,
): ObjectDeserialised => {
	if (typeof input !== 'object') return {};
	return reduceArrayToObject(
		Object.entries(input).map(([key, value]) => {
			try {
				return { [key]: JSON.parse(value) };
			} catch (e) {
				return { [key]: value };
			}
		}),
	);
};

// there are different levels of nesting of the geometries
// depending on the type so correcting the structure
// to be able to get an array of coords (array of two numbers):
// [[number, number], [number, number]]
export type NestedCoords = (
	| Coordinates
	| Coordinates[]
	| Coordinates[][]
	| Coordinates[][][]
)[][];
export const normalizeCoordinates = (array: NestedCoords): Coordinates[] => {
	return array
		.filter(c => c !== undefined && c !== null)
		.flat(4)
		.map((value, i, a) =>
			i % 2
				? ([a[i - 1], value] as Coordinates)
				: // the null will be filtered out with the .filter(Boolean) below
				  ((null as unknown) as Coordinates),
		)
		.filter(Boolean);
};

/// Is the boundary pointing to [0,0], [0,0] (likely an uninitalised location).
export const boundsAreNotInitialized = (bounds: LngLatBounds): boolean => {
	if (bounds == null) {
		return true;
	}

	// HACK:
	// Is this the object we think it is?
	// The typing doesn't allow us access to the actual properties on the object I'm seeting, so checking for the existence of the methods we'll later call.
	try {
		return (
			bounds.getNorth() === 0 &&
			bounds.getSouth() === 0 &&
			bounds.getEast() === 0 &&
			bounds.getWest() === 0
		);
	} catch (error) {
		console.error(error);
		return true;
	}
};

export const convertCoordsToBounds = (
	coordinates: LngLatLike[],
): LngLatBounds => {
	if (coordinates.length === 1) {
		return new LngLatBounds(coordinates[0], coordinates[0]);
	} else {
		return coordinates.reduce(
			// the type definitions for bounds.extend appear to be wrong
			// found this usage from the example found here:
			// https://docs.mapbox.com/mapbox-gl-js/example/zoomto-linestring/
			(previous, coord) => previous.extend((coord as unknown) as LngLat),
			new LngLatBounds(coordinates[0], coordinates[1]),
		);
	}
};

export const replacePlaceholder = (
	string: string,
	...data: (string | number)[]
): string => {
	return string.replace(
		/\{[0-9]{1,}\}/g,
		match => (data.shift() as string) || match,
	);
};

export interface DedupeArray {
	[key: string]: string;
}
export const dedupe = (array: DedupeArray[], key: string): DedupeArray[] => {
	return array.filter((v, i, a) => a.findIndex(t => t[key] === v[key]) === i);
};

export const dispatchTargetedEvent = <T>(
	element: HTMLElement | null,
	eventName: string,
	detail?: T,
): void => {
	element?.dispatchEvent(
		new CustomEvent(eventName, {
			bubbles: true,
			composed: true,
			detail,
		}),
	);
};

interface DateFormat {
	day: string;
	month: string;
	year: string;
}

export const formatDateTime = (
	date?: number,
	dateFormat = 'DD/MM/YYYY',
): string => {
	if (!date) return '--/--/----';
	const options: Partial<Intl.DateTimeFormatOptions> = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	};
	const dateParts = Intl.DateTimeFormat(undefined, options)
		.formatToParts(date)
		.reduce(
			(acc, part) => ({
				...acc,
				[part.type]: part.value,
			}),
			{},
		) as DateFormat;
	return dateFormat
		.replace(/dd/i, dateParts.day)
		.replace(/mm/i, dateParts.month)
		.replace(/yyyy/i, dateParts.year);
};

export const formatTime = (date?: number, hour12?: boolean): string => {
	if (!date) return '--:--';
	const options: Partial<Intl.DateTimeFormatOptions> = {
		hour: 'numeric',
		minute: 'numeric',
		hour12,
	};
	return Intl.DateTimeFormat(undefined, options)
		.format(date)
		.toUpperCase();
};

export const deleteEmptyValues = (obj: {
	[key: string]: unknown;
}): { [key: string]: unknown } => {
	const newObject = { ...obj };
	Object.keys(newObject).forEach(
		key => newObject[key] === undefined && delete newObject[key],
	);
	return newObject;
};

export const expandBoundary = (
	boundary: Bbox,
	distance: number,
	units: Units,
): Bbox => {
	const polygon = bboxPolygon(boundary);
	const buffered = buffer(polygon, distance, { units });
	return bbox(buffered) as Bbox;
};

/**
 * returns valid display mode or full as a fallback
 */
export const getDisplayMode = (displayMode: MapModes | undefined): MapModes => {
	if (displayMode && Object.values(MapModes).includes(displayMode)) {
		return displayMode;
	}
	return MapModes.Full;
};

/**
 * check whether the functionality/control should be enabled
 * @param functionName enum of the name of the functionality to check
 * @param displayMode display mode to check against
 */
export const functionalityEnabled = (
	functionName: MapFunction,
	displayMode: MapModes,
): boolean => {
	const notStatic = displayMode !== MapModes.Static;
	const full = displayMode === MapModes.Full;
	const minSearch =
		displayMode === MapModes.Search || displayMode === MapModes.Full;
	const enabled = {
		[MapFunction.interactive]: notStatic,
		[MapFunction.backgroundControl]: full,
		[MapFunction.selection]: full,
		[MapFunction.clusterExapnsion]: notStatic,
		[MapFunction.geolocateControl]: full,
		[MapFunction.scaleControl]: full,
		[MapFunction.navigationControl]: notStatic,
		[MapFunction.geocoderControl]: minSearch,
		[MapFunction.extentsControl]: minSearch,
		[MapFunction.hoverPopup]: notStatic,
		[MapFunction.marker]: minSearch,
	};
	return enabled[functionName];
};

export const santizeString = (input: string): string => {
	return input
		.replaceAll(/[^A-z0-9]/g, '_')
		.replaceAll(/_{2,}/g, '_')
		.toLowerCase();
};

const normaliseColor = (number: number): number => {
	return Math.max(Math.min(255, number), 0);
};

export const lightenColor = (color: string, percent: number): string => {
	const asInt = parseInt(color.replace('#', ''), 16);
	const adjustment = Math.round(2.55 * percent);
	const red = (asInt >> 16) + adjustment;
	const blue = ((asInt >> 8) & 0x00ff) + adjustment;
	const green = (asInt & 0x0000ff) + adjustment;

	return (
		'#' +
		(
			0x1000000 +
			normaliseColor(red) * 0x10000 +
			normaliseColor(blue) * 0x100 +
			normaliseColor(green)
		)
			.toString(16)
			.slice(1)
	);
};

export const notUndefined = <T>(x: T | undefined): x is T => x !== undefined;

/**
 * Parition an array using a filter
 * @param array array to split
 * @param filter function to split into pass and fail
 * @returns [pass[], fail[]]
 */
export const partition = <T>(
	array: T[],
	filter: (e: T) => boolean,
): [T[], T[]] => {
	if (!Array.isArray(array)) return [[], []];
	const pass: T[] = [];
	const fail: T[] = [];
	array.forEach(item => (filter(item) ? pass : fail).push(item));
	return [pass, fail];
};

export const getLayerIdFromIdPath = (idPath: string): string | undefined =>
	idPath.split('/').pop();

export const convertToLayerVisibility = (
	hiddenLayers: MapHiddenLayers,
): LayerVisibility => {
	return reduceArrayToObject(
		hiddenLayers.map(idPath => {
			const layerId = `${getLayerIdFromIdPath(idPath)}`;
			return { [layerId]: false };
		}),
	);
};

export const getCenterPoint = (geometry: Geometry): Coordinates => {
	if (!('coordinates' in geometry)) return [0, 0];
	const { coordinates } = geometry;
	if (!Array.isArray(coordinates[0])) {
		return coordinates as Coordinates;
	}
	const middle = coordinates[Math.floor((coordinates.length - 1) / 2)];
	return middle as Coordinates;
};

export const typeOf = (value: unknown): string =>
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	({}.toString
		.call(value)
		.match(/\s([a-zA-Z]+)/)[1]
		.toLowerCase());

export const shallowEqual = (a: unknown, b: unknown): boolean => {
	if (typeOf(a) !== typeOf(b)) return false;

	if (typeOf(a) === 'array') {
		if ((a as []).length !== (b as []).length) return false;
		return (a as unknown[]).every(
			(entry, index) => entry === (b as unknown[])[index],
		);
	}

	if (typeOf(a) === 'object') {
		return Object.keys(a as Record<string, unknown>).every(
			key =>
				JSON.stringify((a as Record<string, unknown>)[key]) ===
				JSON.stringify((b as Record<string, unknown>)[key]),
		);
	}

	return a === b;
};

export const extractErrorContext = (
	error: DataServiceRequestError,
): { status: string | number; statusText: string } => ({
	status: error?.response?.status ?? 'http status unknown',
	statusText: error?.response?.statusText ?? error.message,
});

/** checks if the coordinates are the same, using the mapbox LngLat
 * function to determine the distance prevents issues when coordinates
 * are not to the same precision
 */
export const coordinatesSame = (
	coords: LngLatLike,
	compareCoords: LngLatLike,
): boolean => {
	const mapLngLat = LngLat.convert(coords);
	const lngLat = LngLat.convert(compareCoords);
	const distance = mapLngLat.distanceTo(lngLat);
	return distance === 0;
};

/**
 * Return an array of the supplied lng, lat into the order lat, lng.
 * @param coords lng lat like
 * @returns lat lng array
 */
export const lngLatToLatLng = (
	coords: LngLat | [number, number] | (string | number)[] | undefined,
): number[] | undefined => {
	if (!coords) return coords;
	const lngLat = LngLat.convert(coords as LngLatLike);
	return [lngLat.lat, lngLat.lng];
};

/**
 * Return coordinates to fixed decimal places
 * @param coords lng lat like
 * @param lngLatReverse reverse lng and lat positions
 * @returns array of lng lat or lat lng coordinates
 */
export const lngLatToFixed = (
	coords: LngLat | [number, number] | (string | number)[] | undefined,
	lngLatReverse = false,
): string[] | undefined => {
	if (!coords) return coords;
	const lngLat = LngLat.convert(coords as LngLatLike).toArray();
	const a = lngLat.map((value: string | number) => {
		const asNum = typeof value === 'string' ? parseFloat(value) : value;
		return asNum.toFixed(6);
	});
	return lngLatReverse ? a.reverse() : a;
};

export const lineWithinPolygon = (
	feature: GeoJSONFeature,
	polygon: GeoJSONFeature,
): boolean => {
	const { features } = lineInterSect(
		feature as Feature<LineString>,
		polygon as Feature<Polygon>,
	);
	let start: Coord;
	let end: Coord;
	if (features.length === 0) {
		const points = explode(feature as Feature<LineString>);
		return points.features.every(feature =>
			booleanContains(polygon, feature),
		);
	} else if (features.length > 1) {
		start = features[0];
		end = features[1];
	} else {
		const { coordinates } = (feature as Feature<LineString>).geometry;
		const lineStart = point(coordinates[0]);
		const lineEnd = point(coordinates[coordinates.length - 1]);
		const startIn = booleanContains(polygon, lineStart);
		if (startIn) {
			start = lineStart;
			end = features[0];
		} else {
			start = features[0];
			end = lineEnd;
		}
	}

	const lineInside = lineSlice(start, end, feature as Feature<LineString>);
	return length(lineInside) > length(feature) / 2;
};

export const featuresInPolygon = (
	features: GeoJSONFeature[],
	polygon: GeoJSONFeature,
): GeoJSONFeature[] => {
	return features.filter(feature => {
		if (
			feature.geometry.type === 'Polygon' ||
			feature.geometry.type === 'MultiPolygon'
		) {
			const point = centroid(feature as Feature<Polygon>);
			return booleanContains(polygon, point);
		}
		if (feature.geometry.type === 'LineString') {
			return lineWithinPolygon(feature, polygon);
		}
		if (feature.geometry.type === 'MultiLineString') {
			// split MultiLineString into a feature collection of LineString
			const asLines = flatten(feature as Feature<MultiLineString>);
			return asLines.features.some(feature =>
				lineWithinPolygon(feature as GeoJSONFeature, polygon),
			);
		}
		return booleanContains(polygon, feature);
	});
};

export const debounce = <T extends unknown[]>(
	func: (...args: T) => void,
	wait = 200,
): { (...args: unknown[]): void; clear: () => void } => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let timeout: any;
	function debounced(...args: unknown[]) {
		const later = () => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			func.apply(this, args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	}

	debounced.clear = () => {
		clearTimeout(timeout);
	};

	return debounced;
};

/** Reform the config object to new structure */
export const reformConfig = (config: OldConfig | MapConfig): MapConfig => {
	if (Array.isArray(config)) {
		return {
			...defaultThemeProps.configEdits,
			layers: config,
		};
	}
	return config;
};

export const getEditMode = (
	editorMode?: EditorModes,
	styleEditor?: boolean,
): EditorModes => {
	if (editorMode && editorMode in EditorModes) {
		return editorMode;
	} else if (styleEditor) {
		return EditorModes.editAll;
	}
	return EditorModes.noEdit;
};

export const cx = (...args: unknown[]): string => {
	return args
		.flat()
		.filter(x => typeof x === 'string')
		.join(' ')
		.trim();
};
