import i18n from 'i18next';
import settings from './settings.json';

export const convertLanguageToIso639 = (lng: string): string =>
	lng.split('-')[0];

export const getLanguage = (): string =>
	i18n.language ?? settings.defaultLanguage;

export const getLanguageIso639 = (): string =>
	convertLanguageToIso639(getLanguage());
