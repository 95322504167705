import {
	AssetAttrbuteField,
	AssetDetailParams,
	AssetPipeInfoResolvedResponse,
} from '@Types/asset.types';
import { ExpectedStringType, parseServerObjectToFormattedString } from '@Utils';
import {
	addAssetDetailNote,
	checkAssetIds,
	checkAssetIdsRejected,
	checkAssetIdsResolved,
	getAssetDetail,
	getAssetDetailRejected,
	getAssetDetailResolved,
	loadAssetDetails,
	setAssetDetailIsWaiting,
	setAssetDetailNotesIsWaiting,
	setInspectionsValidatingAsset,
	getAssetLocation,
	setAssetLocationIsWaiting,
	setAssetLocation,
	getAssetDetailMapInsertResolved,
} from '@Actions/assetDetail.actions';
import {
	addAssetNoteApi,
	checkAssetIdsApi,
	getAssetDetailApi,
} from '@Services';
import {
	getAsset,
	selectInspectionsByAssetId,
	selectRiskScoreByAssetId,
	AssetRiskAndCondition,
	getMapLayerId,
} from '@innovyze/map-components';
import {
	getAssetRisk,
	getAssetRiskHistory,
	getAssetRiskResolved,
	getRiskSummary,
	getRiskSummaryResolved,
} from '@Actions/assetRisk.actions';
import {
	getInspectionAttributes,
	getInspectionAttributesResolved,
} from '@Actions/inspection.actions';
import { put, retry, select, takeLatest } from 'redux-saga/effects';

import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import { InspectionScoredAttributes } from '@Types/inspectionAttributes.types';
import { AssetLocation, GetAssetLocationResponse, Note } from '@Types';
import { PayloadAction } from '@reduxjs/toolkit';
import center from '@turf/center';
import { Geometry } from '@innovyze/shared-utils/';
import { getAssetManhole } from '@Actions/assetManhole.actions';
import { getAssetPipe } from '@Actions/assetPipe.actions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* getAssetDetailSaga(action: AnyAction) {
	try {
		yield put(setAssetDetailIsWaiting(!action.payload.forNotes));
		yield put(setAssetDetailNotesIsWaiting(action.payload.forNotes));

		const payload: AxiosResponse<AssetPipeInfoResolvedResponse> =
			yield retry(5, 30000, getAssetDetailApi, action.payload);

		const formatDateUTC = action.payload.formatDateUTC;
		// Fixup date/time fields
		if (payload.data.asset['yearLaid']) {
			payload.data.asset['yearLaid'] = parseServerObjectToFormattedString(
				payload.data.asset['yearLaid'] as string,
				ExpectedStringType.Date,
				formatDateUTC,
			);
		}

		if (payload.data.asset['yearInstalled']) {
			payload.data.asset['yearInstalled'] =
				parseServerObjectToFormattedString(
					payload.data.asset['yearInstalled'] as string,
					ExpectedStringType.Date,
					formatDateUTC,
				);
		}

		if (payload.data.asset['yearRenewed']) {
			payload.data.asset['yearRenewed'] =
				parseServerObjectToFormattedString(
					payload.data.asset['yearRenewed'] as string,
					ExpectedStringType.Date,
					formatDateUTC,
				);
		}

		let attributes: AssetAttrbuteField[] = Object.keys(payload.data.asset)
			.filter(
				property =>
					property !== 'geometry' &&
					property !== 'segments' &&
					property !== 'tenantId' &&
					property !== 'notes',
			)
			.map(property => ({
				FieldName: property,
				FieldTitle: property,
				FieldValue:
					payload.data.asset[property] === null
						? ''
						: String(payload.data.asset[property]),
			}));

		if (
			payload.data.asset &&
			payload.data.asset.segments &&
			payload.data.asset.segments.length == 1
		) {
			attributes = [
				...attributes,
				...Object.keys(payload.data.asset.segments[0])
					.filter(
						property =>
							property !== 'startDistance' &&
							property !== 'segmentId' &&
							property !== 'startLength',
					)
					.map(property => ({
						FieldName: property,
						FieldTitle: property,
						FieldValue:
							payload.data.asset.segments[0][property] === null
								? ''
								: String(
										payload.data.asset.segments[0][
											property
										],
								  ),
					})),
			];
		}

		const result = {
			notes: payload.data.asset.notes as Note[],
			assetDetails: attributes,
		};

		yield put(getAssetDetailResolved(result));

		if (payload.data.asset['geometry']) {
			let layerId = action.payload.layerId;
			const systemType = payload.data.asset['layerSystemType'] as string;
			if (layerId === undefined && systemType !== undefined) {
				layerId = getMapLayerId(action.payload.assetType, systemType);
			}

			yield put(
				getAssetDetailMapInsertResolved({
					geometry: payload.data.asset['geometry'] as Geometry,
					layerId: layerId,
				}),
			);
		}
	} catch (e) {
		yield put(getAssetDetailRejected());
	}
}

function* watchGetAssetDetail() {
	yield takeLatest(getAssetDetail, getAssetDetailSaga);
}

function* addAssetDetailNoteSaga(action: AnyAction) {
	try {
		yield put(setAssetDetailNotesIsWaiting(true));
		yield retry(
			5,
			15000,
			addAssetNoteApi,
			action.payload.assetDetailParams,
			action.payload.addNoteParams,
		);
		yield put(getAssetDetail(action.payload.assetDetailParams));
	} catch (e) {
		yield put(setAssetDetailNotesIsWaiting(false));
	}
}

function* watchAddAssetDetailNote() {
	yield takeLatest(addAssetDetailNote, addAssetDetailNoteSaga);
}

function* checkAssetIdsSaga(action: AnyAction) {
	try {
		yield put(setInspectionsValidatingAsset(true));
		const payload: AxiosResponse<string[]> = yield retry(
			5,
			15000,
			checkAssetIdsApi,
			action.payload,
		);
		yield put(checkAssetIdsResolved(payload.data));
		yield put(setInspectionsValidatingAsset(false));
	} catch (e) {
		yield put(checkAssetIdsRejected());
		yield put(setInspectionsValidatingAsset(false));
	}
}

function* watchCheckAssetIds() {
	yield takeLatest(checkAssetIds, checkAssetIdsSaga);
}

function* loadAssetDetailsSaga(action: PayloadAction<AssetDetailParams>) {
	switch (action.payload.assetType) {
		case 'wwManhole':
			yield put(getAssetManhole(action.payload));
			break;
		default:
			yield put(getAssetPipe(action.payload));
			break;
	}

	yield put(getAssetDetail(action.payload));
	yield put(getAssetRiskHistory(action.payload));

	// const isWebSocketEnabled = useIsFeatureEnabled(
	// 	'info-360-asset-risk-web-socket',
	// );
	// const isWebSocketEnabled = true;
	// if (isWebSocketEnabled) {
	// 	const webSocket = WebSocketClass.getWebSockets();
	// 	webSocket.sendMessage(JSON.stringify(
	// 		{
	// 			assetId: `${action.payload.assetType}%23${action.payload.assetId}`,
	// 			action: 'riskSummary',
	// 		}
	// 	));
	// } else {
	// 	yield put(getRiskSummary(action.payload));
	// }

	// since the property pane is getting the risk and inspection data
	// lets re-use the data, fallback is to request it
	const id = `${action.payload.assetType}#${action.payload.assetId}`;
	const riskForAsset: AssetRiskAndCondition | null = yield select(state =>
		selectRiskScoreByAssetId(state, id),
	);

	if (riskForAsset) {
		yield put(getAssetRiskResolved(riskForAsset as AssetRiskAndCondition));
	} else {
		yield put(getAssetRisk(action.payload));
	}

	const inspectionsForAsset: InspectionScoredAttributes[] | null =
		yield select(state => selectInspectionsByAssetId(state, id));

	if (inspectionsForAsset) {
		yield put(
			getInspectionAttributesResolved({
				data: inspectionsForAsset as InspectionScoredAttributes[],
			}),
		);
	} else {
		yield put(getInspectionAttributes(action.payload));
	}
	// new dynamic properties need slightly different structure
	// of data to old get asset details
	yield put(getAsset(id));
}

function* watchGetMapAssetDetails() {
	yield takeLatest(loadAssetDetails, loadAssetDetailsSaga);
}

function* getAssetLocationSaga(action: AnyAction) {
	try {
		yield put(setAssetLocationIsWaiting(true));

		const payload: AxiosResponse<GetAssetLocationResponse> = yield retry(
			5,
			15000,
			getAssetDetailApi,
			action.payload,
		);

		const geometry = payload.data.asset.geometry;
		let result: AssetLocation | undefined = undefined;

		if (geometry) {
			let position: GeoJSON.Position | undefined = undefined;
			// Find the center
			switch (geometry.type) {
				case 'Point':
					position = (geometry as GeoJSON.Point).coordinates;
					break;
				case 'LineString':
					position = center(geometry as GeoJSON.LineString).geometry
						.coordinates;
					break;
				case 'Polygon':
					position = center(geometry as GeoJSON.Polygon).geometry
						.coordinates;
					break;
				case 'MultiPoint':
					position = center(geometry as GeoJSON.MultiPoint).geometry
						.coordinates;
					break;
				case 'MultiLineString':
					position = center(geometry as GeoJSON.MultiLineString)
						.geometry.coordinates;
					break;
				case 'MultiPolygon':
					position = center(geometry as GeoJSON.MultiPolygon).geometry
						.coordinates;
					break;
			}

			if (position) {
				result = {
					assetId: payload.data.asset.assetId,
					position,
				};
			}
		}

		yield put(setAssetLocation(result));
	} catch (e) {
		yield put(setAssetLocation(undefined));
	}

	yield put(setAssetLocationIsWaiting(false));
}

function* watchGetAssetLocation() {
	yield takeLatest(getAssetLocation, getAssetLocationSaga);
}

export default [
	watchGetAssetDetail(),
	watchAddAssetDetailNote(),
	watchCheckAssetIds(),
	watchGetMapAssetDetails(),
	watchGetAssetLocation(),
];
