import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	assetGridExcludeItem,
	assetGridIncludeItem,
	clearAssets,
	getAssetResolved,
	getAssetsPaginated,
	getAssetsPaginatedReset,
	getAssetsPaginatedResolved,
	getSensorResolved,
	getSystemAssetTypesResolved,
} from '@Actions/asset';

import { AssetStoreState } from '@Types/asset.types';

export const initialState: AssetStoreState = {
	assets: [],
	sensors: [],
	assetGrid: {
		loading: false,
		items: [],
		pagination: {
			current: 1,
			pages: 1,
			total: 10,
			limit: 10,
		},
		sort: undefined,
		sortDescending: false,
		filterModel: undefined,
	},
	excludedList: [],
	systemAssets: {},
};

interface RiskReducer {
	[x: string]: (
		state: AssetStoreState,
		action: AnyAction,
	) => AssetStoreState | undefined;
}

const reducer: RiskReducer = {
	[getAssetResolved.toString()]: (state, action) => ({
		...state,
		assets: [...state.assets, action.payload],
	}),
	[getSensorResolved.toString()]: (state, action) => ({
		...state,
		sensors: [...state.sensors, action.payload],
	}),
	[clearAssets.toString()]: () => ({
		...initialState,
	}),
	[getAssetsPaginated.toString()]: state => ({
		...state,
		assetGrid: {
			...state.assetGrid,
			loading: true,
		},
	}),
	[getAssetsPaginatedResolved.toString()]: (state, action) => ({
		...state,
		assetGrid: { ...action.payload, loading: false },
	}),
	[getAssetsPaginatedReset.toString()]: state => ({
		...state,
		assetGrid: initialState.assetGrid,
	}),
	[getSystemAssetTypesResolved.toString()]: (state, action) => ({
		...state,
		systemAssets: action.payload,
	}),
	[assetGridExcludeItem.toString()]: (state, action) => ({
		...state,
		excludedList: [...state.excludedList, action.payload],
	}),
	[assetGridIncludeItem.toString()]: (state, action) => ({
		...state,
		excludedList: state.excludedList.filter(
			item => item !== action.payload,
		),
	}),
};

export default createReducer(initialState, reducer);
