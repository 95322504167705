import { NamespacedStoreState, StoreState } from '@Types/store.types';

import { GridRowId } from '@mui/x-data-grid-premium';
import { moduleNamespace } from '../store.config';

export const getValue = (state: NamespacedStoreState): StoreState =>
	state[moduleNamespace];

export const passInId = (
	state: NamespacedStoreState,
	selectedItem: string | null,
): string | null => selectedItem;

export const passInSystemType = (
	state: NamespacedStoreState,
	systemType: string | null,
): string | null => systemType;

export const passInSelection = (
	state: NamespacedStoreState,
	selectedItems: GridRowId[],
): GridRowId[] => selectedItems;
