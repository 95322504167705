import MapboxLayer, { MapboxLayerProps, UniqueLayerProps } from './MapboxLayer';

import { ServiceLayer } from '../../services/types';
import { assetUnknown } from '../../services/AssetRegistry';

interface ClusterLayerProps extends MapboxLayerProps {
	color: ServiceLayer['color'];
}

export default class ClusterLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'circle';
	protected _color: ServiceLayer['color'];

	constructor({ color, ...other }: ClusterLayerProps) {
		super({ ...other, color });
		this._color = color || assetUnknown.color;
	}

	layerProperties(): UniqueLayerProps {
		return {
			paint: {
				'circle-color': this._color,
				'circle-radius': [
					'step',
					['get', 'point_count'],
					16,
					100,
					21,
					750,
					26,
				],
			},
		};
	}
}
