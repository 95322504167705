import { GeoJsonDataType, ServiceLayer } from './types';

import { AnySourceData } from 'mapbox-gl';
import { MapboxLayerProps } from '@Map/layers/mapbox/MapboxLayer';

export type MapboxDataSourceProps = Pick<
	ServiceLayer,
	| 'displayName'
	| 'type'
	| 'icon'
	| 'color'
	| 'cluster'
	| 'zIndex'
	| 'sourceLayer'
> & {
	id: string;
	data?: GeoJsonDataType;
	tileUrl?: string | string[];
	maxZoom?: number;
	minZoom?: number;
};

export default class MapboxDataSource {
	private _id: MapboxDataSourceProps['id'];
	private _sourceLayer: MapboxLayerProps['sourceLayer'];
	private _data: MapboxDataSourceProps['data'];
	private _displayName: MapboxDataSourceProps['displayName'];
	private _type: MapboxDataSourceProps['type'];
	private _icon: MapboxDataSourceProps['icon'];
	private _color: MapboxDataSourceProps['color'];
	private _cluster: MapboxDataSourceProps['cluster'];
	private _tileUrl: MapboxDataSourceProps['tileUrl'];
	private _minZoom: MapboxDataSourceProps['minZoom'];
	private _maxZoom: MapboxDataSourceProps['maxZoom'];
	private _zIndex: MapboxDataSourceProps['zIndex'];

	constructor({
		id,
		sourceLayer,
		data,
		tileUrl,
		displayName,
		type,
		icon,
		color,
		cluster = false,
		minZoom,
		maxZoom,
		zIndex,
	}: MapboxDataSourceProps) {
		this._id = id;
		this._sourceLayer = sourceLayer;
		this._data = data;
		this._tileUrl = tileUrl;
		this._displayName = displayName;
		this._type = type;
		this._cluster = cluster;
		this._icon = icon;
		this._color = color;
		this._minZoom = minZoom;
		this._maxZoom = maxZoom;
		this._zIndex = zIndex;
	}

	get id(): MapboxDataSourceProps['id'] {
		return this._id;
	}

	get sourceLayer(): MapboxDataSourceProps['sourceLayer'] {
		return this._sourceLayer;
	}

	get data(): MapboxDataSourceProps['data'] {
		return this._data;
	}

	get displayName(): MapboxDataSourceProps['displayName'] {
		return this._displayName;
	}

	get type(): MapboxDataSourceProps['type'] {
		return this._type;
	}

	get icon(): MapboxDataSourceProps['icon'] {
		return this._icon;
	}

	get color(): MapboxDataSourceProps['color'] {
		return this._color;
	}

	get source(): AnySourceData {
		if (!this._tileUrl) {
			return {
				type: 'geojson',
				data: this.data,
				cluster: this._cluster,
				clusterRadius: 40,
				maxzoom: 15,
				promoteId: 'id',
			} as AnySourceData;
		}

		return {
			type: this._type === 'raster' ? 'raster' : 'vector',
			tiles: [this._tileUrl].flat(),
			...(this._minZoom && { minzoom: this._minZoom }),
			...(this._maxZoom && { maxzoom: this._maxZoom }),
			promoteId: 'id',
		} as AnySourceData;
	}

	get cluster(): MapboxDataSourceProps['cluster'] {
		return this._cluster;
	}

	get zIndex(): MapboxDataSourceProps['zIndex'] {
		return this._zIndex;
	}

	set tileUrl(tileUrl: string | string[]) {
		this._tileUrl = tileUrl;
	}
}
