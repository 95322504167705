import { GridFilterModel, getDefaultGridFilterModel } from '@mui/x-data-grid';
import React, { ReactNode, createContext, useMemo, useState } from 'react';
import {
	addExcludedFilter,
	addPolygonFilter,
	removeExcludedFilter,
} from '@Utils/filter';

type ProcessFilterFunction = (filter: GridFilterModel) => GridFilterModel;

export interface FilterContextProps {
	rawFilter: GridFilterModel;
	setFilter: React.Dispatch<React.SetStateAction<GridFilterModel>>;
	setProcessFilterFunc: React.Dispatch<
		React.SetStateAction<ProcessFilterFunction>
	>;
	filter: GridFilterModel;
	setPolygonFilter: (
		filter: GeoJSON.Feature<GeoJSON.Polygon> | undefined,
	) => void;
	clearFilters: () => void;
	additionalFilterProps: Record<string, unknown>;
	setAdditionalFilterProps: React.Dispatch<
		React.SetStateAction<Record<string, unknown>>
	>;
	initExcludedFilter: () => void;
	clearExcludedFilter: () => void;
}

export const FilterContext = createContext<FilterContextProps | null>(null);

export const useFilter = (): FilterContextProps => {
	return (
		React.useContext(FilterContext) ?? {
			rawFilter: getDefaultGridFilterModel(),
			setFilter: () => undefined,
			setProcessFilterFunc: () => undefined,
			filter: getDefaultGridFilterModel(),
			setPolygonFilter: () => undefined,
			clearFilters: () => undefined,
			additionalFilterProps: {},
			setAdditionalFilterProps: () => undefined,
			initExcludedFilter: () => undefined,
			clearExcludedFilter: () => undefined,
		}
	);
};

interface FilterProviderProps {
	children: ReactNode;
}

export const FilterProvider = ({
	children,
}: FilterProviderProps): JSX.Element => {
	const [rawFilter, setRawFilter] = useState<GridFilterModel>(
		getDefaultGridFilterModel(),
	);
	const [processFilterFunc, setProcessFilterFunc] = useState<
		ProcessFilterFunction
	>(() => (filter: GridFilterModel) => filter);
	const [additionalFilterProps, setAdditionalFilterProps] = useState<
		Record<string, unknown>
	>({});

	const setPolygonFilter: FilterContextProps['setPolygonFilter'] = polygonFilter => {
		setRawFilter(state => addPolygonFilter(state, polygonFilter));
	};

	const initExcludedFilter = () => {
		setRawFilter(state => addExcludedFilter(state));
	};

	const clearExcludedFilter = () => {
		setRawFilter(state => removeExcludedFilter(state));
	};

	const filter = useMemo(() => processFilterFunc(rawFilter), [
		rawFilter,
		processFilterFunc,
	]);

	const clearFilters = () => {
		setRawFilter(getDefaultGridFilterModel());
	};

	return (
		<FilterContext.Provider
			value={{
				rawFilter,
				setFilter: setRawFilter,
				setProcessFilterFunc,
				filter,
				setPolygonFilter,
				clearFilters,
				additionalFilterProps,
				setAdditionalFilterProps,
				initExcludedFilter,
				clearExcludedFilter,
			}}>
			{children}
		</FilterContext.Provider>
	);
};
