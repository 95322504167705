import { LayerZindex, TileJson } from '@Map/services/types';
import { RequireSome, RequiredWithPartial } from '@Map/typeUtils';

import { CompositeLayerProps } from '@Map/layers/types';
import { EventProps } from '@Map/events/Events';
import { ExtractTrans } from '@Translations/extraction';
import { GradientKey } from '@Map/layers/settings';
import { Info360TileMetadataV2 } from '@Map/services/Info360TileTypes';
import { MapHiddenLayers } from '@Map/layers/LayerManager';

export enum AdaptorTypes {
	tiles = 'tiles',
	tilesJson = 'tilesJson',
}

export interface Adaptor {
	type: AdaptorTypes;
	overrides: ConfigOverrides[];
}

export enum ColorByOptions {
	fixed = 'fixed',
	attribute = 'attribute',
	range = 'range',
}

export interface ColorRange {
	value: number;
	color: string;
}

export enum AttributeTypes {
	discrete = 'discrete',
	range = 'range',
}

export type LayerType =
	| 'line'
	| 'symbol'
	| 'polygon'
	| 'fill'
	| 'heatmap'
	| 'bubble';

export interface ConfigLayer {
	layerName: string | ExtractTrans;
	id?: string;
	/** @private - used internally, will be overwritten */
	idPath?: string;
	layers?: ConfigLayer[];
	layersEndpoint?: string;
	hideOnNoData?: boolean;
	requestToken?: string;
	dataServiceId?: string;
	tileSource?: string | RegExp;
	tileLayerName?: string | RegExp;
	count?: number;
	properties?: Record<string, string | [number, number] | string[]>;
	type?: LayerType;
	color?: string;
	outlineColor?: string;
	selectedColor?: string;
	highlightColor?: string;
	labelColor?: string;
	colorBy?: ColorByOptions;
	attribute?: string;
	attributeType?: AttributeTypes;
	attributeUnit?: string;
	colorRamp?: GradientKey;
	colorRange?: ColorRange[];
	fillOpacity?: number;
	radius?: number;
	scale?: number;
	pattern?: string;
	/** @private - used internally */
	patternSvg?: string;
	selectedPattern?: string;
	icon?: string;
	/** @private - used internally */
	iconImage?: string;
	selectedIcon?: string;
	iconBackground?: boolean;
	lineWidth?: number;
	lineDashArray?: number[];
	zIndex?: LayerZindex;
	minZoom?: number;
	maxZoom?: number;
	/** only used if layer type is line and has icon */
	iconMinZoom?: number;
	iconMaxZoom?: number;
	visible?: boolean;
	showFlow?: boolean;
	flowMinZoom?: number;
	selectable?: boolean;
	/** @private - used internally */
	_adaptor?: Adaptor;
}

export type ConfigLayerInternal = Omit<
	RequireSome<ConfigLayer, 'id' | 'idPath'>,
	'layers'
> & {
	layers?: ConfigLayerInternal[];
	virtualLayers?: ConfigLayerInternal[];
	showColorBy?: ColorByOptions;
	validColorBy?: boolean;
	colorByTheming?: ColorByOptions;
	canHeatmap?: boolean;
};

export type ConfigOverrides = Omit<
	ConfigLayer,
	'layerName' | 'layersEndpoint' | '_adaptor'
> & {
	assetType: string;
	systemType: string;
};

export type AdaptorConverter<T> = (data: T) => Partial<ConfigLayer>;

export type AdaptorData = Info360TileMetadataV2 & TileJson;

export type AdaptorConverters =
	| AdaptorConverter<Info360TileMetadataV2>
	| AdaptorConverter<TileJson>;

export enum BackgroundTypes {
	Streets = 'streets',
	Satellite = 'satellite',
	Outdoors = 'outdoors',
	Dark = 'dark',
	OSMStandard = 'osmStandard',
	OSMRelief = 'osmRelief',
	OSMStreets = 'osmStreets',
	OSMStreetsRelief = 'osmStreetsRelief',
	OSMLightGray = 'osmLightGray',
	OSMDarkGray = 'osmDarkGray',
	ArcGISImagery = 'arcGISImagery',
	ArcGISImageryLabels = 'arcGISImageryLabels',
}

export enum EditorModes {
	noEdit = 'noEdit',
	editOwn = 'editOwn',
	editAll = 'editAll',
}

export enum EditorState {
	readonly = 'readonly',
	edit = 'edit',
}

export interface Background {
	title: string;
	uri: string;
	description: string;
	key: BackgroundTypes;
}

export type BasemapType = 'mapbox' | 'arcgis';

export interface LayerVisibility {
	[key: string]: boolean;
}

export interface LayerToggle extends EventProps {
	layerPath: string;
	state: boolean;
	hiddenLayers: MapHiddenLayers;
	layerVisibility?: LayerVisibility;
}

export interface EditorToggle extends EventProps {
	open: boolean;
}

export interface LayerUpdate extends EventProps {
	compositeLayer: CompositeLayerProps;
}

export interface LayerEdits {
	[key: string]: Partial<ConfigLayer>;
}

export interface ConfigEdits {
	layers: LayerEdits;
	pitchControl: boolean;
}

export type OldConfig = Partial<ConfigLayer>[];
export interface MapConfig {
	layers: OldConfig;
	pitchControl: boolean;
}

export interface ThemeResponse {
	config: MapConfig;
	themeName?: string;
	themeId?: string;
	userId?: string;
}

export interface ThemeProperties {
	configEdits: ConfigEdits;
	name?: string | ExtractTrans;
	id?: string;
	isUserTheme?: boolean;
}

export interface ThemeListResponseItem {
	name?: string | ExtractTrans;
	id?: string;
	userId?: string;
}

export type ThemeListResponse = ThemeListResponseItem[];

export type ThemeListItem = RequiredWithPartial<ThemeProperties, 'id' | 'name'>;

export type ThemeList = ThemeListItem[];

export interface ThemeSettings {
	shared: boolean;
}

export interface EditorSave extends EventProps {
	edits: ConfigEdits;
}

export interface CBAttributes {
	id: string;
	fieldType: AttributeTypes;
	units?: string;
}

export interface CBAttributesPerLayer {
	[key: string]: CBAttributes[];
}

export interface CBHasValuesPerAttributeId {
	[key: string]: number;
}

export interface CBHasValuesPerAttributePerLayer {
	[key: string]: CBHasValuesPerAttributeId;
}

export type CBValues = string[];

export interface CBResponseValues {
	count: number;
	values: CBValues;
}

export interface CBAttributeLookup {
	layerId: string;
	attributeId: string;
}

export interface ServiceAttributeLookup {
	[key: string]: CBAttributeLookup[];
}
