import {
	getValue,
	passInId,
	passInSelection,
	passInSystemType,
} from './shared';

import { createSelector } from '@reduxjs/toolkit';
import { selectInspectionsByAssetId } from './inspections';
import { selectRiskScoreByAssetId } from './risk';

export const selectAssetBase = createSelector(
	[getValue],
	moduleState => moduleState.asset,
);

export const selectAssets = createSelector(
	[selectAssetBase],
	baseState => baseState.assets,
);

export const selectAssetById = createSelector(
	[selectAssets, passInId],
	(assets, selectedItem) => assets.find(({ _id }) => _id === selectedItem),
);

export const selectSensors = createSelector(
	[selectAssetBase],
	baseState => baseState.sensors,
);

export const selectSensorById = createSelector(
	[selectSensors, passInId],
	(assets, selectedItem) => assets.find(({ _id }) => _id === selectedItem),
);

export const selectExtendedAssetById = createSelector(
	[selectAssetById, selectRiskScoreByAssetId, selectInspectionsByAssetId],
	(asset, risk, inspections) => {
		if (!asset) return undefined;
		return {
			...asset,
			risk,
			inspections,
		};
	},
);

export const selectPaginatedAssets = createSelector(
	[selectAssetBase],
	baseState => baseState.assetGrid,
);

export const selectGridLoading = createSelector(
	[selectPaginatedAssets],
	paginatedAssets => paginatedAssets.loading,
);

export const selectGridItems = createSelector(
	[selectPaginatedAssets],
	paginatedAssets => paginatedAssets.items,
);

export const selectGridExcludeList = createSelector(
	[selectAssetBase],
	baseState => baseState.excludedList,
);

export const selectGridRows = createSelector(
	[selectGridItems, selectGridExcludeList],
	(items, excludedList) =>
		items.map(({ segments, ...properties }) => ({
			...properties,
			...segments?.[0],
			excluded: excludedList.includes(properties._id),
		})),
);

export const selectSelectionGridRows = createSelector(
	[selectGridRows, passInSelection],
	(rows, selection) => rows.filter(({ _id }) => selection.includes(_id)),
);

export const selectSelectedAssets = createSelector(
	[selectSelectionGridRows],
	rows =>
		rows.map(({ _id, assetId, assetType, layerSystemType, geometry }) => ({
			_id,
			assetId,
			assetType,
			systemType: layerSystemType,
			geometry,
		})),
);

export const selectGridPagination = createSelector(
	[selectPaginatedAssets],
	paginatedAssets => paginatedAssets.pagination,
);

export const selectGridSort = createSelector(
	[selectPaginatedAssets],
	({ sort, sortDescending }) => ({ sort, sortDescending }),
);

export const selectSystemAssets = createSelector(
	[selectAssetBase],
	baseState => baseState.systemAssets,
);
export const selectSystemTypes = createSelector(
	[selectSystemAssets],
	systemAssets =>
		Object.keys(systemAssets).filter(systemType => systemType !== 'null'),
);

export const selectAssetTypesInSystem = createSelector(
	[selectSystemAssets, passInSystemType],
	(systemAssets, systemType) =>
		systemType ? systemAssets?.[systemType] : undefined,
);
