export * from './assetDetail.actions';
export * from './assetManhole.actions';
export * from './assetPipe.actions';
export * from './assetRisk.actions';
export * from './inspection.actions';
export * from './map.actions';
export * from './spatial.actions';
export * from './observations.actions';
export * from './riskConfigs.actions';
export * from './charts.actions';
export * from './configuration.actions';
export * from './Rehab.action';

import { getFullInsp, addNote, clearFullInsp } from './fullInspection.actions';
import {
	getObservations,
	editObservations,
	createObservations,
	deleteObservations,
	setJointOnly,
	setContinuousOnly,
	setStepOnly,
	clearFilters,
	setGradeFilters,
	setTypeFilters,
	clearObservations,
} from './observations.actions';
import { checkAssetIds, clearValidAssetIds } from './assetDetail.actions';
import { getConfigurationWithMapping } from './configuration.actions';

export {
	getFullInsp,
	clearFullInsp,
	addNote,
	checkAssetIds,
	clearValidAssetIds,
	getObservations,
	editObservations,
	createObservations,
	deleteObservations,
	setJointOnly,
	setContinuousOnly,
	setStepOnly,
	setGradeFilters,
	setTypeFilters,
	clearFilters,
	clearObservations,
	getConfigurationWithMapping,
};

export default {
	getFullInsp,
	clearFullInsp,
	addNote,
	checkAssetIds,
	clearValidAssetIds,
	getObservations,
	editObservations,
	createObservations,
	deleteObservations,
	setJointOnly,
	setContinuousOnly,
	setGradeFilters,
	setTypeFilters,
	setStepOnly,
	clearFilters,
	clearObservations,
	getConfigurationWithMapping,
};
