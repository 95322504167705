import { FormControlOutline, FormControlUnderline } from './Select.styles';
import { InputLabel, MenuItem, Select } from '@mui/material';
import React, { ElementType } from 'react';

import { SelectInputProps } from '@mui/material/Select/SelectInput';
import { selectSystemTypes } from '@Selectors/asset';
import { useGlobalization } from '@Translations/useGlobalization';
import { useSelector } from 'react-redux';

export interface SystemTypeSelectProps {
	systemType: string;
	onSystemTypeChange: SelectInputProps<string>['onChange'];
	topBar?: boolean;
	dataCy?: string;
}

export const SystemTypeSelect = ({
	systemType,
	onSystemTypeChange,
	topBar,
	dataCy,
}: SystemTypeSelectProps): JSX.Element => {
	const { t } = useGlobalization();
	const systemTypes = useSelector(selectSystemTypes);

	const FormControl: ElementType = topBar
		? FormControlUnderline
		: FormControlOutline;

	return (
		<FormControl>
			<InputLabel shrink={topBar}>{t('System type')}</InputLabel>
			<Select
				displayEmpty
				value={systemType}
				onChange={onSystemTypeChange}
				disabled={!systemTypes?.length}
				data-cy={dataCy}>
				{topBar && (
					<MenuItem disabled value="">
						{t('Select a system type')}
					</MenuItem>
				)}
				{systemTypes.map(systemType => (
					<MenuItem key={systemType} value={systemType}>
						{systemType}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};
