import React from 'react';
import { useDispatch } from 'react-redux';
import {
	useAuth0,
	useSiteConfig,
	useIsFeatureEnabled,
	useCompanyDateTime,
	useTzDateTime,
	STYLOVYZE_FLAGS,
	useSettings,
} from '@innovyze/stylovyze';
import { BackgroundTypes, MapModes, ReactMap } from '@innovyze/inno-map';

import {
	selectFullInspection,
	selectFullInspectionIsWaiting,
	useSelectMapInsertInspection,
} from '@Selectors';
import { InspectionStandard, MapStatusType } from '@Types';
import { getFullInsp } from '@Actions/fullInspection.actions';

interface Props {
	standard: InspectionStandard;
	inspectionId: string;
	logLevel?: MapStatusType;
}

const MapInsertInspection: React.FC<Props> = ({
	standard,
	inspectionId,
	logLevel = MapStatusType.WARN,
}: Props) => {
	const dispatch = useDispatch();

	const { config } = useSiteConfig();
	const { token } = useAuth0();
	const { formatDate } = useCompanyDateTime();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const inputs = useSelectMapInsertInspection(token);
	const fullInspectionIsWaiting = selectFullInspectionIsWaiting();
	const inspection = selectFullInspection();
	const { companySettings } = useSettings();

	const enableMediaTranscode = useIsFeatureEnabled('info-360-media-convert')
		? true
		: false;

	const showArcGISBasemap = !!useIsFeatureEnabled(
		STYLOVYZE_FLAGS.ARCGIS_BASEMAPS,
	);
	const arcGISKey = config?.mapbox?.arcGISKey ?? undefined;
	const basemapOrigin = showArcGISBasemap
		? companySettings.mapSettings?.basemapOrigin ?? 'mapbox'
		: 'mapbox';
	const background =
		basemapOrigin == 'arcgis'
			? BackgroundTypes.OSMStandard
			: BackgroundTypes.Streets;

	React.useEffect(() => {
		if (
			standard !== undefined &&
			!fullInspectionIsWaiting &&
			inspection._id !== inspectionId
		) {
			// Ensure map.reducers are triggered with the appropriate inspection geometry
			dispatch(
				getFullInsp({
					standard,
					inspectionId,
					formatDate,
					formatDateUTC,
					enableMediaTranscode,
				}),
			);
		}
	}, [
		standard,
		inspectionId,
		formatDate,
		dispatch,
		fullInspectionIsWaiting,
		inspection,
	]);

	return (
		<ReactMap
			mapKey={config?.mapbox.key || ''}
			mode={MapModes.Navigation}
			dataServices={inputs.sources}
			layerConfig={inputs.layers}
			autoResize={false}
			position={inputs.location?.position ?? undefined}
			showMinZoomWarning={false}
			logLevel={logLevel ?? MapStatusType.WARN}
			arcGISBasemapStylesToken={arcGISKey}
			background={background}
			basemapOrigin={basemapOrigin}
		/>
	);
};

export default MapInsertInspection;
