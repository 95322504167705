import {
	excludedFilterColumn,
	spatialFilterColumn,
} from '@Components/AssetGrid/columnMapping';

import { NamespacedStoreState } from '@Types/store.types';
import { createSelector } from '@reduxjs/toolkit';
import { getValue } from './shared';

export const selectMapBase = createSelector(
	[getValue],
	moduleState => moduleState.map,
);

export const selectMapLayout = createSelector(
	[selectMapBase],
	baseState => baseState.layout,
);

const passInAssetType = (
	state: NamespacedStoreState,
	assetType: string,
): string => assetType;

export const selectMapLayoutByAssetType = createSelector(
	[selectMapLayout, passInAssetType],
	(layout, assetType) => layout && layout[assetType],
);

export const selectMapLayoutForPropertyPane = createSelector(
	[selectMapLayoutByAssetType],
	assetTypeLayout =>
		assetTypeLayout &&
		assetTypeLayout.find(({ showInPanel }) => showInPanel),
);

export const selectMapLayoutForAssetDetails = createSelector(
	[selectMapLayoutByAssetType],
	assetTypeLayout =>
		assetTypeLayout &&
		assetTypeLayout.filter(({ showInPanel }) => !showInPanel),
);

export const selectMapLayoutForAssetGrid = createSelector(
	[selectMapLayoutForPropertyPane, selectMapLayoutForAssetDetails],
	(toShow, additional) => {
		const fieldKeys = toShow?.fields.map(({ fieldKey }) => fieldKey);
		const additionalFields = additional?.flatMap(({ fields }) => fields);
		return [
			...(toShow?.fields || []).map(data => ({ ...data, visible: true })),
			spatialFilterColumn,
			excludedFilterColumn,
			...(additionalFields || [])?.filter(
				({ fieldKey }) => !fieldKeys?.includes(fieldKey),
			),
		];
	},
);

export const selectAssetGridColumnVisibility = createSelector(
	selectMapLayoutForAssetGrid,
	fields => {
		return fields.reduce(
			(prev, { fieldKey, visible }) => ({
				...prev,
				[fieldKey]: visible ?? false,
			}),
			{},
		);
	},
);
