import {
	continuousOnlySelector,
	gradeFiltersSelector,
	jointOnlySelector,
	observationSelector,
	observationStoreSelector,
	selectContinuousOnly,
	selectGradeFilters,
	selectJointOnly,
	selectObservations,
	selectStepOnly,
	selectTypeFilters,
	stepOnlySelector,
	typeFiltersSelector,
} from './observations.selectors';
import {
	fullInspSelector,
	hasValidationIssues,
	selectFullInspection,
	selectFullInspectionConditions,
	selectFullInspectionIsWaiting,
	selectMergedValidation,
	selectNotes,
	selectNotesIsWaiting,
} from './fullInspection.selectors';
import {
	selectInspections,
	selectInspectionsIsWaiting,
	selectCreatingInspection,
} from './inspection.selectors';
import {
	getMapBackground,
	getMapHiddenLayers,
	getMapPosition,
	getMapSelected,
	getMapSelectedIds,
	getMapSelectedPosition,
	getTenant,
	mapInsertAssetSelector,
	mapInsertSpatialSelector,
	riskLayersSelector,
	selectMapInsertAsset,
	selectMapInsertInspection,
	selectMapInsertSpatial,
	selectMapRehabLayers,
	selectRiskLayers,
	useSelectMapInsertAsset,
	useSelectMapInsertInspection,
	useSelectMapInsertSpatial,
	useSelectRiskLayers,
} from './map.selectors';
import {
	selectAssetDetail,
	selectAssetDetailDetail,
	selectAssetDetailId,
	selectAssetDetailIsWaiting,
	selectAssetDetailNotesIsWaiting,
	selectAssetDetailType,
	selectDetailMapZoom,
	selectDetailSelectedMapIds,
	selectMapSelectedPosition,
	selectValidAssetIds,
	selectValidatingAssets,
	useSelectAssetLocation,
	useSelectAssetLocationIsWaiting,
} from './assetDetail.selectors';
import { selectAssetPipe, selectAssetPipeAsset } from './assetPipe.selectors';
import {
	selectAssetRisk,
	selectAssetRiskIsReady,
	selectAssetRiskIsRejected,
	selectAssetRiskIsWaiting,
} from './assetRisk.selectors';
import {
	selectAssetRiskHistory,
	selectAssetRiskHistoryIsReady,
} from './assetRiskHistory.selectors';
import { selectChartIsLoading, selectCharts } from './charts.selectors';
import {
	selectRiskConfigsIsLoading,
	selectRiskConfigsIsWaiting,
	selectRiskConfigsPage,
	selectRiskConfigsPagination,
	selectRiskConfigsPerPage,
	selectRiskConfigsStatus,
} from './riskConfigs.selectors';
import {
	selectRiskConfigsList,
	selectRiskConfigsListIsLoading,
} from './riskConfigsList.selectors';
import { selectUserContext, selectUserInitial } from './user.selectors';
import {
	useSelectConfiguration,
	useSelectConfigurationIsLoading,
} from './configuration.selectors';

import selectAssetManhole from './assetManhole.selectors';

export {
	selectRiskConfigsPage,
	selectRiskConfigsStatus,
	selectRiskConfigsIsWaiting,
	selectRiskConfigsIsLoading,
	selectRiskConfigsPerPage,
	selectRiskConfigsPagination,
};

export { selectRiskConfigsList, selectRiskConfigsListIsLoading };

export {
	selectAssetDetail,
	selectAssetDetailDetail,
	selectAssetDetailId,
	selectAssetDetailType,
	selectAssetDetailIsWaiting,
	selectAssetDetailNotesIsWaiting,
	selectDetailSelectedMapIds,
	selectDetailMapZoom,
	selectMapSelectedPosition,
	selectValidAssetIds,
	selectValidatingAssets,
	useSelectAssetLocation,
	useSelectAssetLocationIsWaiting,
};
export { selectAssetPipe, selectAssetPipeAsset };
export { selectAssetManhole };
export {
	selectAssetRiskIsRejected,
	selectAssetRiskIsReady,
	selectAssetRisk,
	selectAssetRiskIsWaiting,
};
export { selectAssetRiskHistoryIsReady, selectAssetRiskHistory };
export {
	selectFullInspection,
	selectNotes,
	selectFullInspectionIsWaiting,
	selectNotesIsWaiting,
	hasValidationIssues,
	selectFullInspectionConditions,
	selectMergedValidation,
	fullInspSelector,
};
export {
	selectInspections,
	selectInspectionsIsWaiting,
	selectCreatingInspection,
};
export {
	selectMapInsertInspection,
	selectMapInsertAsset,
	selectMapInsertSpatial,
	selectRiskLayers,
	getMapPosition,
	getMapBackground,
	getMapSelected,
	getMapSelectedIds,
	getMapSelectedPosition,
	getMapHiddenLayers,
	getTenant,
	useSelectMapInsertInspection,
	mapInsertAssetSelector,
	useSelectMapInsertAsset,
	mapInsertSpatialSelector,
	useSelectMapInsertSpatial,
	useSelectRiskLayers,
	riskLayersSelector,
	selectMapRehabLayers,
};
export { selectUserContext, selectUserInitial };
export {
	selectContinuousOnly,
	selectJointOnly,
	selectObservations,
	selectStepOnly,
	selectGradeFilters,
	selectTypeFilters,
	observationSelector,
	observationStoreSelector,
	jointOnlySelector,
	continuousOnlySelector,
	stepOnlySelector,
	gradeFiltersSelector,
	typeFiltersSelector,
};

export { useSelectConfiguration, useSelectConfigurationIsLoading };

export default {
	selectAssetDetail,
	selectAssetDetailDetail,
	selectAssetDetailId,
	selectAssetDetailType,
	selectAssetDetailIsWaiting,
	selectAssetDetailNotesIsWaiting,
	selectValidAssetIds,
	selectValidatingAssets,
	selectDetailSelectedMapIds,
	selectDetailMapZoom,
	selectMapSelectedPosition,
	selectAssetPipe,
	selectAssetPipeAsset,
	selectAssetManhole,
	selectAssetRiskIsRejected,
	selectAssetRiskIsReady,
	selectAssetRisk,
	selectAssetRiskHistoryIsReady,
	selectAssetRiskHistory,
	selectFullInspection,
	selectNotes,
	selectFullInspectionIsWaiting,
	selectNotesIsWaiting,
	hasValidationIssues,
	selectFullInspectionConditions,
	selectMergedValidation,
	selectInspections,
	selectInspectionsIsWaiting,
	getMapPosition,
	getMapBackground,
	getMapSelected,
	getMapSelectedIds,
	getMapSelectedPosition,
	getMapHiddenLayers,
	getTenant,
	selectMapInsertInspection,
	mapInsertAssetSelector,
	selectMapInsertAsset,
	mapInsertSpatialSelector,
	selectMapInsertSpatial,
	selectUserContext,
	selectUserInitial,
	selectContinuousOnly,
	selectJointOnly,
	selectObservations,
	selectStepOnly,
	selectGradeFilters,
	selectTypeFilters,
	observationSelector,
	observationStoreSelector,
	jointOnlySelector,
	continuousOnlySelector,
	stepOnlySelector,
	gradeFiltersSelector,
	typeFiltersSelector,
	selectRiskLayers,
	riskLayersSelector,
	useSelectMapInsertInspection,
	useSelectMapInsertAsset,
	useSelectMapInsertSpatial,
	useSelectRiskLayers,
	useSelectConfiguration,
	useSelectConfigurationIsLoading,
	selectMapRehabLayers,
	useSelectAssetLocation,
	useSelectAssetLocationIsWaiting,
};

export { selectCharts, selectChartIsLoading };
