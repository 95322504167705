import { CircularProgress, Grid } from '@mui/material';
import {
	StylovyzeFormV2,
	useIsFeatureEnabled,
	getSubscriptions,
	useUnits,
	DateTimeInputMode,
	useTzDateTime,
	useSettings,
} from '@innovyze/stylovyze';
import { Tab, TabPanel, TabWrapper, Tabs } from '@Components/Tabs';
import {
	a11yProps,
	fixupDateTimeForInputDateTime,
	getTaskValues,
	unitMapping,
	UNITS,
} from '@Utils';
import {
	refreshInsp,
	selectFullInspection,
	selectFullInspectionIsWaiting,
} from '@Selectors/fullInspection.selectors';

import React, { useEffect, useMemo } from 'react';
import { useGlobalization } from '@Translations';
import { useDispatch } from 'react-redux';
import {
	refreshInspection,
	updateInspection,
} from '@Actions/inspection.actions';
import ModifiedInfoCard from '@Components/ModifiedInfoCard/ModifiedInfoCard';
import {
	UnitProps,
	DateTimeProps,
	getChangedFormikFields,
	getDetailGrid,
	NumericProps,
	processFormSubmit,
} from '@Utils/common.utils';
import { FormikValues } from 'formik';
import {
	getTaskMandatoryFields,
	getTaskOptionalFields,
	taskIgnoreFields,
} from '@Types/fullInspection.types';
import { viewerAccess } from '@innovyze/shared-utils';
import { useSelectConfiguration } from '@Selectors';

const NON_EDITABLE_FIELDS = ['uploadedBy', 'uploadDateTime'];

const isEditableField = (fieldName: string) => {
	return !NON_EDITABLE_FIELDS.includes(fieldName);
};

interface Props {
	taskType: string;
	readonly: boolean;
	onReRender?: () => void;
}

export const TaskDetailsProps = ({
	taskType,
	readonly,
	onReRender,
}: Props): JSX.Element => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const { getSystemValueFormatted } = useUnits();
	const formatDateUTC = useTzDateTime({ timeZone: 'UTC' }).formatDate;
	const { companySettings } = useSettings();

	const isLoading = selectFullInspectionIsWaiting();
	const [formSubmitted, setFormSubmitted] = React.useState(false);
	const [editDetails, setEditDetails] = React.useState(false);
	const [tabValue, setTabValue] = React.useState(0);

	const subscriptions = getSubscriptions();
	const hasViewerAccess = viewerAccess(subscriptions);

	const config = useSelectConfiguration(taskType);
	// const details = useSelectConfigurationDetails(taskType);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const inspection = selectFullInspection() as any;
	const mandatoryFields = getTaskMandatoryFields(t);
	const optionalFields = getTaskOptionalFields(t);

	// Booleans are not yet supported by the task mapping
	const booleanFields: {
		[key: string]: boolean;
	} = {};

	const unitFields = useMemo(() => {
		const newUnitFields: { [key: string]: UnitProps } = {};

		if (config && config.length !== 0) {
			config.forEach(mapping => {
				switch (mapping.unit) {
					case 'integer':
					case 'float':
						if (mapping.usage.length != 0) {
							const displayUnit = unitMapping(mapping.usage);
							if (displayUnit !== UNITS.NONE)
								newUnitFields[mapping.target] = {
									unit: displayUnit,
								};
						}
						break;
				}
			});
		}

		return newUnitFields;
	}, [config]);

	const currencyFields = useMemo(() => {
		const newCurrencyFields: { [key: string]: NumericProps } = {};

		if (config && config.length !== 0) {
			config.forEach(mapping => {
				switch (mapping.unit) {
					case 'currency':
						newCurrencyFields[mapping.target] = {};
						break;
				}
			});
		}

		return newCurrencyFields;
	}, [config]);

	const numericFields = useMemo(() => {
		const newNumericFields: {
			[key: string]: NumericProps;
		} = {};

		if (config && config.length !== 0) {
			config.forEach(mapping => {
				switch (mapping.unit) {
					case 'integer':
					case 'float':
						if (mapping.usage.length == 0) {
							newNumericFields[mapping.target] = {};
							break;
						}
						break;
					case 'date':
						if (mapping.incomingUnit == 'dateyear') {
							// Years are handled as numerics
							newNumericFields[mapping.target] = {
								min: 0,
								max: 9999,
								step: 1,
							};
						}
						break;
				}
			});
		}

		return newNumericFields;
	}, [config]);

	const dateTimeFields = useMemo(() => {
		const newDateTimeFields: {
			[key: string]: DateTimeProps;
		} = {};

		if (config && config.length !== 0) {
			config.forEach(mapping => {
				switch (mapping.unit) {
					case 'date':
						// Options are time, dateyear, datetime(import version which we dont care about) and date(import version which we dont care about)
						if (mapping.incomingUnit == 'time') {
							newDateTimeFields[mapping.target] = {
								mode: DateTimeInputMode.TIME,
								field: mapping.target,
							};
						} else if (
							mapping.incomingUnit.startsWith('datetime')
						) {
							newDateTimeFields[mapping.target] = {
								mode: DateTimeInputMode.DATE_TIME,
								field: mapping.target,
							};
						} else if (mapping.incomingUnit !== 'dateyear') {
							newDateTimeFields[mapping.target] = {
								mode: DateTimeInputMode.DATE,
								field: mapping.target,
							};
						}
						break;
				}
			});
		}

		return newDateTimeFields;
	}, [config]);

	const taskFields = useMemo(() => {
		const fields: { name: string; alias: string }[] = [];

		if (config && config.length !== 0) {
			// First we need to sort the order of the config using the mandatory and option fields
			const mappingFields = config.map(mapping => {
				const field = optionalFields[mapping.target];

				if (field) {
					return { ...mapping, label: field };
				} else {
					return { ...mapping, label: mapping.target };
				}
			});

			// Mandatory items first
			Object.keys(mandatoryFields).forEach((key: string) => {
				const mapping = config.find(mapping => mapping.target == key);
				if (mapping) {
					let realKey = key;
					// Some messing around with relatedAssetId as it or assetId may not exist
					// assetId may not exist if there is no actual related asset
					if (realKey === 'relatedAssetId' && inspection['assetId']) {
						realKey = 'assetId';
					}

					fields.push({
						name: mapping.target,
						alias: mandatoryFields[key],
					});
				}
			});

			mappingFields
				.sort((a, b) => a.label.localeCompare(b.label))
				.forEach(mapping => {
					// Filter out ignored fields
					if (
						!taskIgnoreFields.find(
							field => field === mapping.target,
						)
					) {
						fields.push({
							name: mapping.target,
							alias: mapping.label,
						});
					}
				});
		}

		return fields;
	}, [config]);

	const origDetails = useMemo(() => {
		setFormSubmitted(false);

		const values = getTaskValues(inspection, false);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			if (
				Object.entries(dateTimeFields).find(
					value => value[1].field === key,
				)
			) {
				values[key] = fixupDateTimeForInputDateTime(values[key]);
			}
		});

		return values;
	}, [inspection]);

	const viewDetails = useMemo(() => {
		const values = getTaskValues(inspection, true);

		const keys = Object.keys(values);
		keys.forEach(key => {
			// Adjust any date time fields for timezone/dst offsets caused by the datetime control
			if (
				Object.entries(dateTimeFields).find(
					value => value[1].field === key,
				)
			) {
				values[key] = fixupDateTimeForInputDateTime(values[key]);
			}
		});

		return values;
	}, [inspection]);

	const inspUpdatestatus = refreshInsp();

	//when task has been updated, page needs to be refreshed to display latest details
	useEffect(() => {
		if (inspUpdatestatus === 200 && onReRender) onReRender();
	}, [inspUpdatestatus]);

	const isEditAllowedFlag = useIsFeatureEnabled('info-360-edit-tasks')
		? true
		: false;

	const details = (
		<ModifiedInfoCard
			title={t('Details')}
			dataCy="modified-info-card-details"
			showEditBtn={
				!readonly && !hasViewerAccess && onReRender !== undefined
			}
			launchDarkleyFlag={isEditAllowedFlag}
			onEditClick={() => {
				setEditDetails(true);
			}}
			onCancelClick={() => {
				setEditDetails(false);
			}}
			inEditMode={editDetails}
			disabled={inspUpdatestatus === -1}>
			<Grid container>
				{getDetailGrid(
					t,
					taskFields,
					unitFields,
					dateTimeFields,
					currencyFields,
					numericFields,
					booleanFields,
					isEditableField,
					true,
				)}
			</Grid>
		</ModifiedInfoCard>
	);

	const handleFormSubmit = (data: FormikValues) => {
		// We should really used the touched state of a field but I cant get the Formik stuff to work properly
		const changes = getChangedFormikFields(origDetails, data);

		if (changes && Object.entries(changes).length !== 0) {
			setFormSubmitted(true);

			const editedFieldsList = processFormSubmit(
				t,
				taskType,
				origDetails,
				changes,
				taskFields,
				dateTimeFields,
				unitFields,
				currencyFields,
				numericFields,
				booleanFields,
				getSystemValueFormatted,
				formatDateUTC,
				companySettings,
			);

			dispatch(refreshInspection({ status: -1 })); //reset update flag and hide the controls
			dispatch(updateInspection(editedFieldsList)); //trigger update
		}
	};

	const handleChangeTab = (event: unknown, newValue: number) => {
		setTabValue(newValue);
	};

	if (
		(!isLoading && origDetails && origDetails._id !== '') ||
		formSubmitted
	) {
		return (
			<StylovyzeFormV2
				key={editDetails ? 'edit' : 'view'}
				mode={editDetails ? 'edit' : 'view'}
				initialValues={editDetails ? origDetails : viewDetails}
				enableReinitialize
				onSubmit={(data: FormikValues) => {
					setEditDetails(false);
					handleFormSubmit(data);
				}}>
				{({ handleSubmit }) => {
					return (
						<form onSubmit={handleSubmit}>
							<TabWrapper>
								<Tabs
									orientation="vertical"
									variant="scrollable"
									value={tabValue}
									onChange={handleChangeTab}>
									<Tab
										label={t('Details')}
										{...a11yProps(0)}
									/>
								</Tabs>
								<TabPanel value={tabValue} index={0} key={0}>
									{details}
								</TabPanel>
							</TabWrapper>
						</form>
					);
				}}
			</StylovyzeFormV2>
		);
	} else {
		return (
			<div style={{ padding: '25px' }}>
				<CircularProgress />
			</div>
		);
	}
};

export default TaskDetailsProps;
