import {
	DISMISS_MESSAGE,
	MESSAGE_CLOSED,
	NEW_MESSAGE,
} from '@Map/notifications/Messages';
import React, {
	MutableRefObject,
	forwardRef,
	useCallback,
	useState,
} from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { dispatchTargetedEvent } from '@Map/utils';
import { useEventListener } from '@Hooks/useEventListener';

export const Messages = forwardRef<HTMLDivElement | null>(function Messages(
	_,
	ref,
) {
	const [lastKey, setLastKey] = useState('');
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const onDismiss = (key: SnackbarKey | undefined) => () => {
		closeSnackbar(key);
		dispatchTargetedEvent(
			(ref as MutableRefObject<HTMLDivElement>)?.current,
			MESSAGE_CLOSED,
			{ key },
		);
	};

	const action = (key: SnackbarKey | undefined) => (
		<IconButton type="button" onClick={onDismiss(key)}>
			<CloseIcon htmlColor="white" />
		</IconButton>
	);

	const updateMessage = useCallback(
		e => {
			const result = e.detail;
			if (result.key === lastKey) {
				return;
			}
			closeSnackbar(lastKey);
			enqueueSnackbar(result.message, {
				persist: result.persist,
				key: result.key,
				variant: result.variant,
				action,
			});
			setLastKey(result.key);
		},
		[enqueueSnackbar, lastKey, closeSnackbar],
	);

	useEventListener(
		NEW_MESSAGE,
		updateMessage,
		ref as MutableRefObject<HTMLDivElement>,
	);

	const dismissMessage = useCallback(
		e => {
			const key = e.detail.key;
			closeSnackbar(key);
		},
		[closeSnackbar],
	);

	useEventListener(
		DISMISS_MESSAGE,
		dismissMessage,
		ref as MutableRefObject<HTMLDivElement>,
	);

	return <div ref={ref} />;
});
