import { GeoJsonProperties, SelectedAsset } from './features/types';

import { PointLike } from 'mapbox-gl';

export * from './geocoder/types';
export * from './panel/types';

export enum MapModes {
	Full = 'full',
	Static = 'static',
	Navigation = 'navigation',
	Search = 'search',
}

export enum DistanceUnit {
	imperial = 'imperial',
	metric = 'metric',
}

export type MapSelectedAssets = SelectedAsset[];

export type MapHighlightedAsset = {
	_id: string;
	assetType: string;
	systemType: string;
	geometry: GeoJSON.Geometry;
};

export type MapPanelItems = GeoJsonProperties[] | null;

export interface MapPosition {
	center: number[];
	zoom: number;
}

// Weirdly using Pick doesn't seem to work when importing into another app
export interface FitBoundsOptions {
	padding?: mapboxgl.FitBoundsOptions['padding'];
	zoom?: mapboxgl.FitBoundsOptions['zoom'];
	maxZoom?: mapboxgl.FitBoundsOptions['maxZoom'];
	minZoom?: mapboxgl.FitBoundsOptions['minZoom'];
	offset?: mapboxgl.FitBoundsOptions['offset'];
}

export interface SelectionOptions {
	minZoom: number;
	maxFeatures: number;
}

export { SelectedAsset, GeoJsonProperties };

export enum MapFunction {
	interactive,
	backgroundControl,
	selection,
	clusterExapnsion,
	geolocateControl,
	scaleControl,
	navigationControl,
	geocoderControl,
	extentsControl,
	hoverPopup,
	marker,
}

export type LocationBox = [PointLike, PointLike];

export enum SelectionMode {
	normal,
	invert,
	add,
	subtract,
}

export interface MapState {
	drawing: boolean;
}

export type MapSetState = <T extends keyof MapState>(
	key: T,
	value: MapState[T],
) => void;
