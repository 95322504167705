import { AnyAction, createReducer } from '@reduxjs/toolkit';
import {
	ConfigurationStoreState,
	ConfigurationMapping,
	YAMLMapping,
	Configuration,
} from '@Types';
import {
	getConfigurationWithMapping,
	getConfigurationResolved,
	getConfigurationRejected,
	getConfigurationWithUploadId,
} from '@Actions';
import { status200, statusDefault, statusLoading } from './paginationUtils';

export const initialState: ConfigurationStoreState = {
	configurations: {},
	isLoadingConfiguration: false,
	status: statusDefault(),
};

interface ConfigurationReducer {
	[x: string]: (
		state: ConfigurationStoreState,
		action: AnyAction,
	) => ConfigurationStoreState | undefined;
}

const reducer: ConfigurationReducer = {
	[getConfigurationWithMapping.toString()]: state => ({
		...state,
		isLoadingConfiguration: true,
		status: statusLoading(),
	}),
	[getConfigurationWithUploadId.toString()]: state => ({
		...state,
		isLoadingConfiguration: true,
		status: statusLoading(),
	}),
	[getConfigurationResolved.toString()]: (state, action) => {
		const mapping = new Array<ConfigurationMapping>();

		// Parse the config
		Object.entries<YAMLMapping>(
			action.payload.configuration['config'],
		).forEach(([key, value]) => {
			mapping.push({
				target: value.display_name,
				unit: value.data_type,
				source: key,
				incomingUnit: value.incoming_unit ?? '',
				sampleValue: '',
				usage: value.system_unit_name ?? '',
				primaryKey: value.primary_key ?? false,
			});
		});

		const details = {
			// For the moment all we need is the target type.. we might be able to use other stuff later
			targetType:
				action.payload.configuration['importRecord']['targetType'],
		};

		const configuration: Configuration = {
			mapping: mapping,
			details: details,
		};

		state.configurations[action.payload.key] = configuration;
		state.isLoadingConfiguration = false;
		state.status = status200();

		return state;
	},
	[getConfigurationRejected.toString()]: (state, action) => ({
		...state,
		isLoadingConfiguration: false,
		status: action.payload,
	}),
};

export default createReducer(initialState, reducer);
