export enum InspectionStandard {
	PACP = 'PACP',
	MACP = 'MACP',
	MSCC = 'MSCC',
	WSA05 = 'WSA05',
}

export enum UploadStatus {
	MissingMedia = 'missing_media',
	Ready = 'ready',
	Submitted = 'submitted',
	InReview = 'inReview',
	Approved = 'approved',
	Rejected = 'rejected',
	AIProcessing = 'ai_processing',
	AIProcessed = 'ai_processed',
	AIFailed = 'ai_failed',
	VaparEdit = 'vapar_edit',
	Superseded = 'superseded',
}

export enum Direction {
	Upstream = 'upstream',
	Downstream = 'downstream',
}

// Base interface for all inspections
export interface Inspection {
	inspectionId: string;
	inspectionStatus: UploadStatus;
	project: string;
	uploadDateTime: Date;
	uploadedBy: string;
	validationState: string;
	surveyDateTime: Date;
	surveyedBy: string;
}

export interface PACPInspection extends Inspection {
	direction: Direction;
	upstreamManholeId: string;
	downstreamManholeId: string;
	pipelineRef: string;
}

export interface MACPInspection extends Inspection {
	direction: Direction;
	upstreamManholeId: string;
	downstreamManholeId: string;
	pipelineRef: string;
}

export interface MSCCInspection extends Inspection {
	direction: Direction;
	upstreamManholeId: string;
	downstreamManholeId: string;
	pipelineLengthRef: string;
}

export interface GetInspectionsParams {
	something?: number;
}

export interface GetInspectionsResolvedResponse {
	inspections: Inspection[];
}

export interface InspectionStoreState {
	inspections: Inspection[];
	sortField: string;
	sortDirection: SortDirection;
	filters: InspectionFilters[];
	rows: number;
	page: number;
	query: string;
	projectList: string[];
	statusList: string[];
}

export enum SortDirection {
	ASC = 'asc',
	DESC = 'desc',
}

export interface Sort {
	sortField: string;
	sortDirection: SortDirection;
}

export interface InspectionFilters {
	filterField: string;
	filterValue: string;
}

export interface RowsPerPagePayload {
	rows: number;
}

export interface ChangePagePayload {
	page: number;
}

export interface SearchParams {
	query: string;
}

export enum ValidationStatus {
	Error = 'error',
	Warning = 'warning',
	Information = 'information',
}
