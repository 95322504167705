import * as assetActions from '@Actions/asset';

import {
	GetAssetResponse,
	GetSensorResponse,
	getAsset,
	getSensor,
	getSystemAssetTypes,
	getAssetsPaginated,
} from '@Services/asset';
import { PaginatedAssetsAction, PaginatedAssetsResponse } from '@Utils/types';
import { put, retry, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	selectAssetById,
	selectSensorById,
	selectSystemAssets,
} from '../selectors/asset';

import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { getAssetIdAndType } from '@Utils/helpers';
import { getInspections } from '@Actions/inspection';
import { getRiskScore } from '@Actions/risk';

function* getAssetSaga(action: PayloadAction<string>) {
	// check if asset information has already been retrieved before requesting it
	const asset: ReturnType<typeof selectAssetById> = yield select(state =>
		selectAssetById(state, action.payload),
	);
	if (asset) return;
	const { getAssetResolved, getAssetRejected } = assetActions;
	const { assetType, assetId } = getAssetIdAndType(action.payload);
	try {
		const payload: AxiosResponse<GetAssetResponse> = yield retry(
			5,
			1500,
			getAsset,
			assetId,
			assetType,
		);
		if (payload.data.asset) {
			yield put(getAssetResolved(payload.data.asset));
		} else {
			yield put(getAssetRejected());
		}
	} catch (e) {
		yield put(getAssetRejected());
	}
}

function* getAssetWithExtendedDataSaga(action: PayloadAction<string>) {
	const { getAsset, getAssetWithExtendedDataResolved } = assetActions;
	const { assetType } = getAssetIdAndType(action.payload);
	yield put(getAsset(action.payload));
	if (
		assetType === 'wwPipe' ||
		assetType === 'wwManhole' ||
		assetType === 'pipe'
	) {
		yield put(getRiskScore(action.payload));
		yield put(getInspections(action.payload));
	}
	yield put(getAssetWithExtendedDataResolved());
}

function* getSensorSaga(action: PayloadAction<string>) {
	// check if asset information has already been retrieved before requesting it
	const sensor: ReturnType<typeof selectSensorById> = yield select(state =>
		selectSensorById(state, action.payload),
	);
	if (sensor) return;
	const { getSensorResolved, getSensorRejected } = assetActions;
	const { assetId } = getAssetIdAndType(action.payload);
	try {
		const payload: AxiosResponse<GetSensorResponse> = yield retry(
			5,
			1500,
			getSensor,
			assetId,
		);
		if (payload.data.sensor) {
			yield put(getSensorResolved(payload.data.sensor));
		} else {
			yield put(getSensorRejected());
		}
	} catch (e) {
		yield put(getSensorRejected());
	}
}

function* getAssetsPaginatedSaga(action: PayloadAction<PaginatedAssetsAction>) {
	const {
		getAssetsPaginatedResolved,
		getAssetsPaginatedRejected,
	} = assetActions;
	const {
		assetType,
		systemType,
		limit,
		offset,
		sort = 'assetId',
		sortDescending,
		filterModel,
	} = action.payload;
	try {
		const payload: AxiosResponse<PaginatedAssetsResponse> = yield retry(
			5,
			1500,
			getAssetsPaginated,
			systemType,
			assetType,
			limit,
			offset,
			sort,
			sortDescending,
			filterModel,
		);
		if (payload.data) {
			yield put(
				getAssetsPaginatedResolved({
					...payload.data,
					pagination: { ...payload.data.pagination, limit },
					sort,
					sortDescending,
					filterModel,
				}),
			);
		} else {
			yield put(getAssetsPaginatedRejected());
		}
	} catch (e) {
		yield put(getAssetsPaginatedRejected());
	}
}

function* getSystemAssetTypesSaga() {
	const systemAssets: ReturnType<typeof selectSystemAssets> = yield select(
		selectSystemAssets,
	);
	if (Object.keys(systemAssets).length) return;
	const {
		getSystemAssetTypesResolved,
		getSystemAssetTypesRejected,
	} = assetActions;
	try {
		const payload: AxiosResponse<PaginatedAssetsResponse> = yield retry(
			5,
			1500,
			getSystemAssetTypes,
		);
		if (payload.data) {
			yield put(
				getSystemAssetTypesResolved({
					...payload.data,
				}),
			);
		} else {
			yield put(getSystemAssetTypesRejected());
		}
	} catch (e) {
		yield put(getSystemAssetTypesRejected());
	}
}

function* watchGetAsset() {
	yield takeEvery(assetActions.getAsset, getAssetSaga);
}

function* watchGetAssetWithExtendedData() {
	yield takeLatest(
		assetActions.getAssetWithExtendedData,
		getAssetWithExtendedDataSaga,
	);
}

function* watchGetSensor() {
	yield takeEvery(assetActions.getSensor, getSensorSaga);
}

function* watchGetAssetsPaginated() {
	yield takeLatest(assetActions.getAssetsPaginated, getAssetsPaginatedSaga);
}

function* watchGetSystemAssetTypes() {
	yield takeLatest(assetActions.getSystemAssetTypes, getSystemAssetTypesSaga);
}

export default [
	watchGetAsset(),
	watchGetSensor(),
	watchGetAssetWithExtendedData(),
	watchGetAssetsPaginated(),
	watchGetSystemAssetTypes(),
];
