import MapboxUtilsBase from './base';
import logger from '@Map/logger/Logger';

export default class MapboxUtilsStyle extends MapboxUtilsBase {
	setTerrain(options: mapboxgl.TerrainSpecification | undefined | null) {
		try {
			this._map?.setTerrain(options);
		} catch {
			logger.error('Could not set terrain', { options });
		}
	}
}
