import MapboxLayer, { UniqueLayerProps } from './MapboxLayer';

import { ServiceLayer } from '../../services/types';

export default class HighlightLayer extends MapboxLayer {
	protected _type: ServiceLayer['type'] = 'circle';

	layerProperties(): UniqueLayerProps {
		return {
			paint: {
				'circle-color':
					this._highlightColor || MapboxLayer.HIGHLIGHT_COLOR,
				'circle-radius': [
					'interpolate',
					['linear'],
					['zoom'],
					// zoom 10 (or less)
					10,
					15,
					// zoom is 13 (or greater)
					13,
					30,
				],
				'circle-opacity': 0.3,
			},
		};
	}
}
