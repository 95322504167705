import {
	FilterCardContainer,
	FilterCardTextContainer,
} from './FilterCard.styles';

import React from 'react';
import { useFilter } from '../../context/FilterProvider';
import { useGlobalization } from '@Translations/useGlobalization';
import { IconButton } from '@mui/material';
import { Clear } from '@mui/icons-material';

export interface FilterCardProps {
	filterCount: number;
}

export const FilterCard = ({ filterCount }: FilterCardProps): JSX.Element => {
	const { t } = useGlobalization();
	const { clearFilters } = useFilter();

	const label = filterCount > 1 ? t('Filters Active') : t('Filter Active');

	return (
		<FilterCardContainer className="filterCardContainer">
			<FilterCardTextContainer className="filterCardTextContainer">
				{`${filterCount} ${label}`}
			</FilterCardTextContainer>
			<IconButton
				className="filterCardResetButton"
				size="small"
				onClick={clearFilters}>
				<Clear fontSize="small" />
			</IconButton>
		</FilterCardContainer>
	);
};
