import { Button } from '@innovyze/stylovyze';
import { Paper } from '@mui/material';
import { rem } from '@Utils/styles';
import styled from 'styled-components';

const columns = 4;

export const Wrapper = styled(Paper)`
	margin-top: ${rem(15)};
	display: grid;
	grid-template-columns: ${Array(columns)
		.fill('minmax(0, 1fr)')
		.join(' ')};
	column-gap: ${rem(10)};
	border-left: 6px solid #0071a4;
	padding: ${rem(5)} ${rem(10)};
`;

export const TaskWrapper = styled(Wrapper)`
	border-left: 6px solid #ffc107;
`;

export const InspectionId = styled.div<{ viewDetails?: boolean }>`
	grid-column: span ${({ viewDetails }) => (viewDetails ? 3 : 4)};
	font-size: ${rem(18)};
	line-height: ${rem(24)};
	font-weight: bold;
`;

export const SurveyDate = styled.div<{ viewDetails?: boolean }>`
	grid-column: span ${({ viewDetails }) => (viewDetails ? 3 : 4)};
	font-size: ${rem(12)};
	line-height: ${rem(16)};
	color: #768d95;
`;

export const ViewDetails = styled(Button)`
	grid-row: 1 / span 2;
	grid-column: 4;
	justify-self: end;
	align-self: center;
`;

export const InspectionCard = styled.div`
	width: 386px;
	display: flex;
	justify-content: space-between;
`;
