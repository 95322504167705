import { GridFilterModel } from '@mui/x-data-grid';

export const addPolygonFilter = (
	filter: GridFilterModel,
	polygonFilter?: GeoJSON.Feature<GeoJSON.Polygon>,
): GridFilterModel => {
	const polygonFilterItem = polygonFilter
		? [
				{
					field: 'spatial',
					operator: 'isInside',
					value: polygonFilter.geometry,
				},
		  ]
		: [];
	return {
		...filter,
		items: [
			...filter.items.filter(item => item.field !== 'spatial'),
			...polygonFilterItem,
		],
	};
};

export const removeExcludedFilter = (
	filter: GridFilterModel,
): GridFilterModel => {
	return {
		...filter,
		items: filter.items.filter(item => item.field !== 'excluded'),
	};
};

export const addExcludedFilter = (filter: GridFilterModel): GridFilterModel => {
	// don't add the filter if it already exists
	if (!filter.items.find(item => item.field === 'excluded')) {
		return filter;
	}
	return {
		...filter,
		items: [
			...filter.items,
			{
				field: 'excluded',
				operator: 'equals',
				value: false,
			},
		],
	};
};
