import { getValue, passInId } from './shared';

import { createSelector } from '@reduxjs/toolkit';

export const selectRiskBase = createSelector(
	[getValue],
	moduleState => moduleState.risk,
);

export const selectSpatialRecords = createSelector(
	[selectRiskBase],
	baseState => baseState.spatialRecords,
);

export const selectSpatialRecordById = createSelector(
	[selectSpatialRecords, passInId],
	(records, selectedItem) => records.find(({ id }) => id === selectedItem),
);

export const selectRiskScores = createSelector(
	[selectRiskBase],
	baseState => baseState.riskCondition,
);

export const selectRiskScoreByAssetId = createSelector(
	[selectRiskScores, passInId],
	(scores, selectedItem) => selectedItem && scores[selectedItem],
);
