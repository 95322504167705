import { rem } from '@Utils/styles';
import styled from 'styled-components';

export const FilterCardContainer = styled.div`
	align-items: center;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.23);
	display: flex;
	max-width: max-content;
	padding-right: ${rem(8)};
`;

export const FilterCardTextContainer = styled.p`
	color: #3c3c3c;
	font-family: 'Comic Neue', sans-serif;
	font-size: ${rem(14)};
	font-weight: bold;
	line-height: ${rem(18)};
	margin: 0;
	padding: ${rem(10)} ${rem(8)} ${rem(10)} ${rem(12)};
`;
