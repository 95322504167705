import { FeatureItem } from '@Utils/types';
import React from 'react';
import { Tab } from '@Components/Tab';
import { getSpatialRecord } from '@Actions/risk';
import { selectSpatialRecordById } from '../../selectors/risk';
import { useGlobalization } from '@Translations/useGlobalization';

interface OtherTabProps {
	items: FeatureItem[];
}

export const OtherTab = ({ items }: OtherTabProps): JSX.Element => {
	const { t } = useGlobalization();

	return (
		<Tab
			items={items}
			tabName={t('Other', {
				context: 'Property pane tab heading',
			})}
			getItemDetailsSelector={selectSpatialRecordById}
			getItemDetailsAction={getSpatialRecord}
			getItemDetailsOnList
		/>
	);
};
