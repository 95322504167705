import LayerBase, { Config } from './LayerBase';

import { CompositeLayerProps } from '../types';
import { MapboxLayerProps } from '../mapbox/MapboxLayer';

export default class HighlightBackgroundLayer extends LayerBase {
	private _highlightItems: string[] = [];

	constructor(
		layerInfo: CompositeLayerProps,
		map: mapboxgl.Map,
		config: Config | undefined = undefined,
	) {
		super(
			layerInfo,
			map,
			config ?? {
				zIndexesPerType: { base: 2000, line: 2000, symbol: 2000 },
				selected: true,
				highlightBackground: true,
				idPostfix: '-highlight-background',
			},
		);
	}

	get highlightedItems(): string[] {
		return this._highlightItems;
	}

	set highlightedItems(items: string[]) {
		this._highlightItems = items;
		this._resetFilterOnMap();
	}

	protected _getFilter = (): MapboxLayerProps['filter'] => {
		return [
			'in',
			'id',
			...(this.highlightedItems.length ? this.highlightedItems : ['']),
		];
	};
}
