import {
	InfoCard,
	LoadingPage,
	Property,
	PropertyCard,
} from '@innovyze/stylovyze';
import { extractValue, notUndefined } from '@Utils/helpers';

import { FeatureItem } from '@Utils/types';
import React from 'react';
import { SpatialProperty } from '@Types/risk.types';

interface GenericPropertiesProps {
	item: FeatureItem;
}

const getItemName = (item: FeatureItem): string =>
	`${item.name || item.NAME || item.Name || item.id}`;

const getType = (value: SpatialProperty): Property['type'] => {
	if (typeof value === 'object') {
		if (value?.type === 'date') return 'date';
	}
	return 'text';
};

const propertiesToExclude = [
	'id',
	'layerName',
	'type',
	'srcType',
	'sourceId',
	'layerId',
	'assetType',
	'assetId',
	'sensorId',
	'displayType',
	'geometry',
];

const getItemProperties = (item: FeatureItem): Property[] => {
	return Object.entries(item)
		.map(([title, value]) => {
			if (
				propertiesToExclude.includes(title) ||
				title.toLowerCase() === 'name'
			)
				return;
			return {
				title,
				value: extractValue(value as SpatialProperty),
				type: getType(value as SpatialProperty),
				cy: `prop-${title}`,
				ellipsis: true,
			};
		})
		.filter(notUndefined);
};

export const GenericProperties = ({
	item,
}: GenericPropertiesProps): JSX.Element => {
	const title = getItemName(item);
	const properties = getItemProperties(item);
	if (!properties.length)
		return (
			<InfoCard title={title}>
				<LoadingPage />
			</InfoCard>
		);
	return <PropertyCard title={title} properties={properties} />;
};
