import * as riskActions from '@Actions/risk';

import {
	GetSpatialRecordResponse,
	getRiskScore,
	getSpatialRecord,
} from '@Services/risk';
import { put, retry, select, takeEvery } from 'redux-saga/effects';
import {
	selectRiskScoreByAssetId,
	selectSpatialRecordById,
} from '../selectors/risk';

import { AssetRiskAndCondition } from '@Types/risk.types';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

function* getSpatialRecordSaga(action: PayloadAction<string>) {
	// check if record has already been retrieved before requesting it
	const record: ReturnType<typeof selectSpatialRecordById> = yield select(
		state => selectSpatialRecordById(state, action.payload),
	);
	if (record) return;
	const { getSpatialRecordResolved, getSpatialRecordRejected } = riskActions;
	try {
		const payload: AxiosResponse<GetSpatialRecordResponse> = yield retry(
			5,
			1500,
			getSpatialRecord,
			action.payload,
		);
		if (payload.data.record) {
			yield put(getSpatialRecordResolved(payload.data.record));
		} else {
			yield put(getSpatialRecordRejected());
		}
	} catch (e) {
		yield put(getSpatialRecordRejected());
	}
}

function* getRiskScoreSaga(action: PayloadAction<string>) {
	// check if risk score has already been retrieved before requesting it
	const riskScore: ReturnType<typeof selectRiskScoreByAssetId> = yield select(
		state => selectRiskScoreByAssetId(state, action.payload),
	);
	if (riskScore) return;
	const { getRiskScoreResolved, getRiskScoreRejected } = riskActions;
	try {
		const payload: AxiosResponse<AssetRiskAndCondition[]> = yield retry(
			5,
			1500,
			getRiskScore,
			action.payload,
		);
		if (payload.data) {
			yield put(
				getRiskScoreResolved({
					assetId: action.payload,
					risk: payload.data[0],
				}),
			);
		} else {
			yield put(getRiskScoreRejected());
		}
	} catch (e) {
		yield put(getRiskScoreRejected());
	}
}

function* watchGetSpatialRecord() {
	yield takeEvery(riskActions.getSpatialRecord, getSpatialRecordSaga);
}

function* watchGetRiskScore() {
	yield takeEvery(riskActions.getRiskScore, getRiskScoreSaga);
}

export default [watchGetSpatialRecord(), watchGetRiskScore()];
